import { Omit } from 'lodash';
import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useSampleManifestAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: Omit<AnalyticsOptions, 'category'>) => {
        analyticsTracking.trackEvent({
            ...ga,
            category: 'sample_manifest',
            app: ga.app || AppName.SAMPLE_MANIFEST,
            project: ga.project || currentProjectDetails?.projectName,
        });
    };

    const trackSampleManifestLandingFromProjectCard = (projectName: string) => {
        sendTrackEvent({
            action: 'sample_manifest_landing_from_project_card',
            app: AppName.PROJECTS,
            project: projectName,
        });
    };

    const trackSampleManifestLandingFromSiteMenu = () => {
        sendTrackEvent({
            action: 'sample_manifest_landing_from_site_menu',
        });
    };

    const trackSampleManifestLandingFromEmailLink = () => {
        sendTrackEvent({
            action: 'sample_manifest_landing_from_email_link',
        });
    };

    const trackSampleManifestHomePageAction = (props: { action: 'start' | 'resume' | 'amend' }) => {
        sendTrackEvent({
            action: `sample_manifest_${props.action}_sampling_event`,
        });
    };

    const trackSampleManifestPrint = (props: { page: 'home' | 'submit_popup' }) => {
        sendTrackEvent({
            action: `sample_manifest_print_from_${props.page}`,
        });
    };

    const trackSampleManifestNavigationClick = (props: { action: 'back' | 'next' | 'exit'; page: string }) => {
        sendTrackEvent({
            action: `sample_manifest_${props.page.replaceAll('-', '_')}_${props.action}_click`,
        });
    };

    const trackSampleManifestMapZoom = (props: { zoomLevel: string }) => {
        sendTrackEvent({
            action: 'sample_manifest_map_zoom',
            label: props.zoomLevel,
        });
    };

    const trackSampleManifestMapDrag = () => {
        sendTrackEvent({
            action: 'sample_manifest_map_drag',
        });
    };

    return {
        trackSampleManifestLandingFromEmailLink,
        trackSampleManifestLandingFromProjectCard,
        trackSampleManifestLandingFromSiteMenu,
        trackSampleManifestHomePageAction,
        trackSampleManifestPrint,
        trackSampleManifestNavigationClick,
        trackSampleManifestMapZoom,
        trackSampleManifestMapDrag,
    };
};

export default useSampleManifestAnalytics;
