export const availableAreaColors = [
    '#0fc0c7',
    '#bc4e7c',
    '#e3a52d',
    '#386EB9',
    '#e25f18',
    '#8b79d4',
    '#a4e8e9',
    '#e7de41',
    '#67af20',
    '#a71b1b',
];

export const habitatInsightsLandTypeColors: {
    [key: string]: {
        color: string;
        label: string;
        key: string;
    };
} = {
    water: {
        color: '#6B98E1',
        label: 'Water',
        key: 'water',
    },
    trees: {
        color: '#4C7F47',
        label: 'Trees',
        key: 'trees',
    },
    grass: {
        color: '#8EB24E',
        label: 'Grass',
        key: 'grass',
    },
    floodedvegetation: {
        color: '#8385C8',
        label: 'Flooded Vegetation',
        key: 'floodedvegetation',
    },
    crops: {
        color: '#D19833',
        label: 'Crops',
        key: 'crops',
    },
    shrubandscrub: {
        color: '#D4C556',
        label: 'Shrub and Scrub',
        key: 'shrubandscrub',
    },
    built: {
        color: '#AD2A21',
        label: 'Built',
        key: 'built',
    },
    bare: {
        color: '#A29B8F',
        label: 'Bare',
        key: 'bare',
    },
    snowandice: {
        color: '#B39DE3',
        label: 'Snow and Ice',
        key: 'snowandice',
    },
};

export const habitatInsightsLandTypeOrder = [
    'Water',
    'Trees',
    'Grass',
    'Flooded Vegetation',
    'Crops',
    'Shrub and Scrub',
    'Built',
    'Bare',
    'Snow and Ice',
];

export const habitatInsightsConnectivityTypeColors: {
    [key: string]: {
        color: string;
        label: string;
        key: string;
    };
} = {
    trees: {
        color: '#4C7F47',
        label: 'Trees',
        key: 'trees',
    },
    grass: {
        color: '#8EB24E',
        label: 'Grass',
        key: 'grass',
    },
    allgreen: {
        color: '#344B0B',
        label: 'All green',
        key: 'allgreen',
    },
};

export const habitatInsightsPrimaryProductivityTypeColors: {
    [key: string]: {
        color: string;
        label: string;
        key: string;
    };
} = {
    annualaverage: {
        color: '#0FC0C7',
        label: 'Annual average',
        key: 'annualaverage',
    },
};

export const habitatInsightsPrimaryProductivityColors: {
    color: string;
    label: string;
}[] = [
    {
        color: '#004400',
        label: '0.6 to 1',
    },
    {
        color: '#0F540A',
        label: '0.5 to 0.6',
    },
    {
        color: '#306D1C',
        label: '0.4 to 0.5',
    },
    {
        color: '#4F892D',
        label: '0.3 to 0.4',
    },
    {
        color: '#70A33F',
        label: '0.2 to 0.3',
    },
    {
        color: '#91BF51',
        label: '0.1 to 0.2',
    },
    {
        color: '#CCC682',
        label: '0 to 0.1',
    },
    {
        color: '#EAEAEA',
        label: '-1 to 0',
    },
];

export const getColourForHabitatInsights = (insightType: string) => {
    const insightTypeKey = insightType.split(' ').join('').toLowerCase();
    const allInsightsColors = {
        ...habitatInsightsLandTypeColors,
        ...habitatInsightsPrimaryProductivityTypeColors,
        ...habitatInsightsConnectivityTypeColors,
    };

    return allInsightsColors[insightTypeKey]?.color || '#000';
};
