import { RefObject, useState, useMemo, useEffect } from 'react';

/* 
  This hook is used to track when an element comes into view 
  It reports back only once.
*/
const useIsViewed = (ref: RefObject<HTMLElement | null>) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref]);

    useEffect(() => {
        if (!ref.current || isIntersecting) {
            return;
        }
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref.current, isIntersecting]);

    return isIntersecting;
};

export default useIsViewed;
