import React from 'react';

import TickIcon from 'src/assets/svg/general/green-tick.svg?react';
import RedCrossIcon from 'src/assets/svg/general/red-cross.svg?react';
import { getKitTypeFromBarcode, isValidKitBarcode } from 'src/shared/helpers/kitHelpers';
import useSampleManifestFieldDataAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestFieldDataAnalytics';

type KitTypeMessageProps = {
    kitBarcode: string;
};

const KitTypeMessage = (props: KitTypeMessageProps) => {
    const { kitBarcode } = props;
    const { trackSampleManifestAddDataNoNmKitFoundError } = useSampleManifestFieldDataAnalytics();

    const isValidBarcode = isValidKitBarcode(kitBarcode);

    if (!isValidBarcode) {
        return null;
    }

    const kitType = getKitTypeFromBarcode(kitBarcode);

    if (!kitType) {
        // Capture GA for sample manifest add field data - No NM kit found error
        trackSampleManifestAddDataNoNmKitFoundError();

        return (
            <div className='flex gap-2 items-center'>
                <RedCrossIcon data-testid='red-cross-icon' />
                <div className='text-sm'>No NM kit found.</div>
            </div>
        );
    }

    return (
        <div className='flex gap-2 items-center'>
            <TickIcon data-testid='tick-icon' />
            <div className='text-sm'>NM {kitType} kit</div>
        </div>
    );
};

export default KitTypeMessage;
