import React from 'react';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import speciesImageUrl from 'src/assets/images/dashboard/species.svg?url';
import { useSpeciesMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { useAppSelector } from 'src/store';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import { filterByCHEGDStatus } from '../metrics-table/metricsTableHelpers';

const ChegdMetric = () => {
    const chartTitle = 'CHEGD Species';
    const { currentProjectSelectedHabitatAssayType: habitatAssayType, currentProjectId } = useProjectFilters();

    const uiState = useAppSelector(getCurrentDashboardUIState);

    const {
        iucnMetric: { samplingEvent },
    } = uiState;

    const { currentData, isFetching } = useSpeciesMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: samplingEvent?.fromDate,
                toDate: samplingEvent?.toDate,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType || !samplingEvent,
        }
    );

    if (isFetching || !currentData) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const { speciesMetrics: speciesMetricsData } = currentData;
    const { taxonomySummary } = speciesMetricsData;

    const chegdSpecies = filterByCHEGDStatus(taxonomySummary).filter(species => species.taxonomy.tax_species);

    const helpContent = <QuestionButtonHelp type='api' placement='right' slug='chegd-species-helptext' />;

    if (!taxonomySummary) {
        return <MetricCard header={chartTitle} helpText={helpContent} noData={true} />;
    } else {
        return (
            <MetricCard
                backgroundImage={speciesImageUrl}
                header={chartTitle}
                total={chegdSpecies.length}
                content='CHEGD species detected'
                helpText={helpContent}
            />
        );
    }
};

export default ChegdMetric;
