import React, { ReactNode } from 'react';
import ErrorIcon from 'src/assets/svg/icon-err-dark.svg?react';
import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ message }: { message: ReactNode }) => {
    return (
        <div className={styles.errorMessage}>
            <ErrorIcon />
            <div className={styles.message} data-testid='message'>
                {message}
            </div>
        </div>
    );
};

export const ErrorMessageWithBackground = ({ message }: { message: ReactNode }) => {
    return (
        <div className={styles.errorMessageBackground} data-testid='error-message'>
            <ErrorIcon />
            <span>{message}</span>
        </div>
    );
};

export default ErrorMessage;
