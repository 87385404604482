import { getCurrentCustomerDetails, getCurrentCustomerId, getAllCustomers } from 'src/app/customers/state/customersSelector';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import useAuthService from 'src/shared/hooks/useAuthService';
import { setCurrentCustomerId as setCurrentCustomerIdAction } from '../state/customersSlice';

const useCustomer = () => {
    const currentCustomerId = useAppSelector(getCurrentCustomerId) || '';
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails);
    const allCustomers = useAppSelector(getAllCustomers);
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuthService();

    const [searchParams] = useSearchParams();
    const customerIdFromUrl = searchParams.get('customerId');

    const {
        isFetching: isFetchingCurrentCustomerProjects,
        currentData: currentCustomerProjects,
        error,
    } = useProjectsQuery(
        {
            customerId: currentCustomerId || '',
        },
        {
            skip: !currentCustomerId || !isAuthenticated,
        }
    );

    useEffect(() => {
        if (customerIdFromUrl && customerIdFromUrl !== currentCustomerId) {
            setCurrentCustomerId(customerIdFromUrl);
        }
    }, [customerIdFromUrl]);

    if (error) {
        console.error(error);
        throw {
            error: true,
            message: 'Unable to fetch projects list.',
        };
    }

    const setCurrentCustomerId = (customerId: string) => {
        dispatch(setCurrentCustomerIdAction(customerId));
    };

    return {
        allCustomers,
        currentCustomerId,
        currentCustomerDetails,
        currentCustomerName: currentCustomerDetails?.customerName,
        setCurrentCustomerId,
        currentCustomerProjects,
        isFetchingCurrentCustomerProjects,
    };
};

export default useCustomer;
