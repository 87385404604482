import React, { useEffect, useState } from 'react';
import { SelectedDateRange } from 'src/shared/components/filters/date-filter/DateFilter';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { useAppDispatch } from 'src/store';
import { Loader, LinearLoader } from 'src/shared/components/loader/Loader';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import SamplingEventFilter from 'src/shared/components/filters/sampling-event-filter/SamplingEventFilter';
import { AppName } from 'src/global/globalConfig';
import useProjectFilters, { SamplingEventFilterData } from 'src/shared/hooks/useProjectFilters';
import useFeature from 'src/shared/hooks/useFeature';
import { Resources } from 'src/shared/types';
import Metrics from './components/metrics/Metrics';
import useDashboardAnalytics from './hooks/useDashboardAnalytics';
import styles from './Dashboard.module.scss';
import FreemiumDashboard from './components/freemium-dashboard/FreemiumDashboard';
import { setSelectedDateRange } from '../projects/views/project-list/state/projectListSlice';

type SamplingEventDetails = {
    start: SamplingEventFilterData;
    end: SamplingEventFilterData;
};

const DASHBOARD_TITLE = 'Nature Performance Dashboard';

const Dashboard = () => {
    const {
        currentProjectSelectedDateRange,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        currentProjectFilters,
        setCurrentProjectHabitatAssayType,
        currentProjectSelectedHabitatAssayType,
        samplingEventFilterData,
    } = useProjectFilters();

    const { hasFeature } = useFeature();
    const isDashboardAvailable = hasFeature({
        resource: Resources.DASHBOARD,
    });

    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(DASHBOARD_TITLE);

    const [samplingEventDetails, setSamplingEventDetails] = useState<SamplingEventDetails>();

    const habitatAssays = currentProjectFilters?.habitatAssays;
    const isSingleTimepoint = !!habitatAssays?.find(entry => entry.key === currentProjectSelectedHabitatAssayType?.key)?.singleTimepoint;

    const { trackDateFilterChange } = useDashboardAnalytics();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!samplingEventFilterData || samplingEventFilterData.length === 0) {
            return;
        }
        setSamplingEventDetails({
            start: samplingEventFilterData.at(0) as SamplingEventFilterData,
            end: samplingEventFilterData.at(-1) as SamplingEventFilterData,
        });
    }, [samplingEventFilterData]);

    if (!isDashboardAvailable) {
        return <FreemiumDashboard />;
    }

    if (!samplingEventFilterData) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    const onDateRangeChange = (dateRange: SelectedDateRange) => {
        trackDateFilterChange(`${dateRange.startDate} - ${dateRange.endDate}`);
        dispatch(setSelectedDateRange(dateRange));
    };

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={setCurrentProjectHabitatAssayType}
            app={AppName.DASHBOARD}
        />
    );

    const selectedStartSamplingEvent =
        samplingEventFilterData.find(entry => entry.fromDate === currentProjectSelectedDateRange?.startDate) || samplingEventFilterData[0];
    const selectedEndSamplingEvent =
        samplingEventFilterData.find(entry => entry.toDate === currentProjectSelectedDateRange?.endDate) || samplingEventFilterData.at(-1);

    const otherActions =
        !isSingleTimepoint && samplingEventDetails?.start ? (
            <div className={styles.nowrap}>
                <span>Displaying from</span>
                <span>
                    <SamplingEventFilter
                        dataTestId='dashboard-start-sampling-event-filter'
                        selectedSamplingEvent={selectedStartSamplingEvent}
                        samplingEventFilterData={samplingEventFilterData}
                        onChange={value =>
                            onDateRangeChange({
                                startDate: value.fromDate,
                                endDate:
                                    currentProjectSelectedDateRange?.endDate ||
                                    (samplingEventFilterData.at(-1) as SamplingEventFilterData).toDate,
                            })
                        }
                    />
                </span>
                <span>to</span>
                <span>
                    <SamplingEventFilter
                        dataTestId='dashboard-end-sampling-event-filter'
                        selectedSamplingEvent={selectedEndSamplingEvent as SamplingEventFilterData}
                        samplingEventFilterData={samplingEventFilterData}
                        onChange={value =>
                            onDateRangeChange({
                                startDate: currentProjectSelectedDateRange?.startDate || samplingEventFilterData[0].fromDate,
                                endDate: value.toDate,
                            })
                        }
                    />
                </span>
            </div>
        ) : null;
    return (
        <StandardAppLayout
            lozenges={lozenges}
            mainContent={<Metrics />}
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={DASHBOARD_TITLE}
            subTitle='Trends and indicators'
            otherActions={otherActions}
            page={AppName.DASHBOARD}
        />
    );
};

export default Dashboard;
