import React from 'react';
import CompleteIcon from 'src/assets/svg/general/complete.svg?react';
import NoneIcon from 'src/assets/svg/general/none.svg?react';
import { FieldDataStatus } from 'src/shared/types';

type FieldDataStatusIndicatorProps = {
    status: FieldDataStatus;
};

const icons = {
    [FieldDataStatus.NONE]: <NoneIcon className='fill-grey-80' data-testid='none-icon' />,
    [FieldDataStatus.INCOMPLETE]: <NoneIcon className='fill-orange-100' data-testid='none-icon' />,
    [FieldDataStatus.COMPLETE]: <CompleteIcon data-testid='complete-icon' />,
};

const FieldDataStatusIndicator = (props: FieldDataStatusIndicatorProps) => {
    const { status } = props;

    return (
        <div className='flex items-center gap-1 px-2'>
            <div>{icons[status]}</div>
            <div className='text-sm'>{status}</div>
        </div>
    );
};

export default FieldDataStatusIndicator;
