import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectSampleManifestStatus, SortOrder, SubscriptionType } from 'src/shared/types';
import { SampleReceptionSortField } from './api/sampleReceptionGraphSlice';

export enum SampleReceptionPendingAction {
    NO_PENDING_ACTIONS = 'NO_PENDING_ACTIONS',
    PENDING_NM_APPROVAL = 'PENDING_NM_APPROVAL',
    PENDING_CUSTOMER_AMENDMENT = 'PENDING_CUSTOMER_AMENDMENT',
}

export type SampleReceptionFilterState = {
    searchText: string;
    subscriptions: SubscriptionType[];
    statuses: ProjectSampleManifestStatus[];
    pendingActions: SampleReceptionPendingAction[];
    sortBy: {
        field: SampleReceptionSortField;
        order: SortOrder;
    };
    currentPageNumber: number;
};

export type SampleReceptionState = {
    filters: SampleReceptionFilterState;
};

export const defaultFilterState: SampleReceptionFilterState = {
    searchText: '',
    subscriptions: [SubscriptionType.BASIC],
    statuses: [
        ProjectSampleManifestStatus.NOT_STARTED,
        ProjectSampleManifestStatus.IN_PROGRESS_NO_DATA_SUBMITTED,
        ProjectSampleManifestStatus.IN_PROGRESS_MORE_SAMPLES_REQUIRED,
        ProjectSampleManifestStatus.IN_PROGRESS_ALL_SAMPLES_SUBMITTED,
        ProjectSampleManifestStatus.COMPLETED_NO_FUTURE_EVENT_PLANNED,
        ProjectSampleManifestStatus.COMPLETED_FUTURE_EVENT_PLANNED,
        ProjectSampleManifestStatus.COMPLETED_ALL_EVENTS_COMPLETE,
    ],
    pendingActions: [
        SampleReceptionPendingAction.NO_PENDING_ACTIONS,
        SampleReceptionPendingAction.PENDING_NM_APPROVAL,
        SampleReceptionPendingAction.PENDING_CUSTOMER_AMENDMENT,
    ],
    sortBy: {
        field: SampleReceptionSortField.KITS_SENT_AT,
        order: SortOrder.DESC,
    },
    currentPageNumber: 1,
};

export const initialState: SampleReceptionState = {
    filters: defaultFilterState,
};

export const sampleReceptionSlice = createSlice({
    name: 'sampleReception',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<Partial<SampleReceptionFilterState>>) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = { ...defaultFilterState };
        },
    },
});

export const { updateFilters, resetFilters } = sampleReceptionSlice.actions;

export default sampleReceptionSlice.reducer;
