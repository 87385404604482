import React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

type LoaderProps = {
    padding?: string;
};

export const Loader = ({ padding }: LoaderProps) => {
    const boxPadding = padding || '20rem';
    return (
        <div className='container mx-auto px-6'>
            <div style={{ textAlign: 'center', padding: `${boxPadding} 0` }} data-testid='loader'>
                <CircularProgress sx={{ color: '#53bdbf' }} />
                <div className='text-grey-60'>loading...</div>
            </div>
        </div>
    );
};
export const LoaderInline = () => {
    return (
        <Stack sx={{ color: 'grey.500' }} spacing={2} direction='row' data-testid='inline-loader'>
            <CircularProgress sx={{ color: '#53bdbf' }} />
        </Stack>
    );
};

export const LinearLoader = () => {
    return (
        <div data-testid='linear-loader'>
            <LinearProgress
                sx={{
                    backgroundColor: '#53bdbf40',
                    '& .MuiLinearProgress-bar1Indeterminate': {
                        backgroundColor: '#53bdbf60',
                    },
                    '& .MuiLinearProgress-bar2Indeterminate': {
                        backgroundColor: '#53bdbf80',
                    },
                }}
            />
        </div>
    );
};

export default Loader;
