import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { useSpeciesTrendsQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { transformData } from 'src/app/insights/views/species-trends/SpeciesTrends.utils';
import { AppName } from 'src/global/globalConfig';
import TimeSeriesReChart from 'src/shared/components/charts/time-series/TimeSeriesReChart';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import { useAppDispatch, useAppSelector } from 'src/store';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { chartExplainerSlugKey } from 'src/shared/constants/chartExplainerKeys';
import InputLabel from '@mui/material/InputLabel';
import styles from './SpeciesTrends.module.scss';
import { updateFilters } from '../../state/species-trends/speciesTrendsSlice';
import { getCurrentSpeciesTrendsUIState } from '../../state/species-trends/speciesTrendsSelector';

const PAGE_TITLE = 'Species Trends';

const SpeciesTrends = () => {
    const {
        currentProjectId,
        currentProjectFilters,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        setCurrentProjectHabitatAssayType,
        getColourForArea,
    } = useProjectFilters();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);

    const dispatch = useAppDispatch();

    const [metricOptions, setMetricOptions] = useState<SelectOption[]>([
        {
            label: 'None available',
            value: 'none',
        },
    ]);

    const uiState = useAppSelector(getCurrentSpeciesTrendsUIState);

    const {
        filters: { chartId },
    } = uiState;

    useEffect(() => {
        if (!currentProjectFilters || !habitatAssayType) {
            return;
        }

        const { habitatAssays } = currentProjectFilters;

        const newMetricsOptions = habitatAssays
            .find(entry => entry.key === habitatAssayType?.key)
            ?.metrics.map(metric => ({
                label: startCase(metric.metricName),
                value: metric.metricKey,
            })) as SelectOption[];

        setMetricOptions(newMetricsOptions);
        if (!chartId) {
            dispatch(
                updateFilters({
                    chartId: newMetricsOptions[0].value,
                })
            );
        }
    }, [currentProjectFilters?.habitatAssays, habitatAssayType, chartId]);

    const { currentData, isFetching } = useSpeciesTrendsQuery(
        {
            projectId: currentProjectId || '',
            chartId,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType || !chartId,
        }
    );

    if (!currentData || isFetching || !currentProjectId) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    const { speciesTrends: speciesRichnessInsightsData } = currentData;

    const timeSeriesData = speciesRichnessInsightsData && transformData(speciesRichnessInsightsData.data[0].data, getColourForArea);

    const onMetricChange = (value: string) => {
        dispatch(
            updateFilters({
                chartId: value,
            })
        );
    };

    const otherActions = (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputLabel style={{ marginRight: '8x' }}>Metric</InputLabel>
                <QuestionButtonHelp type='api' placement='right' slug='metrics-explainer' detailedExplainerSlug='species-trends-te' />
            </div>
            <Select options={metricOptions} onChange={onMetricChange} selectedValue={chartId} width={280} />
        </>
    );

    const mainContent = (
        <div className='p-6 bg-white rounded-lg shadow-sm'>
            <div className={styles.chartTitle}>
                <div className={styles.titleValue}>{find(metricOptions, { value: chartId })?.label}</div>
                {chartExplainerSlugKey[chartId] && (
                    <QuestionButtonHelp type='api' placement='right' slug={chartExplainerSlugKey[chartId]} />
                )}
            </div>
            {!isEmpty(speciesRichnessInsightsData) ? (
                <TimeSeriesReChart chartData={timeSeriesData} />
            ) : (
                <div className='text-lg'>No Metrics to display</div>
            )}
        </div>
    );

    const onHabitatAssayTypeChange = (value: string) => {
        onMetricChange('');
        setCurrentProjectHabitatAssayType(value);
    };

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={onHabitatAssayTypeChange}
            app={AppName.SPECIES_TRENDS}
        />
    );

    return (
        <StandardAppLayout
            lozenges={lozenges}
            mainContent={mainContent}
            otherActions={otherActions}
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={PAGE_TITLE}
            subTitle='Time series by sample group'
            page={AppName.SPECIES_TRENDS}
        />
    );
};

export default SpeciesTrends;
