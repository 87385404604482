import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type HabitatMapFiltersState = {
    year: string;
    quarter: number;
    selectedSites: string[];
    metric: string;
    metricLabel: string;
    shouldShowBufferArea: boolean;
};

const defaultFiltersState: HabitatMapFiltersState = {
    year: 'none',
    quarter: 0,
    selectedSites: [],
    metric: 'none',
    metricLabel: '',
    shouldShowBufferArea: false,
};

export type HabitatInsightsUIState = {
    filters: HabitatMapFiltersState;
    isSidebarOpen: boolean;
    mapType: string;
};

export const defaultHabitatInsightsUiState = {
    filters: { ...defaultFiltersState },
    isSidebarOpen: false,
    mapType: 'roadmap',
};

type HabitatInsightsState = {
    uiState: {
        [projectId: string]: HabitatInsightsUIState;
    };
};
const initialState: HabitatInsightsState = {
    uiState: {},
};

export const updateFilters =
    (fragment: Partial<HabitatMapFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                habitatMapSlice.actions.updateFilters({
                    projectId,
                    fragment,
                })
            );
        }
    };

export const updateUiState =
    (fragment: Partial<HabitatInsightsUIState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();
        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                habitatMapSlice.actions.updateUIState({
                    projectId,
                    fragment,
                })
            );
        }
    };

export const resetFilters = (): ThunkAction<void, RootState, void, Action<string>> => (dispatch, getState) => {
    const state = getState();

    const projectId = state.projects.projectList.currentProjectId;

    if (projectId) {
        dispatch(
            habitatMapSlice.actions.resetFilters({
                projectId,
            })
        );
    }
};

const ensureProjectUIStatePresence = (state: HabitatInsightsState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            filters: { ...defaultFiltersState },
            isSidebarOpen: true,
            mapType: 'roadmap',
        };
    }
};

const habitatMapSlice = createSlice({
    name: 'HabitatMap',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<{ projectId: string; fragment: Partial<HabitatMapFiltersState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId].filters = {
                ...(state.uiState[projectId].filters as HabitatMapFiltersState),
                ...fragment,
            };
        },

        updateUIState(state, action: PayloadAction<{ projectId: string; fragment: Partial<HabitatInsightsUIState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId] = {
                ...(state.uiState[projectId] as HabitatInsightsUIState),
                ...fragment,
            };
        },
        resetFilters(state, action: PayloadAction<{ projectId: string }>) {
            const { projectId } = action.payload;
            state.uiState[projectId].filters = { ...defaultFiltersState };
        },
    },
});

export default habitatMapSlice.reducer;
