import React from 'react';
import SignificantDifference from 'src/assets/images/insights/community-comparison/significant-difference.svg?react';
import NoSignificantDifference from 'src/assets/images/insights/community-comparison/no-significant-difference.svg?react';

import styles from './MetricViewDifferenceIndicator.module.scss';

type DifferenceIndicatorProps = {
    isDifferent: boolean;
};

const MetricViewDifferenceIndicator = (props: DifferenceIndicatorProps) => {
    const { isDifferent } = props;
    return (
        <div className={styles.metricViewContainer}>
            <div>
                {isDifferent ? (
                    <SignificantDifference data-testid='significant-difference-svg' />
                ) : (
                    <NoSignificantDifference data-testid='no-significant-difference-svg' />
                )}
            </div>
            <div className={styles.textContent}>
                {isDifferent ? 'Significant difference' : 'No significant difference'}
                <div>in compositon of communities</div>
            </div>
        </div>
    );
};

export default MetricViewDifferenceIndicator;
