import React, { JSX, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';

import ExpandableSection from 'src/shared/components/expandable-section/ExpandableSection';
import Textarea from 'src/shared/components/textarea/Textarea';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import styles from '../AssignProjects.module.scss';

type WarningSectionElementProps = {
    title: string;
    onChangeUserLimitRequest?: (value: string) => void;
    value?: string;
    severity: 'warning' | 'error' | 'info';
    icon: JSX.Element;
    count: number;
    role: string;
    contentSlug: string;
};

const WarningSectionElement = ({
    title,
    onChangeUserLimitRequest,
    value,
    severity,
    icon,
    count,
    contentSlug,
    role,
}: WarningSectionElementProps) => {
    const [isExpanded, setExpanded] = useState<boolean>(severity === 'error');
    const [textAreaValue, setTextAreaValue] = useState<string>(value || '');
    const headerElement = (
        <span className={styles.info}>
            <Alert severity={severity} icon={icon} className={styles.error}>
                {title}
            </Alert>
        </span>
    );

    const toggleControlOverride =
        severity === 'error'
            ? {
                  expanded: null,
                  collapsed: null,
              }
            : undefined;

    useEffect(() => {
        setExpanded(severity === 'error');
    }, [severity]);

    useEffect(() => {
        setTextAreaValue(value || '');
    }, [value]);

    const onChange = (value: string) => {
        setTextAreaValue(value);
        if (onChangeUserLimitRequest) {
            onChangeUserLimitRequest(value);
        }
    };
    const expandedContent = (
        <>
            {headerElement}
            <span className={styles.content}>
                <span>
                    <CMSArticle
                        slug={contentSlug}
                        substitutions={{
                            count: String(count),
                            role: role.toLowerCase(),
                        }}
                    />
                </span>
                {onChangeUserLimitRequest && (
                    <Textarea onChange={event => onChange(event.target.value)} value={textAreaValue} maxLength={150} />
                )}
            </span>
        </>
    );

    return (
        <ExpandableSection
            isExpanded={isExpanded}
            expandedContent={expandedContent || null}
            collapsedContent={headerElement}
            classNames={{
                expanded: `${styles.expandedSection} ${styles[`expandedSection${severity}`]}`,
                chevron: styles.chevron,
            }}
            toggleControlOverride={toggleControlOverride}
            onToggle={setExpanded}
        />
    );
};

export default WarningSectionElement;
