import React from 'react';
import Select, { SelectOption } from 'src/shared/components/select/Select';

import { SamplingEventFilterData } from 'src/shared/hooks/useProjectFilters';
import styles from './SamplingEventFilter.module.scss';

export type SelectedDateRange = {
    startDate: string;
    endDate: string;
};

type DateFilterProps = {
    dataTestId?: string;
    selectedSamplingEvent: SamplingEventFilterData | null;
    samplingEventFilterData: SamplingEventFilterData[];
    onChange: (samplingEvent: SamplingEventFilterData) => void;
    width?: string | number;
};

const SamplingEventFilter = (props: DateFilterProps) => {
    const { selectedSamplingEvent, samplingEventFilterData, onChange, width, dataTestId = 'sampling-event-filter' } = props;

    if (!samplingEventFilterData) {
        return null;
    }

    const newSamplingEventOptions: SelectOption[] = samplingEventFilterData.map((option, i) => {
        const selectedLabel = `Year ${option.year}, Event ${option.event}`;
        return {
            label: (
                <div className={styles.samplingEventsLabelWrapper}>
                    <span className={styles.labelNumber}>{i + 1}</span>
                    <div className={styles.labelText}>
                        <span>{selectedLabel}</span>
                        <span className={styles.row2}>{option.name}</span>
                    </div>
                </div>
            ),
            value: i + '',
            selectedLabel,
        };
    });

    const selectedLabel = selectedSamplingEvent ? `Year ${selectedSamplingEvent.year}, Event ${selectedSamplingEvent.event}` : '';
    const selectedValue = newSamplingEventOptions.findIndex(option => option.selectedLabel === selectedLabel) || 0;

    return (
        <Select
            dataTestId={dataTestId}
            width={width}
            options={newSamplingEventOptions}
            onChange={value => onChange(samplingEventFilterData[+value])}
            selectedValue={selectedValue + ''}
            renderValueFormatter={value => {
                return newSamplingEventOptions[+value]?.selectedLabel || '';
            }}
        />
    );
};

export default SamplingEventFilter;
