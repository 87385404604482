import { useEffect } from 'react';
import { useAppDispatch } from 'src/store';
import { loadStoredCustomerId } from 'src/app/customers/state/customersSlice';
import { loadStoredProjectId } from 'src/app/projects/views/project-list/state/projectListSlice';
import { loadStoredRoutesConfig } from 'src/routes/state/routesSlice';
import useAuthService from 'src/shared/hooks/useAuthService';
import { once } from 'lodash';

// load redux state from browser storage once when the application mounts
const useRestoredReduxState = () => {
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuthService();

    useEffect(() => {
        const restoreState = once(async () => {
            if (isAuthenticated) {
                // Projects
                dispatch(loadStoredProjectId());

                // Customers
                dispatch(loadStoredCustomerId());

                // Routes config
                dispatch(loadStoredRoutesConfig());
            }
        });
        restoreState();
    }, []);
};

export default useRestoredReduxState;
