import { useState, useRef, useCallback, useEffect } from 'react';

import { transformFiles, transformFile } from '../helpers/uploadHelpers';

import type { Options, Result, FileUploadParams } from '../types';

const useFileDrop = <O extends Options>(params: FileUploadParams<O>) => {
    const [isDraggedOver, setIsDraggedOver] = useState(false);
    const elementRef = useRef<HTMLElement | null>(null);
    const paramsRef = useRef(params);

    // keep count of all dragenter and dragleave events from the component and its children
    // to correctly detect when the dragged file is removed from the component
    // (https://stackoverflow.com/a/21002544/3925302)
    const counterRef = useRef(0);

    useEffect(() => {
        paramsRef.current = params; // update at every rerender
    });

    const onDragEnter = (event: DragEvent) => {
        event.stopPropagation();
        counterRef.current += 1;
        if (event.dataTransfer?.types.some(type => type === 'Files')) {
            setIsDraggedOver(true);
        }
    };

    const onDragLeave = (event: DragEvent) => {
        event.stopPropagation();
        counterRef.current -= 1;
        if (counterRef.current === 0) {
            setIsDraggedOver(false);
        }
    };

    const onFileDrop = async (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDraggedOver(false);
        counterRef.current = 0;

        const params = paramsRef.current; // always use up-to-data params
        const { onUpload, transformTo, allowMultiple } = params;

        const accepts = params.accept?.split(',');
        const files = getFilesFromDragEvent(event).filter(file => !accepts?.length || accepts.includes(file.type));

        if (!files || !files.length) {
            return;
        }

        if (!transformTo) {
            const payload = allowMultiple ? [...files] : files[0];
            onUpload(payload as Result<O>);
        } else {
            const result = allowMultiple ? await transformFiles(files, transformTo) : await transformFile(files[0], transformTo);
            onUpload(result as Result<O>);
        }
    };
    const onDragOver = (event: DragEvent) => {
        event.preventDefault();
    };

    const ref = useCallback((element: HTMLElement | null) => {
        if (element === null) {
            // a function ref is called with null when a component that it is referencing unmounts
            elementRef.current?.removeEventListener('dragenter', onDragEnter);
            elementRef.current?.removeEventListener('dragleave', onDragLeave);
            elementRef.current?.removeEventListener('dragover', onDragOver);
            elementRef.current?.removeEventListener('drop', onFileDrop);
            return;
        }

        elementRef.current = element;
        element.addEventListener('dragenter', onDragEnter);
        element.addEventListener('dragleave', onDragLeave);
        element.addEventListener('dragover', onDragOver);
        element.addEventListener('drop', onFileDrop);
    }, []);

    return {
        ref,
        isDraggedOver,
    };
};

// (see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#process_the_drop)
const getFilesFromDragEvent = (event: DragEvent): File[] => {
    const { dataTransfer } = event;
    if (!dataTransfer) {
        return [];
    }
    return [...dataTransfer.items].map(item => item.getAsFile()).filter(Boolean) as File[];
};

export default useFileDrop;
