import { useAppDispatch, useAppSelector } from 'src/store';
import { ProgressStatus, ProjectSamplingCadenceType, SubscriptionType, BasicProjectType, CMSHabitatAssayType } from 'src/shared/types';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { useEffect } from 'react';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import { NewProjectDefinitionFormState, setFormState } from '../state/newProjectDefinitionSlice';
import { useLazyProjectsQuery } from '../../project-list/state/api/projectListGraphSlice';
import {
    useCreateProjectDefinitonMutation,
    useHasProjectDefinitionQuery,
    useLazyHasProjectDefinitionQuery,
} from '../state/api/newProjectDefinitionGraphSlice';
import { getCurrentProjectDefinitionFormState, getCurrentProjectDefinitionFormStep } from '../state/newProjectDefinitionSelector';
import { validateSamplingEvent } from '../components/sampling-cadence/samplingCadence.utils';

const useProjectDefinition = () => {
    const formStep = useAppSelector(getCurrentProjectDefinitionFormStep);
    const formState = useAppSelector(getCurrentProjectDefinitionFormState);
    const { currentCustomerId, currentCustomerProjects } = useCustomer();

    const navigate = useAppNavigation();

    const { data: projectDefinitionAvailabilityData, isFetching: isCheckingProjectDefinitionPresence } = useHasProjectDefinitionQuery(
        {
            projectId: formState.projectId,
        },
        {
            skip: !formState.projectId,
        }
    );

    const dispatch = useAppDispatch();

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    useEffect(() => {
        if (projectDefinitionAvailabilityData) {
            onFormChange({
                projectDefinitionCompletionStatus: projectDefinitionAvailabilityData.hasProjectDefinition,
            });
        }
    }, [projectDefinitionAvailabilityData, isCheckingProjectDefinitionPresence]);

    const {
        projectName,
        projectId,
        projectCode,
        subscriptionType,
        country,
        area,
        projectLeadRole,
        projectLeadEmail,
        projectLeadName,
        projectType,
        projectEndDate,
        targetOutcomes,
        samplingCadence,
        samplingEvents,
        projectLifespanYears,
        samplingEventsPerYear,
        sampleGroups,
        speciesOfInterest,
        habitatAssayTypes,
        initialSampleEstimate,
        estimatedSampleCountForTestTypes,
        projectDefinitionCompletionStatus,
        notifyUsersOnChange,
        hasDefinedSampleEvents,
    } = formState;

    const { exists, metadata } = projectDefinitionCompletionStatus || {};

    // Edit mode is when the project definition is completed and the project has no samples
    const isEmptyProject = !Boolean(currentCustomerProjects?.find(entry => entry.projectId === projectId)?.samplesCount);
    const isEditMode = exists && metadata?.status === ProgressStatus.COMPLETED && isEmptyProject;

    // Check if the project definition is in progress by the current user or a different user
    const isProjectStatusPending = projectDefinitionCompletionStatus && exists && metadata?.status === ProgressStatus.PENDING;
    const isInProgressByCurrentUser = isEditMode || (isProjectStatusPending && metadata?.isCreatedByCurrentUser);
    const isInProgressByDifferentUser = isProjectStatusPending && !metadata?.isCreatedByCurrentUser;

    // User can continue the form if it's in edit mode or the project definition is in progress by the current user
    const canCurrentUserContinueForm = isEditMode || (projectDefinitionCompletionStatus && (!exists || isInProgressByCurrentUser));

    const [createProjectDefiniton] = useCreateProjectDefinitonMutation();
    const [fetchProjects] = useLazyProjectsQuery();
    const [refetchHasProjectDefinition] = useLazyHasProjectDefinitionQuery();

    useEffect(() => {
        if ([!isInProgressByCurrentUser, country, area, projectLeadRole].some(Boolean)) {
            return;
        }

        const fetchAndRestore = () => {
            const existingDefinition = currentCustomerProjects?.find(entry => entry.projectId === projectId);

            if (!existingDefinition) {
                return;
            }

            const existingDefinitionEvents = existingDefinition.samplingCadence?.events
                .filter(event => event.year === 1)
                .map(event => ({
                    name: event.name,
                    fromDate: event.fromDate,
                    toDate: event.toDate,
                }));

            onFormChange({
                subscriptionType: existingDefinition.subscriptionType,
                country: existingDefinition.country,
                area: existingDefinition.area,
                projectLeadRole: existingDefinition.leadRole,
                projectLeadEmail: existingDefinition.leadEmail,
                projectLeadName: existingDefinition.leadName,
                projectType: existingDefinition.projectType,
                projectCode: existingDefinition.projectCode,
                projectEndDate: existingDefinition.projectEndDate,
                targetOutcomes: existingDefinition.targetOutcome,
                samplingCadence: existingDefinition.samplingCadence?.type,
                samplingEvents: existingDefinitionEvents,
                estimatedSampleCountForTestTypes: existingDefinition.estimatedSampleCountForTestTypes || [],
                projectLifespanYears: existingDefinition.projectLifespanYears,
                samplingEventsPerYear: existingDefinition.samplingCadence?.samplingEventsPerYear,
                initialSampleEstimate: existingDefinition.estimatedSampleCounts,
                sampleGroups: existingDefinition.sampleGroups,
                speciesOfInterest: existingDefinition.speciesOfInterest,
                habitatAssayTypes: existingDefinition.habitatAssay as Pick<
                    CMSHabitatAssayType,
                    'assayName' | 'habitatAssayKey' | 'habitatName'
                >[],
                // TODO: ProjectDefinitionMap Temporarily disabling it
                // initialSampleEntries: existingDefinition.initialSampleEntries,
                initialSampleEntries: [],
                notifyUsersOnChange: existingDefinition.notifyUsersOnChange,
                hasDefinedSampleEvents: existingDefinition.hasDefinedSampleEvents,
            });
        };
        fetchAndRestore();
    }, [isInProgressByCurrentUser]);

    const submitProjectDefinitionForm = async () => {
        if (!currentCustomerId || !projectType) {
            return;
        }

        try {
            await createProjectDefiniton({
                customerId: currentCustomerId,
                projectName,
                projectId,
                projectCode,
                subscriptionType: subscriptionType,
                country,
                area,
                projectLeadRole,
                projectLeadEmail,
                projectLeadName,
                projectEndDate,
                projectType: projectType,
                targetOutcome: targetOutcomes,
                projectLifespanYears,
                samplingEventsPerYear,
                samplingCadenceType: (samplingCadence as ProjectSamplingCadenceType) || ProjectSamplingCadenceType.SINGLE_YEAR,
                samplingEvent: samplingEvents,
                sampleGroups,
                speciesOfInterest,
                initialSampleEstimate,
                estimatedSampleCountForTestTypes: estimatedSampleCountForTestTypes,
                habitatAssay: habitatAssayTypes,
                status: ProgressStatus.COMPLETED,
                // TODO: ProjectDefinitionMap Temporarily disabling it by passing empty array
                initialSampleEntries: [],
                notifyUsersOnChange,
                hasDefinedSampleEvents,
            }).unwrap();
        } catch (error) {
            console.error('rejected', error);
        }
    };

    const saveProjectDefinitionForm = async () => {
        if (!currentCustomerId || !projectId) {
            return;
        }

        try {
            await createProjectDefiniton({
                customerId: currentCustomerId,
                projectName,
                projectId,
                projectCode,
                projectEndDate,
                subscriptionType: subscriptionType || 'Basic',
                country,
                area,
                projectLeadRole,
                projectLeadEmail,
                projectLeadName,
                projectType: projectType || 'MAINTAIN',
                targetOutcome: targetOutcomes,
                samplingCadenceType: (samplingCadence as ProjectSamplingCadenceType) || ProjectSamplingCadenceType.SINGLE_YEAR,
                projectLifespanYears,
                samplingEventsPerYear,
                samplingEvent: samplingEvents,
                sampleGroups,
                speciesOfInterest,
                initialSampleEstimate,
                estimatedSampleCountForTestTypes: estimatedSampleCountForTestTypes,
                habitatAssay: habitatAssayTypes,
                status: ProgressStatus.PENDING,
                // TODO: ProjectDefinitionMap Temporarily disabling it by passing empty array
                initialSampleEntries: [],
                notifyUsersOnChange,
                hasDefinedSampleEvents,
            }).unwrap();

            await fetchProjects({
                customerId: currentCustomerId,
            }).unwrap();

            await refetchHasProjectDefinition({
                projectId,
            }).unwrap();

            navigate.toProjectAdmin();
        } catch (error) {
            console.error('rejected', error);
        }
    };

    const hasCompletedCurrentStep = () => {
        switch (formStep) {
            case 0:
                const mandatoryData = [projectName, subscriptionType, country, projectLeadRole];
                if (subscriptionType !== SubscriptionType.BASIC) {
                    mandatoryData.push(...[area, projectEndDate]);
                }
                return mandatoryData.every(Boolean);
            case 1:
                return projectType === BasicProjectType.GENERIC
                    ? [projectType, habitatAssayTypes.length].every(Boolean)
                    : [projectType, targetOutcomes, sampleGroups.length, habitatAssayTypes.length].every(Boolean);

            case 2:
                const isBasicSubscription = subscriptionType === SubscriptionType.BASIC;
                const isSamplingEventsKnown = formState.hasDefinedSampleEvents;

                // Skip validation if the subscription is basic and the user doesn't know the sampling events
                if (isBasicSubscription && !isSamplingEventsKnown) {
                    return true;
                }

                // Validation samplingCadence and samplingEventsPerYear for Insights subscription
                if (!isBasicSubscription && (!samplingCadence || !samplingEventsPerYear)) {
                    return false;
                }

                // Validation samplingEventsPerYear for Basic subscription & user knows the sampling events
                if (isBasicSubscription && isSamplingEventsKnown && !samplingEventsPerYear) {
                    return false;
                }

                const validEvents = samplingEvents.map((event, index) => !validateSamplingEvent(formState, index).hasError);

                return Array(samplingEventsPerYear)
                    .fill('')
                    .every((_, index) => {
                        return validEvents[index];
                    });

            case 3:
                // TODO: ProjectDefinitionMap Temporarily disabling this until we need it
                // if (subscriptionType === SubscriptionType.INSIGHTS) {
                //     const { uploadedFileErrors } = formState;
                //     return [!uploadedFileErrors.length, initialSampleEntries.length].every(Boolean);
                // }

                if (subscriptionType === SubscriptionType.INSIGHTS) {
                    return true;
                }
                return [
                    initialSampleEstimate,
                    estimatedSampleCountForTestTypes.length,
                    estimatedSampleCountForTestTypes.every(entry => entry.count && entry.count <= initialSampleEstimate),
                ].every(Boolean);
            default:
                return true;
        }
    };

    return {
        hasCompletedCurrentStep,
        submitProjectDefinitionForm,
        saveProjectDefinitionForm,
        onFormChange,
        formState,
        formStep,
        isInProgressByDifferentUser,
        isDefinitionExists: exists,
        canCurrentUserContinueForm,
        isCheckingProjectDefinitionPresence,
        isBasicAndGenericProject:
            formState.subscriptionType === SubscriptionType.BASIC && formState.projectType === BasicProjectType.GENERIC,
        isEditMode,
    };
};

export default useProjectDefinition;
