import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type SpeciesTrendsFiltersState = {
    habitatAssayType: string;
    chartId: string;
};

const defaultFiltersState = {
    habitatAssayType: '',
    chartId: '',
};

export type SpeciesTrendsUIState = {
    filters: SpeciesTrendsFiltersState;
};

export const defaultUiState = {
    filters: { ...defaultFiltersState },
};

type SpeciesTrendsState = {
    uiState: {
        [projectId: string]: SpeciesTrendsUIState;
    };
};
const initialState: SpeciesTrendsState = {
    uiState: {},
};

export const updateFilters =
    (fragment: Partial<SpeciesTrendsFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                speciesTrendsSlice.actions.updateFilters({
                    projectId,
                    fragment,
                })
            );
        }
    };

const ensureProjectUIStatePresence = (state: SpeciesTrendsState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            filters: { ...defaultFiltersState },
        };
    }
};

const speciesTrendsSlice = createSlice({
    name: 'speciesTrends',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<{ projectId: string; fragment: Partial<SpeciesTrendsFiltersState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId].filters = {
                ...(state.uiState[projectId].filters as SpeciesTrendsFiltersState),
                ...fragment,
            };
        },
    },
});

export default speciesTrendsSlice.reducer;
