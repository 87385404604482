import React from 'react';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import TestTypeChip from 'src/shared/components/test-type-chip/TestTypeChip';
import CloseIcon from 'src/assets/svg/general/close.svg?react';
import { SamplingKit } from 'src/shared/types';
import classNames from 'classnames';
import { useAppSelector, useAppDispatch } from 'src/store';
import { getIsSavingSamplingKit } from 'src/app/samples/sample-manifest/state/sampleManifestSelector';
import { setIsSavingSamplingKit } from 'src/app/samples/sample-manifest/state/sampleManifestSlice';
import useSampleManifestAddTestsAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestAddTestsAnalytics';

type TestColumnProps = {
    kitId: string;
};

const TestColumn = (props: TestColumnProps) => {
    const { kitId } = props;
    const { kitsReadyToAddTests, saveSamplingKit } = useSamplingEvent();
    const kit = kitsReadyToAddTests.find(kit => kit.id === kitId);

    const isSaving = useAppSelector(getIsSavingSamplingKit);
    const dispatch = useAppDispatch();
    const { trackSampleManifestRemoveTestsIconClick } = useSampleManifestAddTestsAnalytics();

    const removeTestType = async ({ kit, habitatAssayKey }: { kit: SamplingKit; habitatAssayKey: string }) => {
        if (isSaving) {
            return;
        }
        const newTestTypes = kit.testTypes.filter(test => test.habitatAssayKey !== habitatAssayKey);
        try {
            dispatch(setIsSavingSamplingKit(true));
            await saveSamplingKit({
                ...kit,
                testTypes: newTestTypes,
            });
        } finally {
            dispatch(setIsSavingSamplingKit(false));

            // Capture GA for sample manifest add tests - remove tests icon click
            trackSampleManifestRemoveTestsIconClick();
        }
    };

    if (!kit) {
        return null;
    }

    const closeIconClassNames = classNames('cursor-pointer h-4 w-4', {
        'text-grey-60': isSaving,
    });

    return (
        <div className='text-primary flex gap-2'>
            {kit.testTypes.length > 0 ? (
                kit.testTypes.map(test => (
                    <div className='flex items-center bg-[#F6F6F6] rounded-md pr-2' key={test.habitatAssayKey}>
                        <TestTypeChip testTypeKey={test.habitatAssayKey} />
                        <CloseIcon
                            className={closeIconClassNames}
                            onClick={() =>
                                removeTestType({
                                    kit: kit,
                                    habitatAssayKey: test.habitatAssayKey,
                                })
                            }
                        />
                    </div>
                ))
            ) : (
                <div>None added</div>
            )}
        </div>
    );
};

export default TestColumn;
