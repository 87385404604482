import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import { useSearchParams } from 'react-router-dom';
import usePermissions from './usePermissions';
import { Access, Resources } from '../types';

const useProjectSummary = () => {
    const [searchParams] = useSearchParams();
    const projectIdFromUrl = searchParams.get('projectId');
    const customerIdFromUrl = searchParams.get('customerId');

    const { hasPermission } = usePermissions();
    const hasProjectDefinitionViewPermission = hasPermission({
        to: Access.VIEW,
        resource: Resources.PROJECT_DEFINITION,
    });

    const {
        currentData: currentCustomerProjects,
        error,
        refetch: refetchProjectSummary,
    } = useProjectsQuery(
        {
            customerId: customerIdFromUrl || '',
        },
        {
            skip: !customerIdFromUrl || !hasProjectDefinitionViewPermission,
        }
    );

    const getCurrentProjectSummary = () => {
        return currentCustomerProjects?.find(project => project.projectId === projectIdFromUrl);
    };

    if (error) {
        console.error(error);
        throw {
            error: true,
            message: 'Unable to fetch projects list.',
        };
    }

    return {
        projectSummary: getCurrentProjectSummary(),
        customerId: customerIdFromUrl,
        refetchProjectSummary,
    };
};

export default useProjectSummary;
