import React, { useState, useEffect } from 'react';
import { isNull } from 'lodash';
import MapOutlined from '@mui/icons-material/MapOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import SamplingEventBatch from 'src/app/samples/components/sampling-event-batch/SamplingEventBatch';
import { SamplingEventBatchStatus } from 'src/shared/types';
import { SecondaryButton } from 'src/shared/components/button/Button';
import SampleMapModal from 'src/app/samples/components/sample-map-modal/SampleMapModal';
import SamplesCountInfo from 'src/app/samples/components/samples-count-info/SamplesCountInfo';

const ViewSampleManifestContent = () => {
    const [isMapViewDisabled, setIsMapViewDisabled] = useState(true);
    const [isMapViewOpen, setIsMapViewOpen] = useState(false);
    const { hasKitsWithLatLongs } = useSamplingEvent();

    useEffect(() => {
        setIsMapViewDisabled(!hasKitsWithLatLongs);
    }, [hasKitsWithLatLongs]);

    return (
        <div className='flex flex-1 flex-col gap-2'>
            <div className='flex border-b border-grey-40 gap-12 items-center pb-2'>
                <div>
                    <ViewListOutlinedIcon /> List view
                </div>
                <div>
                    <SecondaryButton onClick={() => setIsMapViewOpen(true)} disabled={isMapViewDisabled}>
                        <MapOutlined />
                        &nbsp;View samples on map
                    </SecondaryButton>
                </div>
                <div className='ml-auto'>
                    <SamplesCountInfo />
                </div>
            </div>
            <div className='flex flex-1'>
                <MainContent />
                {isMapViewOpen && <SampleMapModal showTestsInTootip={true} onClose={() => setIsMapViewOpen(false)} />}
            </div>
        </div>
    );
};

const MainContent = () => {
    const { samplingEvent } = useSamplingEvent();

    if (!samplingEvent) {
        return null;
    }

    return (
        <div className='flex flex-col gap-4 w-full'>
            {samplingEvent.sampleManifestInfo.batches.map(batch => {
                // Determine if the amend control should be shown based on the batch status and hide for Logistics controller.
                const isApprovedByLogistics = batch.status === SamplingEventBatchStatus.APPROVED_BY_LOGISTICS;
                const isCustomerAmendmentRequired = batch.status === SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT;
                const hasLogisticsApproval = !isNull(batch.logisticsApprovalAt);
                const showAmendControl = isApprovedByLogistics || (isCustomerAmendmentRequired && hasLogisticsApproval);
                return (
                    <SamplingEventBatch
                        key={batch.id}
                        batchId={batch.id}
                        isViewOnly={true}
                        showAmendControl={showAmendControl}
                        showDownloadControl={true}
                    />
                );
            })}
        </div>
    );
};

export default ViewSampleManifestContent;
