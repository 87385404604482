import React from 'react';
import { useEvolutionaryMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import evolutionaryDiversityImageUrl from 'src/assets/images/dashboard/evolutionary_diversity.svg?url';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import TimeseriesCardTemplate from '../components/TimeseriesCardTemplate';

import styles from '../Metrics.module.scss';

const EvolutionaryMetrics = () => {
    const chartTitle = 'Evolutionary Diversity';
    const {
        currentProjectSelectedDateRange,
        currentProjectId,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
    } = useProjectFilters();
    const { isSingleTimepoint } = useProject();

    const { currentData, isFetching } = useEvolutionaryMetricsQuery(
        {
            projectId: currentProjectId || '',
            fromDate: currentProjectSelectedDateRange?.startDate,
            toDate: currentProjectSelectedDateRange?.endDate,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    if (!currentData) {
        return null;
    }

    const content = (
        <TimeseriesCardTemplate
            title={chartTitle}
            titleHelpSlug='evolutionary-diversity'
            averageValueDescription='Diversity measured in [endYearWithQuarter]'
            metricsChartData={currentData.evolutionaryMetrics}
            metricCardImageUrl={evolutionaryDiversityImageUrl}
            metricCardContent='average genetic diversity per sample'
        />
    );

    if (isSingleTimepoint) {
        return content;
    }

    return <div className={styles.timeseriesCard}>{content}</div>;
};

export default EvolutionaryMetrics;
