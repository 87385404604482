import { useEffect, useRef } from 'react';
import authService from 'src/services/auth-service';
import useAuthService from './useAuthService';

// Logout the user after being idle for 1 hour
const IDLE_LOGOUT_TIMEOUT = 60 * 60 * 1000;

// Refresh the token every 50 minutes
const TOKEN_REFRESH_TIMEOUT = 30 * 60 * 1000;

const EVENTS = ['mousedown', 'keydown', 'touchstart'];

const useAuthServiceTimers = () => {
    const { isAuthenticated, signout } = useAuthService();
    const idleTimerRef = useRef<ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        const autoRefreshTimerId = setInterval(() => {
            authService.refreshSession();
        }, TOKEN_REFRESH_TIMEOUT);

        idleTimerRef.current = setTimeout(() => {
            signout();
        }, IDLE_LOGOUT_TIMEOUT);

        const handleUserActivity = () => {
            if (idleTimerRef.current) {
                clearTimeout(idleTimerRef.current);
            }
            idleTimerRef.current = setTimeout(() => {
                signout();
            }, IDLE_LOGOUT_TIMEOUT);
        };

        const startListening = () => {
            EVENTS.forEach((event: string) => {
                document.addEventListener(event, handleUserActivity);
            });
        };

        const stopListening = () => {
            EVENTS.forEach((event: string) => {
                document.removeEventListener(event, handleUserActivity);
            });
        };

        if (isAuthenticated) {
            startListening();
        }

        return () => {
            clearInterval(autoRefreshTimerId);
            if (idleTimerRef.current) {
                clearTimeout(idleTimerRef.current);
            }
            if (isAuthenticated) {
                stopListening();
            }
        };
    }, [isAuthenticated, idleTimerRef.current]);
};

export default useAuthServiceTimers;
