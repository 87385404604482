import { RootState } from 'src/store';
import { HabitatTrendsUIState, HabitatTrendsFiltersState, defaultUiState } from './habitatTrendsSlice';

export const getCurrentHabitatTrendsUIState = (state: RootState): HabitatTrendsUIState => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }

    return state.insights.habitatTrends.uiState[currentProjectId] || defaultUiState;
};

export const getHabitatTrendsFilters = (state: RootState): HabitatTrendsFiltersState => {
    const currentUiState = getCurrentHabitatTrendsUIState(state);

    return currentUiState.filters;
};
