import React from 'react';
import TabbedCarousel from 'src/shared/components/tabbed-carousel/TabbedCarousel';
import SubmitIcon from 'src/assets/svg/general/submit.svg?react';
import DnaIcon from 'src/assets/svg/general/dna.svg?react';
import ListIcon from 'src/assets/svg/general/list.svg?react';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';

type SubmissionProcessProps = {
    className?: string;
    selectedStep?: number;
};

const SubmissionProcess = (props: SubmissionProcessProps) => {
    const { className } = props;
    const carousel = (
        <TabbedCarousel
            showArrowControls={true}
            classNames={{
                tabsContainer: 'max-w-[700px]',
                tabMainContent: 'max-w-[800px]',
            }}
            tabs={[
                {
                    title: 'Add field data',
                    subTitle: 'Step 1',
                    icon: <ListIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-add-field-data' />
                            </div>
                        </div>
                    ),
                    contentImage: (
                        <div className='h-[200px] w-[200px]'>
                            <CMSArticle slug='sample-manifest-add-field-data-image' />
                        </div>
                    ),
                },
                {
                    title: 'Add tests',
                    subTitle: 'Step 2',
                    icon: <DnaIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-add-tests' />
                            </div>
                        </div>
                    ),
                    contentImage: (
                        <div className='h-[200px] w-[200px]'>
                            <CMSArticle slug='sample-manifest-add-tests-image' />
                        </div>
                    ),
                },
                {
                    title: 'Submit',
                    subTitle: 'Step 3',
                    icon: <SubmitIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-submit' />
                            </div>
                        </div>
                    ),
                    contentImage: (
                        <div className='h-[200px] w-[200px]'>
                            <CMSArticle slug='sample-manifest-submit-image' />
                        </div>
                    ),
                },
            ]}
            selectedTabIndex={props.selectedStep}
        />
    );

    const classes = ['flex flex-col shadow-lg p-4 rounded-lg bg-white gap-6', className].join(' ');
    return (
        <div className={classes}>
            <div className='text-primary text-lg'>Submission Process</div>
            <div className='flex border-b-grey-40 pb-4'>{carousel}</div>
        </div>
    );
};

export default SubmissionProcess;
