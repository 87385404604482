import React, { useEffect, useState, useMemo, ReactElement } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CompleteIcon from 'src/assets/svg/general/complete.svg?react';
import NoneIcon from 'src/assets/svg/general/none.svg?react';
import DataTable from 'src/shared/components/data-table/DataTable';
import CloseIcon from 'src/assets/svg/general/close.svg?react';
import { DataTableColumns, DataTableState, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import ExpandableSection from 'src/shared/components/expandable-section/ExpandableSection';
import Loader from 'src/shared/components/loader/Loader';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { getSamplingKitStatusData } from 'src/shared/helpers/kitHelpers';
import { FieldDataStatus, SamplingEventBatchStatus, SamplingKit, SamplingKitStatus } from 'src/shared/types';
import SamplingEventBatchStatusIndicator from 'src/shared/components/sampling-event-batch-status-indicator/SamplingEventBatchStatusIndicator';
import moment from 'moment';
import TestTypeChip from 'src/shared/components/test-type-chip/TestTypeChip';
import classNames from 'classnames';
import { getBatchNumberFromBatchId } from 'src/app/samples/samplesHelpers';
import DataControl from 'src/app/samples/sample-reception/views/view-sample-manifest/components/data-control/DataControl';
import Tooltip from 'src/shared/components/tooltip/Tooltip';
import FieldDataStatusIndicator from 'src/app/samples/sample-manifest/sample-manifest-form/views/field-data/components/field-data-main-content/components/field-data-status-indicator/FieldDataStatusIndicator';
import PopOver from 'src/shared/components/pop-over/PopOver';
import { SecondaryButton } from 'src/shared/components/button/Button';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import { getCollectionMethodOptions } from 'src/app/samples/sample-manifest/sample-manifest-form/views/field-data/components/field-data-aside-content/components/field-data-form/fieldDataFormHelpers';
import useBatchDownload from 'src/app/samples/hooks/useBatchDownload';
import useSampleManifestFieldDataAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestFieldDataAnalytics';
import ProcessingControl from './components/processing-control/ProcessingControl';
import styles from './SamplingEventBatch.module.scss';

const defaultColumns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: '',
        isUnique: true,
    },
    {
        columnId: 'sampleName',
        title: 'Sample Name',
    },
    {
        columnId: 'kitBarcode',
        title: 'Kit barcode',
    },
    {
        columnId: 'sampler',
        title: 'Sampler',
    },
    {
        columnId: 'samplingDate',
        title: 'Sampling date',
    },
    {
        columnId: 'samplingTime',
        title: 'Sampling time',
    },
    {
        columnId: 'latitude',
        title: 'Latitude',
    },
    {
        columnId: 'longitude',
        title: 'Longitude',
    },
    {
        columnId: 'habitat',
        title: 'Habitat',
    },
    {
        columnId: 'volumeFiltered',
        title: 'Volume filtered (mL)',
    },
    {
        columnId: 'combinedVolumeFiltered',
        title: (
            <span>
                Combined <br /> Volume filtered (mL)
            </span>
        ),
    },
    {
        columnId: 'collectionMethod',
        title: 'Collection method',
    },
    {
        columnId: 'tests',
        title: 'Test(s)',
    },
    {
        columnId: 'notes',
        title: 'Field notes',
    },
];

type SamplingEventBatchProps = {
    batchId: string;
    columns?: DataTableColumns;
    showDataControl?: boolean;
    isExpanded?: boolean;
    showBatchInfo?: boolean;
    showPendingStatus?: boolean;
    showAmendControl?: boolean;
    showDownloadControl?: boolean;
    isViewOnly?: boolean;
    selectedRowIds?: Set<string | number>;
    handleRowClick?: (row: TableRowData) => void;
};

const SamplingEventBatch = (props: SamplingEventBatchProps) => {
    const [selectedKit, setSelectedKit] = useState<string | null>(null);
    const {
        batchId,
        columns = defaultColumns,
        showDataControl = true,
        isExpanded = true,
        showBatchInfo = true,
        showPendingStatus = false,
        showAmendControl = false,
        showDownloadControl = false,
        isViewOnly = false,
        selectedRowIds,
        handleRowClick,
    } = props;
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: Number.POSITIVE_INFINITY,
        hiddenColumnIds: new Set(['sampleId']),
    });
    const { trackSampleManifestAddDataExpandChevronClick } = useSampleManifestFieldDataAnalytics();

    useEffect(() => {
        if (selectedRowIds) {
            setTableState({ ...tableState, selectedRowIds });
        }
    }, [selectedRowIds]);

    const { downloadBatchCsv } = useBatchDownload();
    const { getKitsByBatchId, getBatchById, samplingEvent, isBatchOnlySubmit, saveSamplingKit } = useSamplingEvent();
    const [totalCompleteSamples, setTotalCompleteSamples] = useState(0);
    const currentBatchKits = getKitsByBatchId(batchId);
    const currentBatch = getBatchById(batchId);
    const approvedBatchByLab = currentBatch?.status === SamplingEventBatchStatus.APPROVED_BY_LAB;
    const requiredCustomerAmendment = currentBatch?.status === SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT;
    const isReadOnly = !isBatchOnlySubmit && !showAmendControl && !isViewOnly;
    const tableColumns = useMemo(() => {
        if (showAmendControl) {
            return [
                {
                    columnId: 'amend',
                    title: 'Proceed for \n processing ',
                    renderer: ({ rowData }: { rowData: TableRowData }) => (
                        <ProcessingControl samplingKitId={rowData[0] as string} isDisabled={requiredCustomerAmendment} />
                    ),
                },
                ...columns,
            ];
        }
        return columns;
    }, [showAmendControl, columns, requiredCustomerAmendment]);

    const removeTestType = async ({ kit, habitatAssayKey }: { kit: SamplingKit; habitatAssayKey: string }) => {
        const newTestTypes = kit.testTypes.filter(test => test.habitatAssayKey !== habitatAssayKey);
        await saveSamplingKit({
            ...kit,
            testTypes: newTestTypes,
        });
    };

    useEffect(() => {
        if (!currentBatchKits) {
            return;
        }

        const processed: Record<string, (string | ReactElement | null)[]> = {};
        tableColumns.forEach(column => {
            processed[column.columnId] = [];
        });

        let totalCompleteSamples = 0;
        currentBatchKits.forEach(kit => {
            // Field data status can be None, Incomplete, or Complete
            const fieldDataStatus = getSamplingKitStatusData(kit).status;
            if (fieldDataStatus === FieldDataStatus.COMPLETE) {
                totalCompleteSamples++;
            }
            const showRejectedInfo =
                kit.status === SamplingKitStatus.REJECTED_BY_LAB && (requiredCustomerAmendment || approvedBatchByLab) && !showAmendControl;

            const defaultGetColumnClassNameParams = {
                kit,
                requiredCustomerAmendment,
                approvedBatchByLab,
                selectedKit,
                showAmendControl,
            };

            const columnClassName = getColumnClassNames({ ...defaultGetColumnClassNameParams });
            processed['amend']?.push(kit.id);
            processed['sampleId']?.push(kit.id);
            processed['addTests']?.push(kit.id);
            if (processed['sampleName']) {
                const sampleName = showRejectedInfo ? (
                    <div className='flex flex-row gap-1'>
                        <PopOver
                            content={<CMSArticle slug='sample-manifest-batch-reject-sample-tests-info' />}
                            icon={<ErrorIcon className='text-red-100' />}
                        />
                        {kit.name}
                    </div>
                ) : (
                    kit.name
                );
                processed['sampleName'].push(
                    wrapWithDiv(sampleName, getColumnClassNames({ ...defaultGetColumnClassNameParams, columnId: 'sampleName' }))
                );
            }

            processed['kitBarcode']?.push(wrapWithDiv(kit.barcode, columnClassName));
            processed['sampler']?.push(wrapWithDiv(kit.sampler, columnClassName));
            processed['samplingDate']?.push(wrapWithDiv(moment(kit.sampledAt).format('DD MMM YYYY'), columnClassName));
            processed['samplingTime']?.push(
                wrapWithDiv(kit.sampledTime ? moment(kit.sampledTime).format('hh:mm A') : 'Not provided', columnClassName)
            );
            processed['latitude']?.push(wrapWithDiv(kit.latitude, columnClassName));
            processed['longitude']?.push(wrapWithDiv(kit.longitude, columnClassName));
            processed['habitat']?.push(wrapWithDiv(kit.habitat, columnClassName));
            processed['volumeFiltered']?.push(wrapWithDiv(kit.volumeFiltered || 'N/A', columnClassName));
            processed['combinedVolumeFiltered']?.push(wrapWithDiv(kit.combinedVolumeFiltered || 'N/A', columnClassName));
            processed['collectionMethod']?.push(wrapWithDiv(collectionMethodLabel(kit.collectionMethod, kit.habitat), columnClassName));
            if (processed['tests']) {
                const testInfo = showRejectedInfo ? (
                    <span>test(s) removed</span>
                ) : (
                    <div className='flex gap-2'>
                        {kit.testTypes.map(test =>
                            isBatchOnlySubmit ? (
                                <div className='flex items-center bg-[#F6F6F6] rounded-md pr-2' key={test.habitatAssayKey}>
                                    <TestTypeChip testTypeKey={test.habitatAssayKey} />
                                    <CloseIcon
                                        className='cursor-pointer h-4 w-4'
                                        onClick={() =>
                                            removeTestType({
                                                kit: kit,
                                                habitatAssayKey: test.habitatAssayKey,
                                            })
                                        }
                                    />
                                </div>
                            ) : (
                                <TestTypeChip key={test.habitatAssayKey} testTypeKey={test.habitatAssayKey} />
                            )
                        )}
                    </div>
                );
                processed['tests'].push(wrapWithDiv(testInfo, columnClassName));
            }

            if (processed['notes']) {
                // Trim notes to 40 character and add ellipsis
                const truncatedNotes = kit.notes.length > 40 ? `${kit.notes.substring(0, 40)}...` : kit.notes;
                const notesTooltip = kit.notes ? <Tooltip content={kit.notes}>{truncatedNotes}</Tooltip> : <span>None provided</span>;
                processed['notes'].push(wrapWithDiv(notesTooltip, columnClassName));
            }

            if (processed['fieldData']) {
                processed['fieldData'].push(
                    wrapWithDiv(<FieldDataStatusIndicator status={fieldDataStatus as FieldDataStatus} />, columnClassName)
                );
            }

            if (processed['readyToSubmit']) {
                if (showRejectedInfo) {
                    processed['readyToSubmit'].push(wrapWithDiv(null, columnClassName));
                } else {
                    const readyToSubmitIcon =
                        kit.testTypes.length > 0 ? (
                            <CompleteIcon data-testid='complete-icon' />
                        ) : (
                            <NoneIcon className='fill-grey-80' data-testid='none-icon' />
                        );
                    processed['readyToSubmit'].push(wrapWithDiv(readyToSubmitIcon, columnClassName));
                }
            }
        });

        const data: (string | ReactElement | null)[][] = [];
        tableColumns.forEach(column => {
            processed[column.columnId].forEach((value, index) => {
                if (!data[index]) {
                    data[index] = [];
                }
                data[index].push(value);
            });
        });

        setTotalCompleteSamples(totalCompleteSamples);
        setTableState({ ...tableState, data, selectedRowIds });
    }, [samplingEvent, selectedKit, tableColumns, requiredCustomerAmendment, approvedBatchByLab, showAmendControl]);

    if (!tableState.data || !currentBatch || !samplingEvent) {
        return <Loader />;
    }

    const handleDownload = () => {
        if (!tableState.data) {
            return;
        }
        downloadBatchCsv(batchId, batchNumber, currentBatchKits, totalCompleteSamples);
    };

    const tableClassNames = classNames('flex-1 bg-white rounded-md mt-2', styles.table, {
        [styles['submitted']]: !showDataControl,
        'text-grey-60': isReadOnly,
    });

    const rowClick = (row: TableRowData) => {
        const rowId = row[0] as string;
        setSelectedKit(rowId);
        handleRowClick?.(row);
    };

    const onToggleClick = (isExpanded: boolean) => {
        // Capture GA for sample manifest add field data - expand chevron click
        if (isExpanded) {
            trackSampleManifestAddDataExpandChevronClick();
        }
    };

    const table = (
        <DataTable
            state={tableState}
            columns={tableColumns}
            emptyStateMessage={'No samples to display.'}
            className={tableClassNames}
            onRowSelect={handleRowClick ? rowClick : undefined}
        />
    );

    const dataControl = <DataControl samplingEventBatch={currentBatch} />;

    const separator = <div className='inline-block h-[20px] min-h-[1em] w-0.5 bg-grey-60'></div>;
    // Batch number is eventId + B + number
    const batchNumber = getBatchNumberFromBatchId(batchId, samplingEvent.identifier);
    const isBatchCompleted = approvedBatchByLab;
    const batchStatus =
        !showAmendControl && requiredCustomerAmendment ? SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT : currentBatch.status;
    const toggle = (
        <div className='pl-8 flex gap-4 items-center overflow-auto'>
            <div className='flex flex-col'>
                <span>Batch {batchNumber}</span>
                {showBatchInfo && <span className='text-grey-80'>Batch ID: {`${samplingEvent?.identifier}-B${batchNumber}`}</span>}
            </div>
            <div className='text-sm text-grey-80'>
                {totalCompleteSamples} complete sample{totalCompleteSamples > 1 ? 's' : ''}
            </div>

            {!showPendingStatus && currentBatch?.createdAt && (
                <>
                    {separator}
                    <div className='text-sm text-grey-80'>Submitted {moment(currentBatch.createdAt).format('DD MMM YYYY')}</div>
                </>
            )}

            {!showPendingStatus && currentBatch?.logisticsApprovalAt && (
                <>
                    {separator}
                    <div className='text-sm text-grey-80'>Accepted {moment(currentBatch.logisticsApprovalAt).format('DD MMM YYYY')}</div>
                </>
            )}

            <div>
                <SamplingEventBatchStatusIndicator status={batchStatus || SamplingEventBatchStatus.CREATED} />
            </div>
            {isReadOnly && (
                <div className='ml-auto text-teal-100'>
                    Read Only &nbsp;
                    <LockOutlinedIcon />
                </div>
            )}
            {showDownloadControl && (
                <div className='ml-auto'>
                    <SecondaryButton onClick={handleDownload} className='flex gap-2'>
                        <DownloadIcon />
                        <span>Download CSV</span>
                    </SecondaryButton>
                </div>
            )}
        </div>
    );

    const content = (
        <ExpandableSection
            isExpanded={!isBatchCompleted && isExpanded}
            classNames={{
                wrapper: 'w-full',
                toggle: 'left-[25px] top-[6px]  right-auto',
            }}
            expandedContent={
                <div className='flex flex-col flex-1'>
                    {toggle}
                    {table}
                    {showDataControl && dataControl}
                </div>
            }
            collapsedContent={toggle}
            onToggle={onToggleClick}
        />
    );

    return <div className='flex overflow-y-auto border-b border-grey-20 pb-2'>{content}</div>;
};

type GetColumnClassNamesParams = {
    kit: SamplingKit;
    columnId?: string;
    requiredCustomerAmendment: boolean;
    approvedBatchByLab: boolean;
    selectedKit: string | null;
    showAmendControl: boolean;
};

const getColumnClassNames = (params: GetColumnClassNamesParams) => {
    const { kit, columnId, requiredCustomerAmendment, approvedBatchByLab, selectedKit, showAmendControl } = params;

    if (showAmendControl) {
        const isFlagged = kit.status === SamplingKitStatus.FLAGGED || kit.status === SamplingKitStatus.REJECTED_BY_LAB;
        return classNames({
            [styles['kit-approved']]: kit.status === SamplingKitStatus.APPROVED_BY_LAB,
            [styles['kit-flagged']]: isFlagged,
            [styles['kit-approved-name']]: columnId === 'sampleName' && kit.status === SamplingKitStatus.APPROVED_BY_LAB,
            [styles['kit-flagged-name']]: columnId === 'sampleName' && isFlagged,
        });
    }

    if (kit.status === SamplingKitStatus.REJECTED_BY_LAB && (requiredCustomerAmendment || approvedBatchByLab)) {
        return classNames(styles['kit-flagged'], {
            [styles['kit-flagged-name']]: columnId === 'sampleName',
            [styles['kit-flagged-selected']]: kit.id === selectedKit,
        });
    }

    return '';
};

const wrapWithDiv = (content: React.ReactNode, className: string) => <div className={className}>{content}</div>;

const collectionMethodLabel = (collectionMethod: string, habitat: string) => {
    const collectionMethodOptions = getCollectionMethodOptions(habitat);
    const foundOption = collectionMethodOptions.find(option => option.label === collectionMethod);
    return collectionMethod ? (!foundOption ? `Other (${collectionMethod})` : collectionMethod) : 'N/A';
};

export default SamplingEventBatch;
