import React from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import CommunityComparisonImage from 'src/assets/images/insights/freemium/community-comparison.webp';
import EcologicalTrendsImage from 'src/assets/images/insights/freemium/ecological-trends.webp';
import SampleComparisonImage from 'src/assets/images/insights/freemium/sample-comparison.webp';
import StatisticalComparisonImage from 'src/assets/images/insights/freemium/statistical-comparison.webp';
import TaxonomicCompositionImage from 'src/assets/images/insights/freemium/taxonomic-composition.webp';

import FreemiumCard from 'src/shared/templates/freemium-card/FreemiumCard';
import { AppViews } from 'src/routes/routesConfig';
import UpgradeSubscriptionButton from 'src/shared/components/upgrade-subscription-button/UpgradeSubscriptionButton';
import useFreemiumInsightsAnalytics from 'src/app/insights/views/freemium-insights/hooks/useFreemiumInsightsAnalytics';

const FreemiumInsights = () => {
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb('Ecostats');
    const { trackUpgradeSubscriptionClick } = useFreemiumInsightsAnalytics();

    const mainContent = (
        <div className='flex flex-col gap-8 pb-4'>
            <div className='flex flex-col gap-4 p-4 rounded-lg bg-white shadow-lg'>
                <div>Compare your metrics over time, sample groups, and per sample. Plus view the taxonomic breakdown of your species</div>
                <UpgradeSubscriptionButton onClick={trackUpgradeSubscriptionClick} />
            </div>

            <div className='flex flex-col gap-2'>
                <div className='flex flex-wrap gap-8'>
                    <FreemiumCard
                        title='Species trends'
                        description={<div>View your sample group data over time</div>}
                        image={<img src={EcologicalTrendsImage} alt='Species trends' />}
                    />

                    <FreemiumCard
                        title='Sample comparison'
                        description={<div>Compare metrics across each of your samples</div>}
                        image={<img src={SampleComparisonImage} alt='Sample comparison' />}
                    />

                    <FreemiumCard
                        title='Statistical comparison'
                        description='Look for statistical significant differences between sample groups'
                        image={<img src={StatisticalComparisonImage} alt='Statistical comparison' />}
                    />

                    <FreemiumCard
                        title='Community comparison'
                        description='See how similar your sample groups are to one another, based on the species detected. Select different sampling events to view changes over time'
                        image={<img src={CommunityComparisonImage} alt='Community comparison' />}
                    />

                    <FreemiumCard
                        title='Taxonomic composition'
                        description='Understand the taxonomic relationships between species. Toggle between sampling events to compare over time'
                        image={<img src={TaxonomicCompositionImage} alt='Taxonomic composition' />}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            breadcrumbs={currentProjectBreadcrumbDetails}
            title='Ecostats'
            page={AppViews.INSIGHTS}
        />
    );
};

export default FreemiumInsights;
