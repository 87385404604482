import { combineReducers } from 'redux';

import projects from 'src/app/projects/state/projectsReducer';
import surveys from 'src/app/surveys/state/surveyReducer';
import customers from 'src/app/customers/state/customersSlice';
import dashboard from 'src/app/dashboard/state/dashboardReducer';
import speciesMap from 'src/app/maps/views/species-map/state/speciesMapSlice';
import habitatMap from 'src/app/maps/views/habitat-map/state/habitatMapSlice';
import tables from 'src/app/tables/state/tablesReducer';
import insights from 'src/app/insights/state/insightsReducer';
import admin from 'src/app/admin/state/adminReducer';
import global from 'src/global/state/globalReducer';
import routes from 'src/routes/state/routesSlice';
import samples from 'src/app/samples/samplesReducer';

import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

const createRootReducer = () =>
    combineReducers({
        projects,
        surveys,
        samples,
        customers,
        dashboard,
        speciesMap,
        tables,
        insights,
        admin,
        global,
        habitatMap,
        routes,
        [graphApiSlice.reducerPath]: graphApiSlice.reducer,
    });

export default createRootReducer;
