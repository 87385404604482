import React from 'react';

type ShowProps<T> = {
    when: T | undefined | null | false;
    fallback?: React.ReactNode;
    children: React.ReactNode | ((item: T) => React.ReactNode);
};

/**
 * A functional component that conditionally renders its children based on the `when` prop.
 *
 * @template T - The type of the `when` prop.
 *
 * @param {ShowProps<T>} props - The props for the Show component.
 * @param {T} props.when - The condition to determine whether to render the children or the fallback.
 * @param {React.ReactNode} [props.fallback=null] - The fallback content to render when `when` is falsy.
 * @param {React.ReactNode | ((when: T) => React.ReactNode)} props.children - The content to render when `when` is truthy. If a function is provided, it will be called with the `when` value.
 *
 * @returns {React.ReactNode} The rendered content based on the `when` prop.
 */
const Show = <T,>({ when, fallback = null, children }: ShowProps<T>): React.ReactElement | null => {
    if (!when) {
        return fallback as React.ReactElement | null;
    }

    return typeof children === 'function' ? (children(when) as React.ReactElement) : (children as React.ReactElement);
};

export default Show;
