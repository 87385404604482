import React, { useState } from 'react';
import { PrimaryButton } from 'src/shared/components/button/Button';
import { FilesProps } from 'src/app/projects/views/project-list/components/project-list-item/ProjectListItem';
import { useEnablePdfDownloadMutation } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import SuccessMessage from 'src/shared/components/success-message/SuccessMessage';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';

interface EnablePdfDownloadButtonProps {
    projectId: string;
    pdfEventFiles: FilesProps[];
}

const EnablePdfDownload = ({ pdfEventFiles, projectId }: EnablePdfDownloadButtonProps) => {
    const [enablePdfDownload] = useEnablePdfDownloadMutation();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleOnclick = async (file: FilesProps) => {
        try {
            const response = await enablePdfDownload({
                eventName: file.name,
                projectId,
            });
            const shouldEnablePdfDownload = response.data;
            if (shouldEnablePdfDownload) {
                setSuccessMessage(`PDF download enabled successfully for ${file.name} ${projectId}`);
                setErrorMessage(null);
            } else {
                setErrorMessage(`Failed to enable PDF download for ${file.name} ${projectId}`);
            }
        } catch (error) {
            console.error('rejected', error);
        }
    };
    return (
        <div>
            {successMessage && <SuccessMessage message={successMessage} />}
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {pdfEventFiles.length > 0 ? (
                pdfEventFiles.map((file, index) => (
                    <div key={index} className='flex items-center space-x-4 mb-2'>
                        <span>{file.name}</span>
                        <PrimaryButton onClick={() => handleOnclick(file)} className='text-md'>
                            Enable PDF Download
                        </PrimaryButton>
                    </div>
                ))
            ) : (
                <p>No PDF files available.</p>
            )}
        </div>
    );
};

export default EnablePdfDownload;
