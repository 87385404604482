import React from 'react';
import { AppName } from 'src/global/globalConfig';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import SpeciesOfInterestImage from 'src/assets/images/dashboard/freemium/species-of-interest.webp';
import SpeciesHighlightImage from 'src/assets/images/dashboard/freemium/species-highlight.webp';
import MetricsImage from 'src/assets/images/dashboard/freemium/metrics.webp';
import FreemiumCard from 'src/shared/templates/freemium-card/FreemiumCard';
import UpgradeSubscriptionButton from 'src/shared/components/upgrade-subscription-button/UpgradeSubscriptionButton';
import useDashboardAnalytics from 'src/app/dashboard/hooks/useDashboardAnalytics';

const FreemiumDashboard = () => {
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb('Nature Performance Dashboard');
    const { trackUpgradeSubscriptionClick } = useDashboardAnalytics();

    const mainContent = (
        <div className='flex flex-col gap-8'>
            <div className='flex flex-col gap-4 p-4 rounded-lg bg-white shadow-lg'>
                <div>View key trends and indicators for your project</div>
                <UpgradeSubscriptionButton onClick={trackUpgradeSubscriptionClick} />
            </div>

            <div className='flex flex-col gap-2'>
                <div className='flex flex-wrap gap-6'>
                    <FreemiumCard
                        title='Key metrics and trends'
                        description='View your key biodiversity metrics over time'
                        image={<img src={MetricsImage} alt='Metrics' />}
                        footer={
                            <div className='text-grey-80'>
                                Metrics include:
                                <ul className='list-disc list-inside '>
                                    <li className='pl-4'>Species richness</li>
                                    <li className='pl-4'>Evolutionary diversity</li>
                                    <li className='pl-4'>Fungal functional diversity</li>
                                    <li className='pl-4'>Bacterial functional diversity</li>
                                    <li className='pl-4'>Marine water fish metrics</li>
                                </ul>
                            </div>
                        }
                    />

                    <FreemiumCard
                        title='Species of interest'
                        description='View IUCN Red List and invasive species found in your project'
                        image={<img src={SpeciesOfInterestImage} alt='Species of interest' />}
                    />

                    <FreemiumCard
                        title='Species highlight'
                        description='Information about a notable species detected in your project'
                        image={<img src={SpeciesHighlightImage} alt='Species of interest' />}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={'Nature Performance Dashboard'}
            page={AppName.DASHBOARD}
        />
    );
};

export default FreemiumDashboard;
