import { gql } from 'graphql-request';
import { Project } from 'src/shared/types';

type SpeciesMetricsData = Pick<Project, 'projectId' | 'projectName' | 'projectStartDate' | 'latestReportingDate' | 'taxonomySummary'>;

export const speciesMetricsQuery = gql`
    query get_project_data($projectId: String!, $samples: sampleFilter) {
        speciesMetrics: project(projectId: $projectId, samples: $samples) {
            projectId
            projectName
            taxonomySummary {
                percentTotalSamples
                sampleCount
                conservationStatus {
                    iucnRedListStatus
                    onGRIISList
                    chegdCommonName
                    chegdGroup
                }
                taxonomy {
                    tax_species
                    common_name
                }
            }
            projectStartDate
            latestReportingDate
        }
    }
`;

export type SpeciesMetricsQueryResult = {
    speciesMetrics: SpeciesMetricsData;
};
