import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Legend, Line, Area, Label } from 'recharts';
import { TimeSeriesChartData, LineStyleType, TickFormat } from 'src/shared/types';
import mapValues from 'lodash/mapValues';
import CustomLegend, { LegendDataProps } from '../../custom-legend/CustomLegend';
import { getAxisTicks } from '../chartHelpers';

interface TimeSeriesReChartProps {
    chartData: TimeSeriesChartData[];
    legendData?: LegendDataProps[];
    legendTitle?: string;
    tickFormat?: TickFormat;
    ticksList?: string[];
    yAxisDomain?: number[];
    yAxisLabel?: string;
    onLegendClick?: (legendValue: string) => void;
}

const axisTickStyle = {
    fill: '#1E1E1E',
};

const TimeSeriesReChart = (props: TimeSeriesReChartProps) => {
    const { chartData, legendData, legendTitle, tickFormat, ticksList, yAxisDomain, yAxisLabel } = props;
    const [hoverProps, setHoverProps] = useState<{ [key: string]: boolean | string | null }>({});
    useEffect(() => {
        chartData.forEach(item => {
            if (item.label === 'all') {
                item.label = 'Combined average';
            }
            hoverProps[item.type] = false;
            if (item.groupType) {
                hoverProps[item.groupType] = false;
            }
            hoverProps['hideByGroupType'] = false;
        });
        setHoverProps({ ...hoverProps });
    }, [chartData]);

    const getLineStroke = (lineData: TimeSeriesChartData) => {
        const isHoverActive = hoverProps.hover === lineData.type;
        return isHoverActive || !hoverProps.hover ? lineData.color : '#979797';
    };

    const getLineStrokeOpacity = (lineData: TimeSeriesChartData) => {
        const isHoverActive = hoverProps.hover === lineData.type;
        return Number(isHoverActive || !hoverProps.hover ? 1 : 0.6);
    };

    const isLineHidden = (lineData: TimeSeriesChartData) => {
        const hoverType = hoverProps.hideByGroupType && lineData.groupType ? lineData.groupType : lineData.type;
        return hoverProps[hoverType] === true;
    };

    const renderTickFormatter = (dateTime: number) => {
        return moment(dateTime).format(tickFormat || TickFormat.MONTH_AND_YEAR);
    };

    const handleLegendMouseEnter = (e: { value: string }) => {
        if (!hoverProps[e.value]) {
            setHoverProps({ ...hoverProps, hover: e.value });
        }
    };

    const handleLegendMouseLeave = () => {
        setHoverProps({ ...hoverProps, hover: null });
    };

    const selectLine = (e: { value: string; hideByGroupType: boolean }) => {
        setHoverProps({
            ...hoverProps,
            [e.value]: !hoverProps[e.value],
            hover: null,
            hideByGroupType: e.hideByGroupType,
        });

        props.onLegendClick?.(e.value);
    };

    const resetSelected = () => {
        setHoverProps({ ...mapValues(hoverProps, () => false), hover: null });
    };

    const dates = {
        startDate: chartData[0]?.data[0]?.date,
        endDate: chartData[0]?.data[chartData[0]?.data?.length - 1]?.date,
    };
    const xAxisTicks = ticksList || getAxisTicks(dates) || [];

    return (
        <ResponsiveContainer width='90%' height={500}>
            <ComposedChart
                width={500}
                height={300}
                margin={{
                    top: 40,
                    right: 10,
                    left: 20,
                    bottom: 60,
                }}
            >
                <XAxis
                    dataKey='date'
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={ticksList ? undefined : renderTickFormatter}
                    tick={axisTickStyle}
                    type='number'
                    domain={['dataMin', 'dataMax']}
                    dy={15}
                    ticks={xAxisTicks}
                    allowDuplicatedCategory={false}
                />
                <YAxis
                    dataKey='value'
                    tickLine={false}
                    axisLine={false}
                    scale='auto'
                    tick={axisTickStyle}
                    dx={-10}
                    domain={yAxisDomain ? yAxisDomain : ['auto', 'auto']}
                >
                    ${yAxisLabel ? <Label value={yAxisLabel} position='insideLeft' angle={-90} dx={-20} /> : ''}
                </YAxis>
                <Tooltip labelFormatter={ticksList ? undefined : renderTickFormatter} />
                <Legend
                    align='right'
                    verticalAlign='top'
                    layout='vertical'
                    wrapperStyle={{ marginRight: -72 }}
                    content={
                        <CustomLegend
                            title={legendTitle || 'Sample Groups'}
                            onClickHandler={selectLine}
                            hoverProps={hoverProps}
                            legendData={legendData ? legendData : chartData}
                            onMouseOverHandler={handleLegendMouseEnter}
                            onMouseOutHandler={handleLegendMouseLeave}
                            resetHanlder={resetSelected}
                        />
                    }
                />

                <CartesianGrid vertical={false} />
                {chartData.map((s: TimeSeriesChartData, i: number) =>
                    s.styleType === LineStyleType.AREA ? (
                        <Area
                            type='linear'
                            dataKey='value'
                            stackId='1'
                            stroke='none'
                            hide={hoverProps[s.type] === true}
                            fill={hoverProps.hover === s.type || !hoverProps.hover ? s.color : '#979797'}
                            fillOpacity={Number(hoverProps.hover === s.type || !hoverProps.hover ? 0.3 : 0.2)}
                            data={s.data}
                            label={false}
                            tooltipType='none'
                            dot={false}
                            activeDot={false}
                            key={`${s.type}${i}-area`}
                        />
                    ) : (
                        <Line
                            dataKey='value'
                            data={s.data}
                            name={s.label}
                            strokeWidth={2}
                            hide={isLineHidden(s)}
                            stroke={getLineStroke(s)}
                            strokeOpacity={getLineStrokeOpacity(s)}
                            dot={false}
                            strokeDasharray={s.styleType === LineStyleType.DASHED ? '4 2' : ''}
                            key={`${s.type}${i}-line`}
                        />
                    )
                )}
            </ComposedChart>
        </ResponsiveContainer>
    );
};
export default TimeSeriesReChart;
