import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { useAppSelector } from 'src/store';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import Select from 'src/shared/components/select/Select';
import PrintIcon from 'src/assets/svg/general/print.svg?react';
import { SamplesViews } from 'src/routes/routesConfig';
import { getBatchNumberFromBatchId } from 'src/app/samples/samplesHelpers';
import useSampleManifestAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestAnalytics';
import { getCurrentSampleManifestState } from '../../state/sampleManifestSelector';

type PrintBatchButtonProps = {
    batchId?: string;
    isInSubmitPopup?: boolean;
};

const PrintBatchButton = ({ batchId, isInSubmitPopup }: PrintBatchButtonProps) => {
    const [searchParams] = useSearchParams();
    const navigation = useAppNavigation();
    const { selectedSamplingEventId } = useAppSelector(getCurrentSampleManifestState);
    const { submittedBatches } = useSamplingEvent({
        samplingEventId: selectedSamplingEventId,
    });

    const [selectedBatch, setSelectedBatch] = useState(batchId || '');

    const { trackSampleManifestPrint } = useSampleManifestAnalytics();

    const batchOptions = sortBy(
        submittedBatches?.map(batch => ({
            value: batch.id,
            label: `Batch ${getBatchNumberFromBatchId(batch.id, selectedSamplingEventId)}`,
        })),
        'label'
    );

    useEffect(() => {
        if (batchOptions.length > 0 && !selectedBatch) {
            setSelectedBatch(batchOptions[0].value);
        }
    }, [batchOptions]);

    const handleSelectChange = (value: string) => {
        setSelectedBatch(value);
    };

    if (!batchId && batchOptions.length === 0) {
        return null;
    }

    const onPrintClick = () => {
        // Capture GA for sample manifest - amend sampling event batch
        trackSampleManifestPrint({ page: isInSubmitPopup ? 'submit_popup' : 'home' });

        const projectId = searchParams.get('projectId') || '';
        const customerId = searchParams.get('customerId') || '';
        const samplingEventId = searchParams.get('samplingEventId') || selectedSamplingEventId;
        const pagePath = navigation.getPath(SamplesViews.SAMPLE_BATCH_PRINT, {
            projectId: projectId,
            customerId: customerId,
            samplingEventId: samplingEventId,
            batchId: selectedBatch.replace(/-/g, ''),
        });
        const url = window.location.protocol + '//' + window.location.host + pagePath;
        window.open(url, '_blank');
    };

    const selectBoxContent = (
        <div className='flex gap-2'>
            {
                <div className='flex flex-col text-[#076769]'>
                    <div>Select batch to print</div>
                    <Select options={batchOptions} selectedValue={selectedBatch} onChange={handleSelectChange} width='160px' />
                </div>
            }
            <SecondaryButton onClick={onPrintClick} className='flex gap-2 self-end'>
                Print
                <PrintIcon className='fill-primary' />
            </SecondaryButton>
        </div>
    );

    const printButtonContent = (
        <PrimaryButton onClick={onPrintClick} className='flex gap-2 w-[240px]'>
            <PrintIcon className='fill-white' /> Print
        </PrimaryButton>
    );

    return <>{isInSubmitPopup ? printButtonContent : selectBoxContent}</>;
};

export default PrintBatchButton;
