import React, { ReactNode } from 'react';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import WarningMessage from 'src/shared/components/warning-message/WarningMessage';

type IndividualFieldProps = {
    label: ReactNode;
    fieldElement: ReactNode;
    helpSlug?: string;
    message?: ReactNode;
    errors?: string | ReactNode[];
    warnings?: string[];
};

const IndividualField = (props: IndividualFieldProps) => {
    const { label, fieldElement, helpSlug, message, errors, warnings } = props;

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex gap-2'>
                <div className='flex text-sm text-primary w-[105px] items-center'>{label}</div>
                <div className='flex flex-col flex-1 gap-2'>{fieldElement}</div>
                <div className='flex items-start pt-1 w-[24px]'>{helpSlug && <QuestionButtonHelp slug={helpSlug} type='api' />}</div>
            </div>

            <div className='flex gap-2'>
                <div className='flex text-sm text-primary w-[105px] pt-3'></div>
                <div className='flex flex-col flex-1 gap-2'>{message && <div>{message}</div>}</div>
                <div className='flex items-start pt-1 w-[24px]'></div>
            </div>

            {errors && errors.length > 0 && <ErrorMessage message={errors[0]} />}
            {warnings && warnings.length > 0 && <WarningMessage message={warnings[0]} />}
        </div>
    );
};

export default IndividualField;
