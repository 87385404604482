import { useEffect } from 'react';

export default function useOutsideClick<T extends HTMLElement | null>(
    refOrRefs: React.RefObject<T> | React.RefObject<T>[],
    callback: () => void
) {
    const refs = Array.isArray(refOrRefs) ? refOrRefs : [refOrRefs];

    const handleClickOutside = (event: Event) => {
        let isClickInside = false;

        for (const ref of refs) {
            if (ref.current?.contains(event.target as HTMLElement)) {
                isClickInside = true;
            }
        }

        if (!isClickInside) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, { capture: true });

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
}
