import React from 'react';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
const SamplesCountInfo = () => {
    const { submittedKits, unsubmittedKits } = useSamplingEvent();

    return (
        <span className='text-grey-80'>
            {submittedKits.length} Submitted samples | &nbsp; {unsubmittedKits.length} Remaining samples
        </span>
    );
};

export default SamplesCountInfo;
