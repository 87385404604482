import { createApi } from '@reduxjs/toolkit/query/react';
import { request, ClientError } from 'graphql-request';

import config from 'src/config';
import authService from 'src/services/auth-service';
import { JSONValue } from 'src/shared/types';

const graphApiBaseQuery =
    ({ baseUrl }: { baseUrl: string }) =>
    async ({ body, variables = {} }: { body: string; variables?: JSONValue }) => {
        try {
            const headers = {
                authorization: `${authService.getJWTtoken?.()}`,
            };

            const result = await request(baseUrl, body, variables, headers);
            return { data: result };
        } catch (error) {
            if (error instanceof ClientError) {
                console.error('GraphQL error', error);

                return {
                    error: {
                        status: error.response.status,
                        data: error.response.data,
                        response: {
                            errors: error.response.errors,
                        },
                        message: error.message,
                    },
                };
            }
            return {
                error: {
                    status: 500,
                    meta: { error: (error as Error).message },
                },
            };
        }
    };

export default createApi({
    reducerPath: 'graphApi',
    baseQuery: graphApiBaseQuery({
        baseUrl: config.graphApiUrl,
    }),
    endpoints: () => ({}), // will inject endpoints in other files
    tagTypes: ['customer', 'project', 'user', 'users', 'customerAdmin', 'sampling-event'],
});
