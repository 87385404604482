import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useSampleManifestFieldDataAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: Omit<AnalyticsOptions, 'category'>) => {
        analyticsTracking.trackEvent({
            ...ga,
            category: 'sample_manifest',
            app: AppName.SAMPLE_MANIFEST,
            project: ga.project || currentProjectDetails?.projectName,
        });
    };

    const trackSampleManifestAddDataRowClick = () => {
        sendTrackEvent({
            action: 'sample_manifest_add_field_data_sample_row_click',
        });
    };

    const trackSampleManifestAddDataSaveClickComplete = () => {
        sendTrackEvent({
            action: 'sample_manifest_add_field_data_save_click_complete',
        });
    };

    const trackSampleManifestAddDataSaveClickIncomplete = (props: { fields: string }) => {
        sendTrackEvent({
            action: 'sample_manifest_add_field_data_save_click_incomplete',
            label: props.fields,
        });
    };

    const trackSampleManifestAddDataNoNmKitFoundError = () => {
        sendTrackEvent({
            action: 'sample_manifest_add_field_data_no_nm_kit_found_error',
        });
    };

    const trackSampleManifestAddDataExpandChevronClick = () => {
        sendTrackEvent({
            action: 'sample_manifest_add_field_data_expand_chevron_click',
        });
    };

    return {
        trackSampleManifestAddDataRowClick,
        trackSampleManifestAddDataSaveClickComplete,
        trackSampleManifestAddDataSaveClickIncomplete,
        trackSampleManifestAddDataNoNmKitFoundError,
        trackSampleManifestAddDataExpandChevronClick,
    };
};

export default useSampleManifestFieldDataAnalytics;
