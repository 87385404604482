import React from 'react';
import { useFungalToBacterialRatioMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import functionalDiversityImageUrl from 'src/assets/images/dashboard/functional_diversity.svg?url';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import TimeseriesCardTemplate from '../components/TimeseriesCardTemplate';

import styles from '../Metrics.module.scss';

const FungalToBacterialRatioMetrics = () => {
    const chartTitle = 'Fungal to Bacterial Ratio';
    const { isSingleTimepoint } = useProject();

    const {
        currentProjectSelectedDateRange,
        currentProjectId,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
    } = useProjectFilters();

    const { currentData, isFetching } = useFungalToBacterialRatioMetricsQuery(
        {
            projectId: currentProjectId || '',
            fromDate: currentProjectSelectedDateRange?.startDate,
            toDate: currentProjectSelectedDateRange?.endDate,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetching) {
        return <LinearLoader />;
    }

    if (!currentData || !currentData.fungalToBacterialRatio) {
        return null;
    }

    const content = (
        <TimeseriesCardTemplate
            title={chartTitle}
            titleHelpSlug='soil-fungal-to-bacterial-ratio'
            averageValueDescription='Ratio measured in [endYearWithQuarter]'
            metricsChartData={currentData.fungalToBacterialRatio}
            metricCardImageUrl={functionalDiversityImageUrl}
            metricCardContent='average functions per sample'
        />
    );

    if (isSingleTimepoint) {
        return content;
    }

    return <div className={styles.timeseriesCard}>{content}</div>;
};

export default FungalToBacterialRatioMetrics;
