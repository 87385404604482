import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { noop } from 'lodash';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import Loader from 'src/shared/components/loader/Loader';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { getSamplingKitStatusData } from 'src/shared/helpers/kitHelpers';
import { FieldDataStatus } from 'src/shared/types';
import moment from 'moment';
import classNames from 'classnames';
import { getBatchNumberFromBatchId } from 'src/app/samples/samplesHelpers';
import useSamplingCadence from 'src/app/samples/hooks/useSamplingCadence';
import NMLogo from 'src/assets/images/naturemetrics_logo.png';
import styles from './SampleBatchPrint.module.scss';

const columns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: '',
        isUnique: true,
    },
    {
        columnId: 'sampleName',
        title: 'Sample Name',
        width: '12%',
    },
    {
        columnId: 'kitBarcode',
        title: 'Kit barcode',
        width: '10%',
    },
    {
        columnId: 'samplingDate',
        title: 'Sampling date',
        width: '10%',
    },
    {
        columnId: 'habitat',
        title: 'Habitat',
        width: '10%',
    },
    {
        columnId: 'tests',
        title: 'Test(s)',
        width: '25%',
    },
    {
        columnId: 'volumeFiltered',
        title: 'Volume filtered (mL)',
        width: '6%',
    },
    {
        columnId: 'collectionMethod',
        title: 'Collection method',
        width: '10%',
    },
    {
        columnId: 'notes',
        title: 'Field notes',
        width: '17%',
    },
];

const SampleBatchPrint = () => {
    const [searchParams] = useSearchParams();
    const batchId = searchParams.get('batchId') || '';
    const isPrintLayout = Boolean(searchParams.get('printLayout'));

    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: Number.POSITIVE_INFINITY,
        hiddenColumnIds: new Set(['sampleId']),
    });
    const { customerData } = useSamplingCadence();
    const { getKitsByBatchId, getBatchById, samplingEvent, projectSummary } = useSamplingEvent();
    const [totalCompleteSamples, setTotalCompleteSamples] = useState(0);
    const currentBatchKits = getKitsByBatchId(batchId);
    const currentBatch = getBatchById(batchId);

    useEffect(() => {
        if (!isPrintLayout) {
            return;
        }

        setTimeout(() => {
            window.print();
            window.close();
        }, 2000);
    }, [isPrintLayout]);

    useEffect(() => {
        if (!currentBatchKits) {
            return;
        }
        let totalCompleteSamples = 0;
        const data = currentBatchKits.map(kit => {
            // Field data status can be None, Incomplete, or Complete
            const fieldDataStatus = getSamplingKitStatusData(kit).status;
            if (fieldDataStatus === FieldDataStatus.COMPLETE) {
                totalCompleteSamples++;
            }

            return columns.map(column => {
                switch (column.columnId) {
                    case 'sampleId':
                        return kit.id;
                    case 'sampleName':
                        return kit.name;
                    case 'kitBarcode':
                        return kit.barcode;
                    case 'sampler':
                        return kit.sampler;
                    case 'samplingDate':
                        return moment(kit.sampledAt).format('DD MMM YYYY');
                    case 'samplingTime':
                        return kit.sampledTime ? moment(kit.sampledTime).format('hh:mm A') : 'Not provided';
                    case 'latitude':
                        return kit.latitude;
                    case 'longitude':
                        return kit.longitude;
                    case 'habitat':
                        return kit.habitat;
                    case 'volumeFiltered':
                        return kit.volumeFiltered || 'N/A';
                    case 'tests':
                        return kit.testTypes.map(test => `${test.habitatName} ${test.assayName}`).join(', ');
                    case 'notes':
                        return kit.notes || 'Not provided';
                    case 'fieldData':
                        return fieldDataStatus;
                    case 'collectionMethod':
                        return kit.collectionMethod || 'N/A';
                    default:
                        return null;
                }
            });
        });
        setTotalCompleteSamples(totalCompleteSamples);
        setTableState({ ...tableState, data });
    }, [currentBatchKits?.length]);

    if (!batchId || !samplingEvent) {
        return null;
    }

    if (!tableState.data || !currentBatch || !samplingEvent) {
        return <Loader />;
    }

    const tableClassNames = classNames('flex-1 bg-white rounded-md mt-2', styles.table);

    // Batch number is eventId + B + number
    const batchNumber = getBatchNumberFromBatchId(batchId, samplingEvent.identifier);

    const content = (
        <div className='flex flex-col flex-1 gap-2'>
            <div>
                <img src={NMLogo} alt='Nature Metrics' className='h-20' />
            </div>
            <div className='text-xl text-center font-bold'>Sample Manifest Batch</div>
            <div className='flex gap-5'>
                <div>
                    <strong>Customer code:</strong> {projectSummary?.customerId}
                </div>
                <div>
                    <strong>Customer name:</strong> {customerData?.customerName}
                </div>
            </div>
            <div className='flex gap-5'>
                <div>
                    <strong>Project code:</strong> {projectSummary?.projectCode}
                </div>
                <div>
                    <strong>Project name:</strong> {projectSummary?.projectName}
                </div>
            </div>
            <div className='flex gap-5'>
                <div>
                    <strong>Batch ID:</strong> {`${samplingEvent?.identifier}-B${batchNumber}`}
                </div>
                <div>
                    <strong>Submission Date:</strong> {moment(currentBatch.createdAt).format('DD MMM YYYY')}
                </div>
                <div>
                    <strong> Number of Samples:</strong> {totalCompleteSamples}
                </div>
            </div>
            <DataTable
                state={tableState}
                columns={columns}
                emptyStateMessage={'No samples to display.'}
                className={tableClassNames}
                onRowSelect={noop}
            />
        </div>
    );

    return <div className='flex overflow-y-auto mt-10'>{content}</div>;
};

export default SampleBatchPrint;
