export enum Resources {
    // Login
    LOGIN = 'login',

    // Admin
    ADMIN = 'admin',
    ADMIN_USER_MANAGEMENT = 'admin_user_management',

    // Customers
    CUSTOMERS = 'customers',

    // Projects
    PROJECTS = 'projects',
    PROJECT_DEFINITION = 'project_definition',
    PROJECTS_LIST = 'projects_list',
    NEW_PROJECT_DEFINITION = 'new_project_definition',
    PROJECT_ADMIN = 'project_admin',

    // Map resources
    MAPS = 'maps',
    SPECIES_INSIGHTS_MAP = 'species_insights_map',
    HABITAT_INSIGHTS_MAP = 'habitat_insights_map',

    // Dashboard
    DASHBOARD = 'dashboard',

    // Other
    USER = 'user',
    HABITAT_INSIGHTS_PAGE = 'hlca',

    // Survey
    SURVEY_DESIGN = 'survey_design',
    SURVEY_ADMIN = 'survey_admin',
    NEW_SURVEY_DESIGN = 'new_survey_design',
    SURVEY_DESIGN_SUMMARY = 'survey_design_summary',
    HABITAT_INSIGHTS_UPLOAD = 'habitat_insights_upload',

    // Insights
    ECO_STATS = 'eco_stats',
    COMMUNITY_COMPARISON = 'community_comparison',
    SPECIES_TRENDS = 'species_trends',
    HABITAT_TRENDS = 'habitat_trends',
    SAMPLE_COMPARISON = 'sample_comparison',
    STATISTICAL_COMPARISON = 'statistical_comparison',
    TAXONOMIC_COMPARISON = 'taxonomic_comparison',

    // Tables
    TABLES = 'tables',
    SAMPLES_TABLE = 'samples_table',
    HABITAT_INSIGHTS_TABLE = 'habitat_insights_table',

    // Samples
    SAMPLES = 'samples',
    SAMPLE_BATCH_PRINT = 'sample_batch_print',

    // Sample Manifest
    SAMPLE_MANIFEST = 'sample_manifest',
    SAMPLE_MANIFEST_START_PAGE = 'sample_manifest_start_page',
    SAMPLE_MANIFEST_FORM = 'sample_manifest_form',
    SAMPLE_MANIFEST_ADD_DATA = 'sample_manifest_add_data',
    SAMPLE_MANIFEST_ADD_TESTS = 'sample_manifest_add_tests',
    SAMPLE_MANIFEST_SUBMIT = 'sample_manifest_submit',
    SAMPLE_MANIFEST_VIEW = 'sample_manifest_view',

    // Sample Reception
    SAMPLE_RECEPTION = 'sample_reception',
    SAMPLE_RECEPTION_HOME = 'sample_reception_home',
    SAMPLE_RECEPTION_VIEW_SAMPLE_MANIFEST = 'sample_reception_view_sample_manifest',

    // Upgrade Subscription
    UPGRADE_SUBSCRIPTION = 'upgrade_subscription',
}
