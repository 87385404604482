import { combineReducers } from 'redux';

import statisticalComparisonSlice from './statistical-comparison/statisticalComparisonSlice';
import taxonomicCompositionSlice from './taxonomic-composition/taxonomicCompositionSlice';
import speciesTrendsSlice from './species-trends/speciesTrendsSlice';
import insightsGraphSlice from './api/insightsGraphSlice';
import communityComparisonSlice from './community-comparison/communityComparisonSlice';
import sampleComparisonSlice from './sample-comparison/sampleComparisonSlice';
import habitatTrendsSlice from './habitat-trends/habitatTrendsSlice';

export default combineReducers({
    statisticalComparison: statisticalComparisonSlice,
    taxonomicComposition: taxonomicCompositionSlice,
    insightsGraph: insightsGraphSlice,
    speciesTrends: speciesTrendsSlice,
    communityComparison: communityComparisonSlice,
    sampleComparison: sampleComparisonSlice,
    habitatTrends: habitatTrendsSlice,
});
