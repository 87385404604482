import React from 'react';
import useRefWithRerender from 'src/shared/hooks/useRefWithRerender';

type FreemiumCardProps = {
    title: React.ReactNode;
    description: React.ReactNode;
    image?: React.ReactNode;
    footer?: React.ReactNode;
};

const FreemiumCard = (props: FreemiumCardProps) => {
    const { title, description, image = null, footer = null } = props;

    const [cardRef] = useRefWithRerender<HTMLDivElement>(null);

    // Resize wrapped items to match the width of the first item
    React.useEffect(() => {
        const adjustWrappedCard = () => {
            if (cardRef.current) {
                const card = cardRef.current;
                const parent = card.parentElement;
                const firstCard = parent?.firstElementChild as HTMLDivElement;
                const firstCardClientTop = firstCard?.clientTop || 0;
                const isWrapped = card.offsetTop > firstCardClientTop;
                card.style.maxWidth = '500px';
                if (isWrapped) {
                    card.style.maxWidth = firstCard.clientWidth + 'px';
                }
            }
        };

        window.addEventListener('resize', adjustWrappedCard);
        adjustWrappedCard();

        return () => {
            window.removeEventListener('resize', adjustWrappedCard);
        };
    }, []);

    return (
        <div className='flex flex-col flex-grow gap-4 basis-[400px] max-w-[500px]' ref={cardRef}>
            <div className='text-xl font-bold'>{title}</div>
            <div>{description}</div>
            {image}
            {footer}
        </div>
    );
};

export default FreemiumCard;
