import { RootState } from 'src/store';
import { SampleManifestState, initialState } from './sampleManifestSlice';

export const getCurrentSampleManifestState = (state: RootState): SampleManifestState => {
    return state.samples.sampleManifest || initialState;
};

export const getSelectedSamplingKitId = (state: RootState): string => {
    return getCurrentSampleManifestState(state).selectedSamplingKitId;
};

export const getselectedSamplingEventId = (state: RootState): string => {
    return getCurrentSampleManifestState(state).selectedSamplingEventId;
};

export const getIsSavingSamplingKit = (state: RootState): boolean => {
    return getCurrentSampleManifestState(state).isSavingSamplingKit;
};
