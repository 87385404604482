import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability, getSamplesRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation } from 'react-router-dom';
import { samplesRouteSettings } from 'src/app/samples/Samples';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import OpenBoxIcon from 'src/assets/svg/general/open-box.svg?react';
import SampleManifestIcon from 'src/assets/svg/general/sample-manifest.svg?react';
import useSampleManifestAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestAnalytics';
import styles from '../SiteMenu.module.scss';

const SamplesMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);
    const samplesRoutesAvailability = useAppSelector(getSamplesRoutesAvailability);

    const navigate = useAppNavigation();
    const { pathname } = useLocation();
    const { trackSampleManifestLandingFromSiteMenu } = useSampleManifestAnalytics();

    if (!appRoutesAvailability.samples) {
        return null;
    }

    const getItemPath = (menuItemPath: string) => {
        return '/samples' + menuItemPath.replace('/*', '');
    };

    const getClassNames = (menuItemPath: string) => {
        const itemPath = getItemPath(menuItemPath);
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname.startsWith(itemPath),
        });
    };

    const getIconClassNames = (menuItemPath: string) => {
        const itemPath = getItemPath(menuItemPath);
        return pathname.startsWith(itemPath) ? 'fill-primary' : 'fill-[#666]';
    };

    const onSampleManifestClick = () => {
        // Capture GA for sample manifest landing from site menu
        trackSampleManifestLandingFromSiteMenu();
        navigate.toSampleManifest();
    };

    return (
        <>
            {samplesRoutesAvailability.sampleManifest && (
                <div className={getClassNames(samplesRouteSettings.sampleManifest.path)} onClick={onSampleManifestClick}>
                    <div className={styles.icon}>
                        <SampleManifestIcon className={getIconClassNames(samplesRouteSettings.sampleManifest.path)} />
                    </div>
                    <div className={styles.label}>Sample Manifest</div>
                </div>
            )}

            {samplesRoutesAvailability.sampleReception && (
                <div className={getClassNames(samplesRouteSettings.sampleReception.path)} onClick={navigate.toSampleReception}>
                    <div className={styles.icon}>
                        <OpenBoxIcon className={getIconClassNames(samplesRouteSettings.sampleReception.path)} />
                    </div>
                    <div className={styles.label}>Sample Reception</div>
                </div>
            )}
        </>
    );
};

export default SamplesMenu;
