import { gql } from 'graphql-request';

export const speciesTrendsQuery = gql`
    query iucnMetricsData($projectId: String!, $chartId: String!, $habitatAssay: String!) {
        speciesTrends: chart(projectId: $projectId, chartId: $chartId, habitatAssay: $habitatAssay) {
            chartId
            chartType
            chartTitle
            description
            data
            metadata
        }
    }
`;

type SpeciesTrendsData = {
    chartId: string;
    chartType: string;
    chartTitle: string;
    description: string;
    data: {
        data: {
            datetime: string;
            group: {
                type: string;
                value: number;
            }[];
        }[];
        metric: string;
    }[];
};

export type SpeciesTrendsQueryResult = {
    speciesTrends: SpeciesTrendsData;
};
