import { useState } from 'react';
import { useSamplingReceptionProjectListQuery } from 'src/app/samples/sample-reception/state/api/sampleReceptionGraphSlice';
import { getSampleReceptionFilters } from 'src/app/samples/sample-reception/state/sampleReceptionSelector';
import { DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import { useAppSelector } from 'src/store';
export const SAMPLE_RECEPTION_ROWS_PER_PAGE = 15;

const useSampleReceptionList = () => {
    const filters = useAppSelector(getSampleReceptionFilters);
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: SAMPLE_RECEPTION_ROWS_PER_PAGE,
        hiddenColumnIds: new Set(['customerId', 'projectId', 'eventId']),
        selectedRowIds: new Set([]),
    });

    const { currentData, isFetching } = useSamplingReceptionProjectListQuery(
        {
            filter: {
                sampleManifestStatuses: filters.statuses,
                subscriptionTypes: filters.subscriptions,
                query: filters.searchText,
                pendingActionFilter: filters.pendingActions,
            },
            pagination: {
                page: filters.currentPageNumber || 1,
                pageSize: tableState.rowsPerPage as number,
            },
            sortBy: filters.sortBy,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    return {
        isFetching,
        currentData,
        tableState,
        setTableState,
    };
};

export default useSampleReceptionList;
