import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Input from 'src/shared/components/input/Input';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import BasicDatePicker from 'src/shared/components/basic-datepicker/BasicDatePicker';
import BasicTimePicker from 'src/shared/components/basic-time-picker/BasicTimePicker';
import Textarea from 'src/shared/components/textarea/Textarea';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { PrimaryButton } from 'src/shared/components/button/Button';
import moment from 'moment';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import authService from 'src/services/auth-service';
import InputGroup from 'src/shared/components/input-group/InputGroup';
import Select from 'src/shared/components/select/Select';
import { getHabitatOptionsByKitPrefix, getSamplingKitStatusData } from 'src/shared/helpers/kitHelpers';
import useBoolean from 'src/shared/hooks/useBoolean';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { FieldDataStatus, SamplingKitStatus } from 'src/shared/types';
import WarningMessage from 'src/shared/components/warning-message/WarningMessage';
import useOutsideClick from 'src/shared/hooks/useOutsideClick';
import useSampleManifestFieldDataAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestFieldDataAnalytics';
import KitTypeMessage from './components/kit-type-message/KitTypeMessage';
import IndividualFieldTemplate from './components/individual-field-template/IndividualFieldTemplate';
import useFieldDataForm from './hooks/useFieldDataForm';
import { getCollectionMethodOptions } from './fieldDataFormHelpers';
import styles from './FieldDataForm.module.scss';

const FieldDataForm = () => {
    const {
        samplingKitDraft,
        isLockObtained,
        isCurrentSampleNameUnique,
        isCurrentBarcodeUnique,
        isAquaticKit,
        isMaxiAquaticKit,
        isSoilKit,
        isBulkInvertebrateKit,
        isMarineSedimentKit,
        samplingEvent,
        validationData,
        onSamplingKitChange,
        onKitIdChange,
        saveChanges,
        kitType,
    } = useFieldDataForm();

    const { isBatchOnlySubmit, isKitInAnyBatch } = useSamplingEvent();
    const [isOtherCollectionMethod, setIsOtherCollectionMethod] = useBoolean(false);
    const collectionMethodOptions = getCollectionMethodOptions(samplingKitDraft?.habitat || '');
    const formTopRef = useRef<HTMLDivElement>(null);
    const barcodeFieldRef = useRef<HTMLDivElement>(null);
    const [isBarcodeIncomplete, setBarcodeIncomplete] = useBoolean(false);
    const { trackSampleManifestAddDataSaveClickComplete, trackSampleManifestAddDataSaveClickIncomplete } =
        useSampleManifestFieldDataAnalytics();

    useEffect(() => {
        if (!samplingKitDraft) {
            return;
        }
        if (
            samplingKitDraft.collectionMethod &&
            !collectionMethodOptions.some(option => option.value === samplingKitDraft.collectionMethod)
        ) {
            setIsOtherCollectionMethod.on();
        } else {
            setIsOtherCollectionMethod.off();
        }
    }, []);

    useEffect(() => {
        // Scroll to top when a new kit is selected
        if (formTopRef.current) {
            formTopRef.current.scrollTo({ top: 0 });
        }

        if (samplingKitDraft?.barcode && samplingKitDraft.barcode.replaceAll(' ', '').length < 10) {
            setBarcodeIncomplete.on();
        }
    }, [samplingKitDraft?.id]);

    const { errors, warnings, totalErrors } = validationData;
    const barcodeErrors: (string | ReactNode)[] = errors?.barcode || [];
    if (isBarcodeIncomplete) {
        barcodeErrors[0] = (
            <div>
                Incomplete barcode. <br />
                Required format: ABC-12-12345
            </div>
        );
    }

    const onBarcodeFieldInactive = useCallback(() => {
        if (!barcode) {
            return;
        }
        if (barcode.replaceAll(' ', '').length < 10) {
            setBarcodeIncomplete.on();
        } else {
            setBarcodeIncomplete.off();
        }
    }, [samplingKitDraft?.barcode, isBarcodeIncomplete]);

    useOutsideClick(barcodeFieldRef, onBarcodeFieldInactive);

    if (!samplingKitDraft || !samplingEvent) {
        return null;
    }

    const {
        barcode = '',
        latitude,
        longitude,
        sampler,
        habitat,
        notes,
        sampledAt,
        sampledTime,
        volumeFiltered,
        combinedVolumeFiltered,
        // preservationMethod,
        collectionMethod,
        // samplingDuration,
        name,
    } = samplingKitDraft;

    //disable form if the kit is in a batch and not in batch submission mode
    const isDisabled =
        (samplingKitDraft?.id && isKitInAnyBatch(samplingKitDraft.id) && !isBatchOnlySubmit) ||
        samplingKitDraft.status === SamplingKitStatus.REJECTED_BY_LAB ||
        !isLockObtained;

    // disableFutureTime if the sampling date is today
    const shouldDisableFutureTime = Boolean(sampledAt && new Date(sampledAt).toDateString() === new Date().toDateString());

    const assignToMe = () => {
        const user = authService.getCurrentUserData();
        if (user) {
            onSamplingKitChange({ sampler: user.firstName + ' ' + user.lastName });
        }
    };

    // kitPrefix is first 3 characters of barcode, hence the length check below
    const habitatOptions = barcode?.length > 2 ? getHabitatOptionsByKitPrefix(barcode) : [];

    const habitatField = habitatOptions ? (
        <Select
            options={habitatOptions}
            selectedValue={habitat}
            onChange={value => onSamplingKitChange({ habitat: value, testTypes: [] })}
            placeholder='Select'
            width='100%'
            isDisabled={!kitType || isDisabled}
        />
    ) : (
        <Input
            value={habitat}
            onChange={e => onSamplingKitChange({ habitat: e.target.value })}
            disabled={isMarineSedimentKit || isSoilKit || isDisabled}
        />
    );

    const onCollectionMethodChange = (value: string) => {
        if (value === 'Other') {
            setIsOtherCollectionMethod.on();
            onSamplingKitChange({ collectionMethod: '' });
            return;
        }

        setIsOtherCollectionMethod.off();
        onSamplingKitChange({ collectionMethod: value });
    };

    const onSaveClick = () => {
        // Capture GA for sample manifest add field data - save click
        const kitStatusData = getSamplingKitStatusData(samplingKitDraft);
        if (kitStatusData.status === FieldDataStatus.COMPLETE) {
            trackSampleManifestAddDataSaveClickComplete();
        } else if (kitStatusData.status === FieldDataStatus.INCOMPLETE) {
            // capture incomplete fields metadata
            trackSampleManifestAddDataSaveClickIncomplete({ fields: kitStatusData.incompleteFields.join(', ') });
        }

        saveChanges();
    };

    const latLongPlaceHolder = 'e.g. 12.123456';
    const latLongHelpText = <span className='text-xs text-teal-100'>Requires min. 4 decimal places</span>;

    const latLongLabel = (label: string) => {
        return (
            <div>
                {label} <br /> <span className='text-xs text-grey-80'>Decimal degrees </span>
            </div>
        );
    };

    const hasSampleNameError = Boolean(errors.name?.length) || !isCurrentSampleNameUnique;
    const hasBarcodeError = (barcode && Boolean(barcodeErrors.length)) || !isCurrentBarcodeUnique || isBarcodeIncomplete || false;

    return (
        <div className='flex flex-col gap-2 overflow-y-auto' ref={formTopRef}>
            <div className='flex flex-col gap-2 bg-white rounded-md p-4'>
                <div className='flex text-sm text-primary items-center'>
                    Sample name
                    <QuestionButtonHelp slug='sample-manifest-field-notes' type='api' />
                </div>
                <Input
                    value={name}
                    onChange={e => onSamplingKitChange({ name: e.target.value })}
                    maxLength={18}
                    hasError={hasSampleNameError}
                    disabled={isDisabled}
                    placeholder='Type Sample name'
                />
                {Boolean(errors.name?.length) && <ErrorMessage message={errors.name?.[0]} />}
            </div>

            {isLockObtained === false && <WarningMessage message='This sample is being edited by another user, please try later.' />}

            <div className='flex flex-col gap-2 bg-white rounded-md mt-1 p-4 flex-1 pb-8 '>
                <div className='text-lg'>Field data</div>
                <div ref={barcodeFieldRef}>
                    <IndividualFieldTemplate
                        label='Kit Barcode'
                        fieldElement={
                            <InputGroup
                                inputFields={[3, 2, 5]}
                                value={barcode}
                                onChange={value => {
                                    setIsOtherCollectionMethod.off();
                                    setBarcodeIncomplete.off();
                                    onKitIdChange(value);
                                }}
                                placeholders={['ABC', '01', '00001']}
                                disabled={isDisabled}
                            />
                        }
                        message={<KitTypeMessage kitBarcode={barcode} />}
                        helpSlug='sample-manifest-kit-id'
                        errors={hasBarcodeError ? barcodeErrors : undefined}
                    />
                </div>
                <IndividualFieldTemplate
                    label='Sampler'
                    fieldElement={
                        <div className='flex flex-col gap-1'>
                            <Input
                                value={sampler}
                                onChange={e => onSamplingKitChange({ sampler: e.target.value })}
                                maxLength={70}
                                hasError={Boolean(errors?.sampler?.length)}
                                hideMaxLengthLabel={true}
                                placeholder='Type'
                                disabled={isDisabled}
                            />
                        </div>
                    }
                    helpSlug='sample-manifest-sampler'
                    message={
                        <div
                            className={classNames({
                                'cursor-pointer hover:underline text-primary': !isDisabled,
                                [styles.disabled]: isDisabled,
                            })}
                            onClick={!isDisabled ? assignToMe : undefined}
                        >
                            Assign to me
                        </div>
                    }
                    errors={errors?.sampler}
                />

                <IndividualFieldTemplate
                    label='Sampling Date'
                    fieldElement={
                        <BasicDatePicker
                            value={sampledAt ? new Date(sampledAt) : null}
                            onChange={value => onSamplingKitChange({ sampledAt: moment(value)?.toISOString() })}
                            disableFuture={true}
                            minDate={moment(samplingEvent.fromDate)}
                            hasError={Boolean(errors?.sampledAt?.length)}
                            disabled={isDisabled}
                        />
                    }
                    helpSlug='sample-manifest-sampling-date'
                    errors={errors?.sampledAt}
                />

                <IndividualFieldTemplate
                    label='Sampling Time (Optional)'
                    fieldElement={
                        <BasicTimePicker
                            value={sampledTime ? new Date(sampledTime) : null}
                            onChange={value => onSamplingKitChange({ sampledAt: sampledAt, sampledTime: moment(value)?.toISOString() })}
                            disableFuture={shouldDisableFutureTime}
                            disabled={isDisabled}
                        />
                    }
                    helpSlug='sample-manifest-sampling-time'
                />

                <IndividualFieldTemplate
                    label={latLongLabel('Latitude')}
                    fieldElement={
                        <>
                            <Input
                                value={latitude}
                                onChange={e => onSamplingKitChange({ latitude: e.target.value })}
                                hasError={Boolean(errors?.latitude?.length)}
                                title='Enter a latitude between -90.0 and 90.0'
                                placeholder={latLongPlaceHolder}
                                disabled={isDisabled}
                            />
                            {latLongHelpText}
                        </>
                    }
                    helpSlug='sample-manifest-latitude'
                    errors={errors?.latitude}
                />

                <IndividualFieldTemplate
                    label={latLongLabel('Longitude')}
                    fieldElement={
                        <>
                            <Input
                                value={longitude}
                                onChange={e => onSamplingKitChange({ longitude: e.target.value })}
                                hasError={Boolean(errors?.longitude?.length)}
                                title='Enter a longitude between -180.0 and 180.0'
                                placeholder={latLongPlaceHolder}
                                disabled={isDisabled}
                            />
                            {latLongHelpText}
                        </>
                    }
                    helpSlug='sample-manifest-longitude'
                    errors={errors?.longitude}
                />

                <IndividualFieldTemplate label='Habitat' fieldElement={habitatField} helpSlug='sample-manifest-habitat' />

                {isAquaticKit && !isMaxiAquaticKit && (
                    <IndividualFieldTemplate
                        label='Volume Filtered (mL)'
                        fieldElement={
                            <Input
                                value={volumeFiltered ?? ''}
                                type='number'
                                onChange={e => onSamplingKitChange({ volumeFiltered: e.target.value ? Number(e.target.value) : null })}
                                placeholder='Type'
                                disabled={isDisabled}
                            />
                        }
                        helpSlug='sample-manifest-volume-filtered'
                        warnings={warnings.volumeFiltered}
                    />
                )}

                {isMaxiAquaticKit && (
                    <IndividualFieldTemplate
                        label='Combined volume filtered (mL)'
                        fieldElement={
                            <Input
                                value={combinedVolumeFiltered ?? ''}
                                type='number'
                                onChange={e =>
                                    onSamplingKitChange({ combinedVolumeFiltered: e.target.value ? Number(e.target.value) : null })
                                }
                                placeholder='Type'
                                disabled={isDisabled}
                            />
                        }
                        helpSlug='sample-manifest-combined-volume-filtered'
                        warnings={warnings.combinedVolumeFiltered}
                    />
                )}

                {/* {isSoilKit && (
                    <IndividualFieldTemplate
                        label='No. of Subsamples'
                        fieldElement={
                            <Input
                                value={noOfSubSamples}
                                type='number'
                                onChange={e => onSamplingKitChange({ noOfSubSamples: Number(e.target.value) })}
                            />
                        }
                        helpSlug='sample-manifest-no-of-subsamples'
                    />
                )} */}

                {/* {isSoilKit && (
                    <IndividualFieldTemplate
                        label='Preservation Method'
                        fieldElement={
                            <Input value={preservationMethod} onChange={e => onSamplingKitChange({ preservationMethod: e.target.value })} />
                        }
                        helpSlug='sample-manifest-preservation-method'
                    />
                )} */}

                {isBulkInvertebrateKit && (
                    <IndividualFieldTemplate
                        label='Collection Method'
                        fieldElement={
                            <Select
                                options={collectionMethodOptions}
                                selectedValue={isOtherCollectionMethod ? 'Other' : collectionMethod}
                                onChange={onCollectionMethodChange}
                                placeholder={habitat ? 'Select' : 'Requires habitat'}
                                width='100%'
                                isDisabled={!habitat || isDisabled}
                            />
                        }
                        errors={errors?.collectionMethod}
                        helpSlug='sample-manifest-collection-method'
                    />
                )}

                {isBulkInvertebrateKit && isOtherCollectionMethod && (
                    <IndividualFieldTemplate
                        label=''
                        fieldElement={
                            <div className='flex flex-col gap-1'>
                                <span className='text-xs text-primary'>Please specify collection method</span>
                                <Input
                                    value={collectionMethod}
                                    onChange={e => onSamplingKitChange({ collectionMethod: e.target.value })}
                                    maxLength={22}
                                    disabled={isDisabled}
                                />
                            </div>
                        }
                    />
                )}

                {/* {isSoilKit && (
                    <IndividualFieldTemplate
                        label='Sampling Duration'
                        fieldElement={
                            <Input
                                value={samplingDuration}
                                type='number'
                                onChange={e => onSamplingKitChange({ samplingDuration: Number(e.target.value) })}
                            />
                        }
                        helpSlug='sample-manifest-sampling-duration'
                    />
                )} */}

                <div className='flex flex-col gap-2 '>
                    <div className='flex justify-between items-center flex-1'>
                        <div className='flex text-sm text-primary'>Field notes (Optional)</div>
                        <div className='mr-[-8px]'>
                            <QuestionButtonHelp slug='sample-manifest-field-notes' type='api' />
                        </div>
                    </div>
                    <Textarea
                        value={notes}
                        onChange={e => onSamplingKitChange({ notes: e.target.value })}
                        placeholder='Type'
                        maxLength={200}
                        disabled={isDisabled}
                        hasError={Boolean(errors?.notes?.length)}
                    />
                    {Boolean(errors?.notes?.length) && <ErrorMessage message={errors?.notes?.[0]} />}
                </div>
                {!isDisabled && (
                    <PrimaryButton onClick={onSaveClick} disabled={totalErrors > 0} className='flex gap-1'>
                        <SaveOutlinedIcon /> Save
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};

export default FieldDataForm;
