const isValidCoordinate = (value: string | number, regex: RegExp, min: number, max: number): boolean => {
    const valueString = String(value).trim();

    // count the total number of decimal points
    const decimalCount = (valueString.match(/\./g) || []).length;
    if (decimalCount !== 1) return false;

    // Check if the value matches the format for a valid decimal number
    const isValidFormat = regex.test(valueString);

    if (!isValidFormat) return false;

    // Convert to number and check the range explicitly
    const numberValue = parseFloat(valueString);
    return numberValue >= min && numberValue <= max;
};

export const isValidLatitude = (lat: string | number): boolean => {
    const latitudeRegex = /^-?(?:[0-8]?\d(?:\.\d{4,})?|90(?:\.0{4,})?)$/;
    return isValidCoordinate(lat, latitudeRegex, -90, 90);
};

export const isValidLongitude = (lon: string | number): boolean => {
    const longitudeRegex = /^-?(?:1[0-7]\d|\d{1,2})(?:\.\d{4,})?$|^-?180(?:\.0{4,})?$/;
    return isValidCoordinate(lon, longitudeRegex, -180, 180);
};

export const swapCoordinateLatLng = ([lng, lat]: [number, number]): [number, number] => [lat, lng];
