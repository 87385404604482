import React, { useEffect, useState } from 'react';

import { AppName } from 'src/global/globalConfig';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import HabitatAssayLozenges, { HabitatAssayLozengeOption } from 'src/shared/components/lozenges/HabitatAssayLozenges';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { useAppDispatch } from 'src/store';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import TaxonomicCompositionChart from './components/taxonomic-composition-chart/TaxonomicCompositionChart';
import styles from './TaxonomicComposition.module.scss';
import { TaxonomicCompositionFiltersState, updateFilters } from '../../state/taxonomic-composition/taxonomicCompositionSlice';

const PAGE_TITLE = 'Taxonomic Composition';

const TaxonomicComposition = () => {
    const {
        currentProjectFilters,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        setCurrentProjectHabitatAssayType,
        samplingEventFilterData,
    } = useProjectFilters();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);
    const [habitatAssayTypeOptions, setHabitatAssayTypeOptions] = useState<HabitatAssayLozengeOption[]>([]);

    const dispatch = useAppDispatch();

    const onFiltersChange = (fragment: Partial<TaxonomicCompositionFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    useEffect(() => {
        if (!currentProjectFilters) {
            return;
        }

        const { habitatAssays, areaOptions } = currentProjectFilters;

        const newAssaysOptions = habitatAssays.map(entry => ({
            habitatName: entry.habitat.habitatName,
            assayName: entry.assay.assayName,
            value: entry.key,
        }));

        setHabitatAssayTypeOptions(newAssaysOptions);

        const newAreaOptions = [...areaOptions];
        onFiltersChange({
            areas: newAreaOptions.map(entry => entry.value),
        });
    }, [currentProjectFilters?.habitatAssays, currentProjectFilters?.areaOptions]);

    useEffect(() => {
        if (!samplingEventFilterData || samplingEventFilterData.length === 0) {
            return;
        }
        onFiltersChange({
            samplingEvent: samplingEventFilterData[0],
        });
    }, [samplingEventFilterData]);

    if (!samplingEventFilterData) {
        return null;
    }

    const lozenges = (
        <HabitatAssayLozenges
            options={habitatAssayTypeOptions}
            selectedValue={habitatAssayType?.key || ''}
            onChange={setCurrentProjectHabitatAssayType}
            app={AppName.TAXONOMIC_COMPOSITION}
        />
    );
    const mainContent = (
        <div className={styles.taxonomicCompositionChart}>
            <TaxonomicCompositionChart />
        </div>
    );

    return (
        <StandardAppLayout
            breadcrumbs={currentProjectBreadcrumbDetails}
            lozenges={lozenges}
            title={PAGE_TITLE}
            subTitle='A heat tree chart showing species observations across each Taxonomic rank'
            mainContent={mainContent}
            page={AppName.TAXONOMIC_COMPOSITION}
        />
    );
};

export default TaxonomicComposition;
