import { ChevronRight } from '@mui/icons-material';
import React, { useState } from 'react';
import { PrimaryButton } from 'src/shared/components/button/Button';
import { useAppSelector } from 'src/store';
import usePermissions from 'src/shared/hooks/usePermissions';
import { Resources, Access } from 'src/shared/types';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import useSampleManifestSubmitAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestSubmitAnalytics';
import SuccessfullySubmittedModal from './components/successfully-submitted-modal/SuccessfullySubmittedModal';
import SubmitionErrorModal from './components/submission-error-modal/SubmitionErrorModal';
import { getCurrentSampleManifestState } from '../../../state/sampleManifestSelector';

const SubmitData = () => {
    const { trackSampleManifestSubmitAction } = useSampleManifestSubmitAnalytics();
    const [submissionDetails, setSubmissionDetails] = useState({
        result: '',
        batchId: '',
        errorMessage: '',
    });
    const { hasPermission } = usePermissions();
    const { isSamplingEventCompleted, isTermsAndConditionsAccepted } = useAppSelector(getCurrentSampleManifestState);
    const { submitSamplingKits, isBatchOnlySubmit } = useSamplingEvent();
    const hasPermissionToSubmit = hasPermission({
        to: Access.VIEW,
        resource: Resources.SAMPLE_MANIFEST_SUBMIT,
    });
    const isSubmitButtonDisabled = isSamplingEventCompleted === null || !isTermsAndConditionsAccepted || !hasPermissionToSubmit;

    const onSubmit = async () => {
        const result = await submitSamplingKits({
            isSamplingEventCompleted: isSamplingEventCompleted as boolean,
        });

        const hasError = result.hasError;
        const submittedBatchId = result.batchId;

        // Capture GA for sample manifest submit action
        trackSampleManifestSubmitAction({ action: isBatchOnlySubmit ? 'resubmit' : 'submit' });

        if (!hasError) {
            setSubmissionDetails({
                result: 'success',
                batchId: submittedBatchId,
                errorMessage: '',
            });
        } else {
            setSubmissionDetails({
                result: 'error',
                batchId: '',
                errorMessage: result.errorMessage,
            });
        }
    };

    return (
        <div>
            <PrimaryButton onClick={onSubmit} disabled={isSubmitButtonDisabled}>
                {isBatchOnlySubmit ? 'Resubmit' : 'Submit'} data to Naturemetrics <ChevronRight />
            </PrimaryButton>
            {submissionDetails.result === 'success' && <SuccessfullySubmittedModal batchId={submissionDetails.batchId} />}
            {submissionDetails.result === 'error' && (
                <SubmitionErrorModal
                    onClose={() =>
                        setSubmissionDetails({
                            result: '',
                            batchId: '',
                            errorMessage: '',
                        })
                    }
                    errorMessage={submissionDetails.errorMessage}
                />
            )}
        </div>
    );
};

export default SubmitData;
