import React from 'react';
import { SecondaryButton } from 'src/shared/components/button/Button';
import Modal from 'src/shared/components/modal/Modal';
import useBoolean from 'src/shared/hooks/useBoolean';
import { useSearchParams } from 'react-router-dom';
import { SampleManifestFormViews } from 'src/routes/routesConfig';
import SubmissionProcess from '../../../sample-manifest-start-page/components/SubmissionProcess';
import { sampleManifestFormViewSettings } from '../../SampleManifestForm';

const Help = () => {
    const [shouldShowModal, setShouldShowModal] = useBoolean(false);
    const [searchParams] = useSearchParams();
    const viewFromUrl = searchParams.get('view');

    const currentStep = sampleManifestFormViewSettings[viewFromUrl as SampleManifestFormViews]?.step ?? 0;

    return (
        <>
            <SecondaryButton onClick={setShouldShowModal.on}>Help</SecondaryButton>
            {shouldShowModal && (
                <Modal onClose={setShouldShowModal.off}>
                    <SubmissionProcess className='shadow-none h-[423px] w-[1100px] p-0' selectedStep={currentStep} />
                </Modal>
            )}
        </>
    );
};

export default Help;
