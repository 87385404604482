import { find, isEmpty } from 'lodash';
import React from 'react';

import TimeSeriesReChart from 'src/shared/components/charts/time-series/TimeSeriesReChart';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { transformHabitatInsightsChartData } from 'src/app/insights/views/habitat-trends/habitatTrends.utils';
import { getCurrentHabitatTrendsUIState } from 'src/app/insights/state/habitat-trends/habitatTrendsSelector';
import { useHabitatTrendsQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import { useAppSelector } from 'src/store';
import { HabitatInsightsMetricType, TickFormat } from 'src/shared/types';
import { getColourForHabitatInsights } from 'src/shared/constants/colors';
import HabitatTrendsFilters from './components/habitat-trends-filters/HabitatTrendsFilters';
import useHabitatTrendsAnalytics from './hooks/useHabitatTrendsAnalytics';
import styles from './HabitatTrends.module.scss';

const chartDetailsByType: { [key: string]: { [key: string]: string } } = {
    Land_Cover: {
        explainerSlugKey: 'land-cover',
        legendTitle: 'Land Cover Type',
        chartUnits: '%',
    },
    Habitat_Connectivity: {
        explainerSlugKey: 'habitat-connectivity',
        legendTitle: 'Connectivity Type',
    },
    Primary_Productivity: {
        explainerSlugKey: 'primary-productivity',
        legendTitle: 'Vegetation Health',
    },
    Connectivity_Tree: {
        explainerSlugKey: 'habitat-connectivity',
        legendTitle: 'Tree Connectivity',
        chartUnits: 'km\u00b2',
    },
    Connectivity_Grass: {
        explainerSlugKey: 'habitat-connectivity',
        legendTitle: 'Grass Connectivity',
        chartUnits: 'km\u00b2',
    },
    Connectivity_Scrub: {
        explainerSlugKey: 'habitat-connectivity',
        legendTitle: 'Scrub Connectivity',
        chartUnits: 'km\u00b2',
    },
};

const PAGE_TITLE = 'Habitat Trends';
const HabitatTrends = () => {
    const { currentProjectId, currentProjectFilters } = useProjectFilters();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);
    const uiState = useAppSelector(getCurrentHabitatTrendsUIState);
    const { trackChartLegendClick } = useHabitatTrendsAnalytics();

    const {
        filters: { metric, site, bufferArea, annualRange },
    } = uiState;

    const { currentData, isFetching } = useHabitatTrendsQuery(
        {
            projectId: currentProjectId || '',
            habitatInsightsCharts: [metric],
            siteName: [site],
        },
        {
            skip: !currentProjectId || !site || !metric,
        }
    );

    if (isFetching || !currentProjectId) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    const chartData = currentData?.habitatTrends;

    const tranformedChartData =
        chartData && transformHabitatInsightsChartData(chartData.data, bufferArea, annualRange, getColourForHabitatInsights);
    const timeSeriesChartData = tranformedChartData?.chartData || [];
    const legendData = tranformedChartData?.legendData || [];
    const isDataWithBufferArea = tranformedChartData?.isDataWithBufferArea || false;
    const isDataWithAnnualRange = tranformedChartData?.isDataWithAnnualRange || false;
    const metricOptions = currentProjectFilters?.habitatInsightsMetricOptions || [];
    const ticksList = tranformedChartData?.tickslist || [];

    const mainContent = (
        <div className={styles.chartContainer}>
            <div className={styles.habitatInsights}>
                <div className={styles.chartTitle}>
                    <h3>
                        {find(metricOptions, { value: metric })?.label}
                        {chartDetailsByType[metric]?.explainerSlugKey && (
                            <QuestionButtonHelp type='api' placement='right' slug={chartDetailsByType[metric].explainerSlugKey} />
                        )}
                    </h3>
                </div>

                {!isEmpty(chartData) && timeSeriesChartData.length ? (
                    <TimeSeriesReChart
                        chartData={timeSeriesChartData}
                        legendData={legendData}
                        legendTitle={chartDetailsByType[metric]?.legendTitle}
                        tickFormat={TickFormat.YEAR_ONLY}
                        ticksList={ticksList}
                        yAxisDomain={HabitatInsightsMetricType.PRIMARY_PRODUCTIVITY === metric ? [0, 1] : undefined}
                        yAxisLabel={chartDetailsByType[metric]?.chartUnits}
                        onLegendClick={trackChartLegendClick}
                    />
                ) : (
                    <h5>No Metrics to display</h5>
                )}
            </div>
        </div>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            otherActions={
                <HabitatTrendsFilters showBufferAreaFilter={isDataWithBufferArea} showAnnualRangeFilter={isDataWithAnnualRange} />
            }
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={PAGE_TITLE}
            subTitle='Time series'
        />
    );
};

export default HabitatTrends;
