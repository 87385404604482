import { gql } from 'graphql-request';
import { TaxonomySummary } from 'src/shared/types';

export const taxonomicComparisonQuery = gql`
    query getSamples($projectId: String!, $samples: sampleFilter) {
        taxonomicComparisonData: project(projectId: $projectId, samples: $samples) {
            taxonomySummary {
                taxonomy {
                    tax_class
                    tax_family
                    tax_genus
                    tax_kingdom
                    tax_order
                    tax_phylum
                    tax_species
                }
                assayId
                conservationStatus {
                    iucnRedListStatus
                    chegdCommonName
                    chegdGroup
                    onGRIISList
                }
                sampleCount
                percentTotalSamples
                samples
            }
        }
    }
`;

type TaxonomicComparison = {
    taxonomySummary: TaxonomySummary;
};

export type TaxonomicComparisonQueryResult = {
    taxonomicComparisonData: TaxonomicComparison;
};
