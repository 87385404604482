import React from 'react';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation } from 'react-router-dom';
import { appRouteSettings } from 'src/routes/AppRoutes';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import styles from '../SiteMenu.module.scss';

const CustomersMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);

    const navigate = useAppNavigation();
    const { pathname } = useLocation();

    if (!appRoutesAvailability.customers) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: menuItemPath.startsWith(pathname),
        });
    };

    return (
        <>
            <div
                className={getClassNames(appRouteSettings.customers.path)}
                onClick={navigate.toCustomers}
                data-testid='customers-menu-icon'
            >
                <div className={styles.icon}>
                    <BusinessOutlinedIcon />
                </div>
                <div className={styles.label}>Org</div>
            </div>
        </>
    );
};

export default CustomersMenu;
