import { RootState } from 'src/store';
import { SpeciesTrendsUIState, SpeciesTrendsFiltersState, defaultUiState } from './speciesTrendsSlice';

export const getCurrentSpeciesTrendsUIState = (state: RootState): SpeciesTrendsUIState => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }

    return state.insights.speciesTrends.uiState[currentProjectId] || defaultUiState;
};

export const getSpeciesTrendsFilters = (state: RootState): SpeciesTrendsFiltersState => {
    const currentUiState = getCurrentSpeciesTrendsUIState(state);

    return currentUiState.filters;
};
