import React from 'react';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import CompleteIcon from 'src/assets/svg/general/complete.svg?react';
import NoneIcon from 'src/assets/svg/general/none.svg?react';

type ReadyToSubmitColumnProps = {
    kitId: string;
};

const ReadyToSubmitColumn = (props: ReadyToSubmitColumnProps) => {
    const { kitId } = props;
    const { fieldDataCompletedKits } = useSamplingEvent();
    const kit = fieldDataCompletedKits.find(kit => kit.id === kitId);

    if (!kit) {
        return null;
    }

    return (
        <div className='flex justify-center'>
            {kit.testTypes.length > 0 ? (
                <CompleteIcon data-testid='complete-icon' />
            ) : (
                <NoneIcon className='fill-grey-80' data-testid='none-icon' />
            )}
        </div>
    );
};

export default ReadyToSubmitColumn;
