import React from 'react';
import { closeSiteMenu } from 'src/global/state/site-menu/siteMenuSlice';
import { PrimaryButton } from 'src/shared/components/button/Button';
import Modal from 'src/shared/components/modal/Modal';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import { useAppDispatch } from 'src/store';

type UpgradeModalProps = {
    onClose: () => void;
};

const UpgradeModal = (props: UpgradeModalProps) => {
    const navigation = useAppNavigation();
    const dispatch = useAppDispatch();

    const onUpgradeClick = () => {
        props.onClose();
        dispatch(closeSiteMenu());
        navigation.toUpgradeSubscription();
    };

    return (
        <Modal onClose={props.onClose} dismissOnClickOutside={true}>
            <div className='flex flex-col gap-2'>
                <div className='text-2xl mb-4'>Upgrade Required</div>
                <div className='text-lg'>Your current plan does not support this feature. Please upgrade to access this feature.</div>
                <PrimaryButton onClick={onUpgradeClick} className='mt-4'>
                    Upgrade
                </PrimaryButton>
            </div>
        </Modal>
    );
};

export default UpgradeModal;
