import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import useSampleManifestAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestAnalytics';
import StartPageBanner from './components/StartPageBanner';
import Overview from './components/Overview';
import SubmissionProcess from './components/SubmissionProcess';

const SampleManifestStartPage = () => {
    const { trackSampleManifestLandingFromEmailLink } = useSampleManifestAnalytics();
    const [searchParams] = useSearchParams();

    // Track GA for sample manifest landing from email link
    useEffect(() => {
        if (searchParams.get('dsm_user')) {
            trackSampleManifestLandingFromEmailLink();
        }
    }, []);

    const mainContent = (
        <div className='flex flex-col gap-8'>
            <StartPageBanner />
            <Overview />
            <SubmissionProcess />
        </div>
    );

    const title = 'Sample Manifest';

    const breadcrumbs = useProjectBreadcrumb(title);

    return <StandardAppLayout mainContent={mainContent} title={title} breadcrumbs={breadcrumbs} />;
};

export default SampleManifestStartPage;
