import React, { useEffect, useState } from 'react';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import { dataTableDefaultDownloadHandler } from 'src/shared/components/data-table/helpers/downloadHandler';
import DownloadButton from 'src/shared/components/download-button/DownloadButton';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader from 'src/shared/components/loader/Loader';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import { groupBy } from 'lodash';
import moment from 'moment';
import styles from './HabitatInsightsMetricsTable.module.scss';
import { useHabitatInsightsMetricsTableDataQuery } from '../state/api/tablesGraphSlice';

const PAGE_TITLE = 'Habitat insights metric table';
export const HabitatInsightsMetricsTable = () => {
    const { currentProjectFilters, currentProjectId } = useProjectFilters();

    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);
    const [columns, setColumns] = useState<DataTableColumns>([]);
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        fixedHeader: true,
    });

    const { currentData, isFetching } = useHabitatInsightsMetricsTableDataQuery(
        {
            projectId: currentProjectId || '',
            habitatInsightsCharts: currentProjectFilters?.habitatInsightsChartTypes || [],
            siteName: currentProjectFilters?.habitatInsightsSites || [],
        },
        {
            skip: !currentProjectId || !currentProjectFilters?.habitatInsightsChartTypes || !currentProjectFilters?.habitatInsightsSites,
        }
    );

    useEffect(() => {
        const tableApiData = currentData?.habitatInsightsMetricsTableData;
        const rawData = tableApiData?.data;

        if (!rawData) {
            return;
        }

        const rawDataGroupedByDate = groupBy(rawData, entry => moment(Number(entry.datetime)).format('YYYY [Q]Q'));
        const tableData = [];
        const parsedData: {
            [key: string]: {
                [key: string]: (string | number)[];
            };
        } = {};
        let columnCounter = 2;
        const rawColumns: { [key: string]: { chartType: string; columnPosition: number; columnUnit: string } } = {};
        const allDates = Object.keys(rawDataGroupedByDate);

        // loop through each entry in rawDataGroupedByDate
        allDates.forEach(dateEntry => {
            const tableRowData = [] as any;
            parsedData[dateEntry] = {};
            const dataForDate = rawDataGroupedByDate[dateEntry];
            const dataForDateGroupedBySite = groupBy(dataForDate, 'site');

            // loop through each site in dataForDateGroupedBySite
            Object.keys(dataForDateGroupedBySite).forEach(site => {
                const dataForSite = dataForDateGroupedBySite[site];

                dataForSite.forEach(siteData => {
                    if (!parsedData[dateEntry][site]) {
                        const rowData = Array(50).fill('-');
                        rowData[0] = site;
                        // format datetime Year and quarter using moment
                        rowData[1] = dateEntry;
                        parsedData[dateEntry][site] = rowData;
                    }

                    // Add the buffer row
                    if (!parsedData[dateEntry][site + ' - buffer']) {
                        const rowData = Array(50).fill('-');
                        rowData[0] = site + ' - buffer';
                        // format datetime Year and quarter using moment
                        rowData[1] = dateEntry;
                        parsedData[dateEntry][site + ' - buffer'] = rowData;
                    }

                    siteData.group.forEach(groupData => {
                        if (!rawColumns[groupData.type]) {
                            rawColumns[groupData.type] = {
                                chartType: siteData.chartType,
                                columnPosition: columnCounter,
                                columnUnit: groupData.units,
                            };
                            columnCounter++;
                        }

                        const columnPosition = rawColumns[groupData.type].columnPosition;
                        parsedData[dateEntry][site][columnPosition] = groupData.value;

                        // Add the data for the buffer row
                        parsedData[dateEntry][site + ' - buffer'][columnPosition] = groupData.boundary || '-';
                    });
                });
            });
            tableData.push(tableRowData);
        });

        const tableRows = Object.values(parsedData)
            .map(Object.values)
            .flat()
            // Prepend the date to the site before sorting so that the buffer row is always next to the site row
            .sort((a, b) => (a[1] + a[0]).localeCompare(b[1] + b[0]));
        const columnsOrderedByType = Object.values(groupBy(Object.keys(rawColumns).sort(), key => rawColumns[key].chartType)).flat();
        const allTableData = tableRows.map(row => row.splice(0, columnCounter));

        // reorder table columns by chart type and column position
        const tableDataReordered = allTableData.map(row => {
            const newRow = row.slice(0, 2);
            Object.keys(rawColumns).forEach(key => {
                const column = rawColumns[key];
                newRow[column.columnPosition] = row[column.columnPosition];
            });
            return newRow;
        });

        // create array of columns from rawColumns and sort it
        const columns = columnsOrderedByType.map(key => {
            const columnUnit = rawColumns[key].columnUnit;
            return {
                columnId: key,
                title: key + (columnUnit ? ` (${columnUnit})` : ''),
            };
        });

        setColumns([
            {
                columnId: 'Site',
                title: 'Site',
                width: '120px',
            },
            {
                columnId: 'Year',
                title: 'Year',
                width: '100px',
            },
            ...columns,
        ]);

        setTableState({
            ...tableState,
            data: tableDataReordered,
        });
    }, [currentData]);

    const handleDownload = () => {
        if (!tableState.data) {
            return;
        }

        const downloadFileName = ['NM-', currentProjectId, '.Habitat-Insights', '.Metrics', '.csv'].join('');

        dataTableDefaultDownloadHandler({
            data: tableState.data,
            columns,
            downloadFileName,
        });
    };

    const mainContent =
        !isFetching && tableState.data ? (
            <DataTable state={tableState} columns={columns} onStateChange={setTableState} className={styles.dataTable} />
        ) : (
            <Loader />
        );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            otherActions={<DownloadButton onClick={handleDownload} className={styles.downloadButton} />}
            title={PAGE_TITLE}
            subTitle={<CMSArticle slug='habitat-insights-metrics-table-description' />}
            breadcrumbs={currentProjectBreadcrumbDetails}
        />
    );
};

export default HabitatInsightsMetricsTable;
