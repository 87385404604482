import React from 'react';
import { AppName } from 'src/global/globalConfig';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';

const UpgradeSubscription = () => {
    const mainContent = (
        <div>
            <h1>Upgrade Subscription</h1>
        </div>
    );

    return <StandardAppLayout mainContent={mainContent} title='Upgrade Subscription' page={AppName.UPGRADE_SUBSCRIPTION} />;
};

export default UpgradeSubscription;
