import React from 'react';
import { PrimaryButton } from '../button/Button';

export type UpgradeSubscriptionButtonProps = {
    label?: string;
    onClick?: () => void;
    buttonClassName?: string;
};
const UpgradeSubscriptionButton = ({ label, onClick, buttonClassName }: UpgradeSubscriptionButtonProps) => {
    return (
        <a rel='noreferrer' href='https://www.naturemetrics.shop/collections/subscribe' onClick={onClick} target='_blank'>
            <PrimaryButton className={buttonClassName}>{label || 'Subscribe to access'}</PrimaryButton>
        </a>
    );
};

export default UpgradeSubscriptionButton;
