import React, { useEffect } from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import { batch } from 'react-redux';
import { setCurrentCustomerId } from 'src/app/customers/state/customersSlice';
import { setCurrentProjectId } from 'src/app/projects/views/project-list/state/projectListSlice';
import { useAppDispatch } from 'src/store';
import SampleReceptionList from './components/sample-reception-list/SampleReceptionList';
import SampleReceptionFilters from './components/sample-reception-filters/SampleReceptionFilters';

const SampleReceptionHome = () => {
    const dispatch = useAppDispatch();

    // reset customer id and project id to update site menu
    useEffect(() => {
        batch(() => {
            dispatch(setCurrentCustomerId(null));
            dispatch(setCurrentProjectId(null));
        });
    }, []);

    const mainContent = (
        <div className='flex flex-col gap-4'>
            <SampleReceptionFilters />
            <SampleReceptionList />
        </div>
    );

    return <StandardAppLayout title='Sample Reception' subTitle='View customer sample manifests' mainContent={mainContent} />;
};

export default SampleReceptionHome;
