import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { SecondaryButton } from 'src/shared/components/button/Button';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import useSampleManifestAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestAnalytics';
import { SampleManifestFormViews } from 'src/routes/routesConfig';
import { sampleManifestFormViewSettings } from '../../SampleManifestForm';

const SaveAndExit = () => {
    const [searchParams] = useSearchParams();
    const navigation = useAppNavigation();
    const { trackSampleManifestNavigationClick } = useSampleManifestAnalytics();

    const viewFromUrl = searchParams.get('view');
    const currentStep = sampleManifestFormViewSettings[viewFromUrl as SampleManifestFormViews]?.step ?? 0;
    const currentFormView = Object.keys(sampleManifestFormViewSettings).find(
        key => sampleManifestFormViewSettings[key as SampleManifestFormViews].step === currentStep
    );

    const onExitClick = () => {
        // Capture GA for sample manifest navigation - exit button click
        trackSampleManifestNavigationClick({ action: 'exit', page: currentFormView as string });
        navigation.toSampleManifest();
    };

    return <SecondaryButton onClick={onExitClick}>Exit</SecondaryButton>;
};

export default SaveAndExit;
