import React, { ReactNode, useEffect, useState } from 'react';
import MapOutlined from '@mui/icons-material/MapOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { selectSamplingKit } from 'src/app/samples/sample-manifest/state/sampleManifestSlice';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import ExpandableSection from 'src/shared/components/expandable-section/ExpandableSection';
import { useAppDispatch, useAppSelector } from 'src/store';
import PersonIcon from 'src/assets/svg/general/person.svg?react';
import Loader from 'src/shared/components/loader/Loader';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { getSamplingKitStatusData } from 'src/shared/helpers/kitHelpers';
import { FieldDataStatus, SamplingEventBatchStatus, SamplingKitStatus } from 'src/shared/types';
import SamplingEventBatchStatusIndicator from 'src/shared/components/sampling-event-batch-status-indicator/SamplingEventBatchStatusIndicator';
import SamplingEventBatch from 'src/app/samples/components/sampling-event-batch/SamplingEventBatch';
import { SecondaryButton } from 'src/shared/components/button/Button';
import SampleMapModal from 'src/app/samples/components/sample-map-modal/SampleMapModal';
import SamplesCountInfo from 'src/app/samples/components/samples-count-info/SamplesCountInfo';
import { getSelectedSamplingKitId } from 'src/app/samples/sample-manifest/state/sampleManifestSelector';
import useSampleManifestFieldDataAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestFieldDataAnalytics';
import FieldDataStatusIndicator from './components/field-data-status-indicator/FieldDataStatusIndicator';

const FieldDataMainContent = () => {
    const [isMapViewDisabled, setIsMapViewDisabled] = useState(true);
    const [isMapViewOpen, setIsMapViewOpen] = useState(false);
    const { hasKitsWithLatLongs } = useSamplingEvent();

    useEffect(() => {
        setIsMapViewDisabled(!hasKitsWithLatLongs);
    }, [hasKitsWithLatLongs]);

    return (
        <div className='flex flex-1 flex-col gap-2 overflow-y-auto'>
            <div className='text-lg'>Add Field Data</div>
            <div className='flex border-b border-grey-40 gap-12 items-center pb-2'>
                <div>
                    <ViewListOutlinedIcon /> List view
                </div>
                <div>
                    <SecondaryButton onClick={() => setIsMapViewOpen(true)} disabled={isMapViewDisabled}>
                        <MapOutlined />
                        &nbsp;View samples on map
                    </SecondaryButton>
                </div>
                <div className='ml-auto mr-10'>
                    <SamplesCountInfo />
                </div>
            </div>
            <div className='flex flex-1'>
                <MainContentList />
                {isMapViewOpen && <SampleMapModal showTestsInTootip={false} onClose={() => setIsMapViewOpen(false)} />}
            </div>
        </div>
    );
};

const columns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: '',
        isUnique: true,
    },
    {
        columnId: 'sampleName',
        title: 'Sample Name',
        width: '20%',
    },
    {
        columnId: 'kitBarcode',
        title: 'Kit Barcode',
        width: '20%',
    },
    {
        columnId: 'habitat',
        title: 'Habitat',
        width: '20%',
    },
    {
        columnId: 'sampler',
        title: 'Sampler',
        width: '20%',
        renderer(params) {
            return (
                <div className='flex items-center gap-2'>
                    <PersonIcon className='fill-primary' />
                    <div>{(params.cellData as ReactNode) || '-'}</div>
                </div>
            );
        },
    },
    {
        columnId: 'fieldData',
        title: 'Field Data status',
        titleSlug: 'sample-manifest-sample-status',
        width: '20%',
        renderer(params) {
            const fieldDataStatus = params.cellData as string;
            return <FieldDataStatusIndicator status={fieldDataStatus as FieldDataStatus} />;
        },
    },
];

const MainContentList = () => {
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: Number.POSITIVE_INFINITY,
        hiddenColumnIds: new Set(['sampleId']),
    });
    const { samplingEvent, unsubmittedKits, batchesToDisplay, isBatchOnlySubmit } = useSamplingEvent();
    const [totalCompleteSamples, setTotalCompleteSamples] = useState(0);
    const dispatch = useAppDispatch();
    const selectedSamplingKitId = useAppSelector(getSelectedSamplingKitId);
    const { trackSampleManifestAddDataRowClick } = useSampleManifestFieldDataAnalytics();

    useEffect(() => {
        if (!unsubmittedKits) {
            return;
        }
        let totalCompleteSamples = 0;
        const data = unsubmittedKits
            .filter(kit => kit.status !== SamplingKitStatus.SUBMITTED)
            .map(kit => {
                // Field data status can be None, Incomplete, or Complete
                const fieldDataStatus = getSamplingKitStatusData(kit).status;
                if (fieldDataStatus === FieldDataStatus.COMPLETE) {
                    totalCompleteSamples++;
                }
                return [kit.id, kit.name, kit.barcode, kit.habitat, kit.sampler, fieldDataStatus];
            });
        setTotalCompleteSamples(totalCompleteSamples);
        setTableState({ ...tableState, data });
    }, [samplingEvent]);

    useEffect(() => {
        const selectedRowId = tableState.selectedRowIds?.values().next().value || '';
        if (selectedSamplingKitId !== selectedRowId) {
            setTableState(prev => ({ ...prev, selectedRowIds: new Set([selectedSamplingKitId]) }));
        }
    }, [selectedSamplingKitId, tableState.selectedRowIds]);

    const handleRowClick = (row: TableRowData) => {
        const rowId = row[0] as string;
        // Capture GA for sample manifest add field data - sample row click
        trackSampleManifestAddDataRowClick();
        dispatch(selectSamplingKit(rowId));
        setTableState({ ...tableState, selectedRowIds: new Set([rowId]) });
    };

    if (!tableState.data) {
        return <Loader />;
    }

    const table = (
        <DataTable
            state={tableState}
            columns={columns}
            emptyStateMessage={'No samples to display.'}
            className='flex-1 bg-white rounded-md mt-2'
            onRowSelect={handleRowClick}
        />
    );

    const toggle = (
        <div className='pl-8 flex gap-4 items-center overflow-auto'>
            <div>{samplingEvent?.sampleManifestInfo.batches ? 'Remaining Samples' : 'Samples'}</div>
            <div className='text-sm text-grey-60'>{totalCompleteSamples} complete sample(s)</div>
            <div>
                <SamplingEventBatchStatusIndicator status={SamplingEventBatchStatus.DEFAULT} />
            </div>
        </div>
    );

    const batchComponents = batchesToDisplay?.map(
        (batch, index) =>
            batch.kits.length > 0 && (
                <div key={batch.id} className={`flex flex-col ${index !== 0 ? 'mt-4' : ''}`}>
                    <SamplingEventBatch
                        key={batch.id}
                        batchId={batch.id}
                        showDataControl={false}
                        columns={columns}
                        isExpanded={isBatchOnlySubmit}
                        showBatchInfo={false}
                        showPendingStatus={true}
                        handleRowClick={handleRowClick}
                        selectedRowIds={tableState.selectedRowIds}
                    />
                </div>
            )
    );

    const content = (
        <div className='flex flex-col flex-1 mt-4'>
            <div>{batchComponents}</div>
            {!isBatchOnlySubmit && (
                <div className='mt-4'>
                    <ExpandableSection
                        isExpanded={true}
                        classNames={{
                            wrapper: 'flex-1',
                            toggle: 'left-[25px] top-[6px] right-auto',
                        }}
                        expandedContent={
                            <div>
                                {toggle}
                                {table}
                            </div>
                        }
                        collapsedContent={toggle}
                    />
                </div>
            )}
        </div>
    );
    return <div className='flex flex-1 overflow-y-auto'>{content}</div>;
};

export default FieldDataMainContent;
