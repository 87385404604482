import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useSampleManifestSubmitAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: Omit<AnalyticsOptions, 'category'>) => {
        analyticsTracking.trackEvent({
            ...ga,
            category: 'sample_manifest',
            app: AppName.SAMPLE_MANIFEST,
            project: ga.project || currentProjectDetails?.projectName,
        });
    };

    const trackSampleManifestSubmitOption = (props: { action: 'will' | 'will_not' }) => {
        sendTrackEvent({
            action: `sample_manifest_submit_user_${props.action}_submit_more_samples`,
        });
    };

    const trackSampleManifestSubmitAction = (props: { action: 'submit' | 'resubmit' }) => {
        sendTrackEvent({
            action: `sample_manifest_${props.action}_data`,
        });
    };

    const trackSampleManifestSubmitError = (props: { errorType: 'generic' | 'duplicate_name' | 'duplicate_barcode' }) => {
        sendTrackEvent({
            action: `sample_manifest_submit_${props.errorType}_error`,
        });
    };

    return {
        trackSampleManifestSubmitOption,
        trackSampleManifestSubmitAction,
        trackSampleManifestSubmitError,
    };
};

export default useSampleManifestSubmitAnalytics;
