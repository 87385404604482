import React from 'react';
import IUCNLogo from 'src/assets/images/dashboard/iucn.png';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { useAppSelector } from 'src/store';
import { useSpeciesMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import { filterByRedListStatus } from 'src/app/dashboard/components/metrics/metrics-table/metricsTableHelpers';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';

const IucnMetrics = () => {
    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProjectFilters();
    const uiState = useAppSelector(getCurrentDashboardUIState);

    const {
        iucnMetric: { samplingEvent },
    } = uiState;

    const { currentData, isFetching } = useSpeciesMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: samplingEvent?.fromDate,
                toDate: samplingEvent?.toDate,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType || !samplingEvent,
        }
    );

    if (!currentData || isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const { speciesMetrics: speciesMetricsData } = currentData;
    const { taxonomySummary } = speciesMetricsData;

    const iucnRedLists = filterByRedListStatus(taxonomySummary);

    return (
        <MetricCard
            header='IUCN Red List'
            content='Red List species detected'
            helpText={<QuestionButtonHelp type='api' placement='right' slug='iucn-status' />}
            total={iucnRedLists.length}
            logo={<img src={IUCNLogo} alt='Logo' style={{ marginRight: '5px' }} />}
        />
    );
};

export default IucnMetrics;
