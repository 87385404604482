import React from 'react';
import RadioGroup from 'src/shared/components/radio-group/RadioGroup';

type MapTypeProps = {
    handleChange: (value: string) => void;
    value: string;
    hasSubscribed?: boolean;
};

export const mapTypes = [
    { label: 'Default', value: 'roadmap' },
    { label: 'Terrain', value: 'terrain' },
    { label: 'Satellite', value: 'satellite' },
];

const MapType = ({ handleChange, value, hasSubscribed = true }: MapTypeProps) => {
    const mapTypeOptions = hasSubscribed
        ? mapTypes
        : [mapTypes[0], { label: 'Terrain*', value: '', isDisabled: true }, { label: 'Satellite*', value: '', isDisabled: true }];

    return (
        <div className='flex flex-col gap-1'>
            <RadioGroup options={mapTypeOptions} onChange={value => handleChange(value as string)} selectedOption={value} />

            {!hasSubscribed && <div className='text-sm text-grey-80'>*Subscribe to access</div>}
        </div>
    );
};

export default MapType;
