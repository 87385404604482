import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { AppName } from 'src/global/globalConfig';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import { useAppDispatch, useAppSelector } from 'src/store';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import NoData from 'src/shared/components/no-data/NoData';
import HexagonCommunityComparison from 'src/assets/images/insights/hexagon-communitycomparison.png';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import CommunityComparisonAside from './components/community-comparison-aside/CommunityComparisonAside';
import CommunityComparisonChart from './components/community-comparison-chart/CommunityComparisonChart';
import styles from './CommunityComparison.module.scss';
import { CommunityComparisonFiltersState, updateFilters } from '../../state/community-comparison/communityComparisonSlice';
import { getCurrentCommunityComparisonUIState } from '../../state/community-comparison/communityComparisonSelector';
import { useCommunityComparisonChartDataQuery, useCommunityComparisonSamplesQuery } from '../../state/api/insightsGraphSlice';

const PAGE_TITLE = 'Community Comparison';

const CommunityComparison = () => {
    const [samplingYearOptions, setSamplingYearOptions] = useState<SelectOption[]>([]);

    const {
        currentProjectFilters,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        setCurrentProjectHabitatAssayType,
        currentProjectId,
        getColourForArea,
    } = useProjectFilters();

    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);

    const uiState = useAppSelector(getCurrentCommunityComparisonUIState);

    const {
        filters: { samplingYear, selectedSampleGroup },
    } = uiState;

    const { currentData, isFetching } = useCommunityComparisonChartDataQuery(
        {
            projectId: currentProjectId || '',
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    const { currentData: samplesData, isFetching: isFetchingOtherSamples } = useCommunityComparisonSamplesQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                habitatAssay: [habitatAssayType?.key || ''],
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentData || !currentData.communityComparison) {
            return;
        }
        const newSamplingYearOptions = currentData.communityComparison.data[0].years.map((entry, index) => {
            return {
                label: entry.samplingYear,
                value: `${index}`,
            };
        });
        setSamplingYearOptions(newSamplingYearOptions);

        dispatch(
            updateFilters({
                samplingYear: newSamplingYearOptions[0].value,
            })
        );
    }, [currentData, habitatAssayType]);

    if (isFetching || isFetchingOtherSamples) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    const onChartChange = (fragment: Partial<CommunityComparisonFiltersState>) => {
        dispatch(updateFilters({ ...fragment }));
    };

    let mainContent = null;

    if (!currentData || !currentData.communityComparison || !samplesData) {
        mainContent = (
            <NoData
                image={HexagonCommunityComparison}
                text='There is not enough data to display'
                subText='Please select another Habitat/Assay.'
            />
        );
    } else {
        const chartData = currentData.communityComparison.data[0].years;

        const selectedYearData = chartData[Number(samplingYear)].data;

        mainContent = (
            <div className={styles.wrapper}>
                <div className={styles.mainContent}>
                    <div className={styles.textAboveChart}>
                        <span className={styles.descriptionAboveChart}>
                            Each point represents a sample and each coloured ellipse represents a sample group. Similar communities (more of
                            the same species) are closer together on the chart.
                        </span>
                        <span>
                            <QuestionButtonHelp
                                type='api'
                                slug='community-comparison-how-to-read'
                                detailedExplainerSlug='community-comparison-te'
                                label='How do I read this visualisation?'
                                maxWidth={900}
                                placement='bottom'
                            />
                        </span>
                    </div>
                    <CommunityComparisonChart
                        data={selectedYearData}
                        onChange={onChartChange}
                        samples={samplesData.communityComparisonSamples.samples}
                    />
                    <div className={styles.textBelowChart}>Select a sample group in the key to view the species within that community</div>
                    <div className={styles.legends}>
                        {selectedYearData.map((entry, index) => {
                            const legendClassNames = classNames(styles.legend, {
                                [styles.legendOpaqued]: selectedSampleGroup && selectedSampleGroup !== entry.variable.value,
                            });

                            const onLegendClick = () => {
                                if (selectedSampleGroup === entry.variable.value) {
                                    onChartChange({ selectedSampleGroup: null });
                                } else {
                                    onChartChange({ selectedSampleGroup: entry.variable.value });
                                }
                            };

                            return (
                                <div key={index} className={legendClassNames} onClick={onLegendClick}>
                                    <span
                                        className={styles.legendIcon}
                                        style={{ backgroundColor: getColourForArea(entry.variable.value) }}
                                    ></span>
                                    {entry.variable.value}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <CommunityComparisonAside selectedYearData={selectedYearData} />
            </div>
        );
    }

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={setCurrentProjectHabitatAssayType}
            app={AppName.COMMUNITY_COMPARISON}
        />
    );

    const onFiltersChange = (value: string) => {
        dispatch(
            updateFilters({
                samplingYear: value,
                selectedSampleGroup: null,
                selectedSampleId: null,
            })
        );
    };
    const otherActions = (
        <div className={styles.filters}>
            <span className={styles.filterLabel}>Sampling year</span>
            <Select options={samplingYearOptions} onChange={onFiltersChange} selectedValue={samplingYear} width={130} />
        </div>
    );

    return (
        <StandardAppLayout
            lozenges={lozenges}
            mainContent={mainContent}
            otherActions={otherActions}
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={PAGE_TITLE}
            subTitle='Compare and track community composition across sample groups'
            page={AppName.COMMUNITY_COMPARISON}
        />
    );
};

export default CommunityComparison;
