export enum AppName {
    CUSTOMERS = 'Customers',
    PROJECTS = 'Projects',
    DASHBOARD = 'Dashboard',
    SPECIES_TRENDS = 'Species trends',
    HABITAT_TRENDS = 'Habitat trends',
    STATISTICAL_COMPARISON = 'Statistical comparison',
    TAXONOMIC_COMPOSITION = 'Taxonomic composition',
    COMMUNITY_COMPARISON = 'Community Comparison',
    SAMPLE_METRICS_TABLE = 'Sample metrics table',
    SAMPLE_COMPARISON = 'Sample comparison',
    MAP = 'Map',
    UPGRADE_SUBSCRIPTION = 'Upgrade Subscription',
    SAMPLE_MANIFEST = 'Sample Manifest',
}
