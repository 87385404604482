import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type HideShowType = 'show' | 'hide';

export type HabitatTrendsFiltersState = {
    metric: string;
    site: string;
    bufferArea: HideShowType;
    annualRange: HideShowType;
};

const defaultFiltersState = {
    metric: '',
    site: '',
    bufferArea: 'hide' as HideShowType,
    annualRange: 'show' as HideShowType,
};

export type HabitatTrendsUIState = {
    filters: HabitatTrendsFiltersState;
};

export const defaultUiState = {
    filters: { ...defaultFiltersState },
};

type HabitatTrendsState = {
    uiState: {
        [projectId: string]: HabitatTrendsUIState;
    };
};
const initialState: HabitatTrendsState = {
    uiState: {},
};

export const updateFilters =
    (fragment: Partial<HabitatTrendsFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                HabitatTrendsSlice.actions.updateFilters({
                    projectId,
                    fragment,
                })
            );
        }
    };

const ensureProjectUIStatePresence = (state: HabitatTrendsState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            filters: { ...defaultFiltersState },
        };
    }
};

const HabitatTrendsSlice = createSlice({
    name: 'habitatTrends',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<{ projectId: string; fragment: Partial<HabitatTrendsFiltersState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId].filters = {
                ...(state.uiState[projectId].filters as HabitatTrendsFiltersState),
                ...fragment,
            };
        },
    },
});

export default HabitatTrendsSlice.reducer;
