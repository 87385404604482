import { useEffect } from 'react';
import { useProjectQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import {
    getCurrentProjectId,
    getCurrentProjectSelectedHabitatAssayType,
} from 'src/app/projects/views/project-list/state/projectListSelector';
import { setCurrentProjectId as saveCurrentProjectId } from 'src/app/projects/views/project-list/state/projectListSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useSearchParams } from 'react-router-dom';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import useAuthService from './useAuthService';
import { Project, ProgressStatus, SubscriptionType } from '../types/graph/project';

const useProject = () => {
    const dispatch = useAppDispatch();
    const { currentCustomerProjects } = useCustomer();
    const currentProjectId = useAppSelector(getCurrentProjectId);
    const [searchParams] = useSearchParams();
    const projectIdFromUrl = searchParams.get('projectId');
    const currentProjectSelectedHabitatAssayType = useAppSelector(getCurrentProjectSelectedHabitatAssayType);
    const { isAuthenticated } = useAuthService();

    useEffect(() => {
        if (projectIdFromUrl && projectIdFromUrl !== currentProjectId) {
            setCurrentProjectId(projectIdFromUrl);
        }
    }, [projectIdFromUrl]);

    const setCurrentProjectId = (projectId: string | null) => {
        dispatch(saveCurrentProjectId(projectId));
    };

    const { currentData, isFetching } = useProjectQuery(
        {
            projectId: currentProjectId || '',
        },
        {
            skip: !currentProjectId || !isAuthenticated,
        }
    );

    const getCurrentProject = (): Project | null => {
        return currentData?.project || null;
    };

    const getCurrentProjectSummary = () => {
        return currentCustomerProjects?.find(project => project.projectId === currentProjectId);
    };

    const getCurrentProjectDataAvailability = (): boolean => {
        const currentProjectSummary = getCurrentProjectSummary();

        if (!currentProjectSummary) {
            return false;
        }

        const { samplesCount } = currentProjectSummary;

        return samplesCount > 0;
    };

    const isHabitatInsightsProject =
        getCurrentProjectSummary()?.survey?.subscription?.name === SubscriptionType.SURVEY_WITH_HABITAT_INSIGHTS;

    return {
        currentProjectId,
        currentProjectDetails: getCurrentProject(),
        currentProjectSummary: getCurrentProjectSummary(),
        isProjectAvailable: !!currentProjectId,
        isSurveyDesignAvailable: getCurrentProjectSummary()?.survey?.status === ProgressStatus.COMPLETED ? true : false,
        isCurrentProjectEmpty: !getCurrentProjectDataAvailability(),
        isHabitatInsightsProject,
        setCurrentProjectId,
        isFetching,
        isGRIISProject: Boolean(getCurrentProjectSummary()?.griisLookupCountries?.length),
        isSingleTimepoint: currentProjectSelectedHabitatAssayType?.singleTimepoint || false,
        isChegdProject: getCurrentProjectSummary()?.isChegdProject || false,
    };
};

export default useProject;
