import React from 'react';
import SignificantDifference from 'src/assets/images/insights/community-comparison/significant-difference.svg?react';
import NoSignificantDifference from 'src/assets/images/insights/community-comparison/no-significant-difference.svg?react';
import styles from './DifferenceIndicator.module.scss';

type DifferenceIndicatorProps = {
    isDifferent: boolean;
};
const DifferenceIndicator = (props: DifferenceIndicatorProps) => {
    const { isDifferent } = props;
    return (
        <div className={styles.differenceIndicator}>
            <div className={styles.title}>
                {isDifferent ? 'Significant difference in composition' : 'No significant difference in composition'}
            </div>
            {isDifferent ? <SignificantDifference /> : <NoSignificantDifference />}
            <div>
                There is {isDifferent ? 'a' : 'no'} significant difference in the composition of species across all sample groups at the
                timepoint selected
            </div>
        </div>
    );
};

export default DifferenceIndicator;
