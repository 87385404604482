import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { SecondaryButton } from 'src/shared/components/button/Button';
import SearchInput from 'src/shared/components/search-input/SearchInput';
import SelectMultiple, { SelectMultipleOption } from 'src/shared/components/select-multiple/SelectMultiple';
import CloseIcon from 'src/assets/svg/general/close.svg?react';
import Select from 'src/shared/components/select/Select';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    SampleReceptionFilterState,
    updateFilters,
    resetFilters,
    SampleReceptionPendingAction,
} from 'src/app/samples/sample-reception/state/sampleReceptionSlice';
import { getSampleReceptionFilters } from 'src/app/samples/sample-reception/state/sampleReceptionSelector';

import { SelectOption } from 'src/shared/components/select/Select';
import { ProjectSampleManifestStatus, SortOrder, SubscriptionType } from 'src/shared/types';
import { SampleReceptionSortField } from 'src/app/samples/sample-reception/state/api/sampleReceptionGraphSlice';

const StatusSelectLabel = (props: { label: string; hint: string }) => {
    return (
        <div className='flex flex-col gap-0'>
            <span>{props.label}</span>
            <span>{props.hint}</span>
        </div>
    );
};

const statusOptions: SelectOption[] = [
    { value: ProjectSampleManifestStatus.NOT_STARTED, label: 'Not started' },
    {
        value: ProjectSampleManifestStatus.IN_PROGRESS_NO_DATA_SUBMITTED,
        label: <StatusSelectLabel label='In Progress' hint='No data submitted' />,
    },
    {
        value: ProjectSampleManifestStatus.IN_PROGRESS_MORE_SAMPLES_REQUIRED,
        label: <StatusSelectLabel label='In Progress' hint='More samples expected' />,
    },
    {
        value: ProjectSampleManifestStatus.IN_PROGRESS_ALL_SAMPLES_SUBMITTED,
        label: <StatusSelectLabel label='In Progress' hint='All samples submitted' />,
    },
    {
        value: ProjectSampleManifestStatus.COMPLETED_NO_FUTURE_EVENT_PLANNED,
        label: <StatusSelectLabel label='Complete' hint='No future events planned' />,
    },
    {
        value: ProjectSampleManifestStatus.COMPLETED_FUTURE_EVENT_PLANNED,
        label: <StatusSelectLabel label='Complete' hint='Future events planed' />,
    },
    {
        value: ProjectSampleManifestStatus.COMPLETED_ALL_EVENTS_COMPLETE,
        label: <StatusSelectLabel label='Complete' hint='All events complete' />,
    },
];

const subscriptionOptions: SelectMultipleOption[] = [
    { label: SubscriptionType.BASIC, value: SubscriptionType.BASIC },
    { label: SubscriptionType.INSIGHTS, value: SubscriptionType.INSIGHTS, isDisabled: true },
];

const pendingActionOptions: SelectOption[] = [
    { label: 'Pending NM approval', value: SampleReceptionPendingAction.PENDING_NM_APPROVAL },
    { label: 'Pending cust. amendment', value: SampleReceptionPendingAction.PENDING_CUSTOMER_AMENDMENT },
    { label: 'No pending actions', value: SampleReceptionPendingAction.NO_PENDING_ACTIONS },
];

const sortOptions: SelectOption[] = [
    { label: 'Event trigger date (newest-oldest)', value: `${SampleReceptionSortField.KITS_SENT_AT}_${SortOrder.DESC}` },
    { label: 'Event trigger date (oldest-newest)', value: `${SampleReceptionSortField.KITS_SENT_AT}_${SortOrder.ASC}` },
    { label: 'Org name (A-Z)', value: `${SampleReceptionSortField.CUSTOMER_NAME}_${SortOrder.ASC}` },
    { label: 'Org name (Z-A)', value: `${SampleReceptionSortField.CUSTOMER_NAME}_${SortOrder.DESC}` },
];

const SampleReceptionFilters = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(getSampleReceptionFilters);
    const [searchText, setSearchText] = React.useState(filters.searchText);

    useEffect(() => {
        setSearchText(filters.searchText);
    }, [filters.searchText]);

    const onFilterChange = (fragment: Partial<SampleReceptionFilterState>) => {
        dispatch(
            updateFilters({
                ...fragment,
                currentPageNumber: 1,
            })
        );
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    const onSortChange = (value: string) => {
        onFilterChange({
            sortBy: {
                field: value.split('_')[0] as SampleReceptionSortField,
                order: value.split('_')[1] as SortOrder,
            },
        });
    };

    const debouncedSearch = useCallback(
        debounce((textValue: string) => {
            onFilterChange({ searchText: textValue });
        }, 500),
        []
    );

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);

        // debounce the api call by 500ms
        debouncedSearch(event.target.value);
    };

    return (
        <div className='flex flex-wrap justify-between items-end'>
            <SearchInput
                placeholder='Search projects, organisations (names or codes)'
                width='420px'
                onChange={onSearchChange}
                value={searchText}
            />

            <div className='flex gap-2 items-end'>
                <div className='flex flex-col gap-1'>
                    <span className='text-primary'>Subscription</span>
                    <SelectMultiple
                        options={subscriptionOptions}
                        width='200px'
                        onChange={values => onFilterChange({ subscriptions: values as SubscriptionType[] })}
                        values={filters.subscriptions}
                        showSelectAll={false}
                    />
                </div>
                <div className='flex flex-col gap-1'>
                    <span className='text-primary'>Status</span>
                    <SelectMultiple
                        options={statusOptions}
                        width='200px'
                        values={filters.statuses}
                        onChange={values => onFilterChange({ statuses: values as ProjectSampleManifestStatus[] })}
                        showDeselectAll={true}
                    />
                </div>

                <div className='flex flex-col gap-1'>
                    <span className='text-primary'>Pending actions</span>
                    <SelectMultiple
                        options={pendingActionOptions}
                        width='200px'
                        values={filters.pendingActions}
                        onChange={values => onFilterChange({ pendingActions: values as SampleReceptionPendingAction[] })}
                    />
                </div>

                <SecondaryButton className='flex gap-2' onClick={onResetFilters}>
                    <span>Reset</span> <CloseIcon />
                </SecondaryButton>
            </div>

            <div className='flex flex-col gap-1'>
                <span className='text-primary'>Sort by:</span>
                <Select
                    options={sortOptions}
                    width='300px'
                    selectedValue={filters.sortBy.field + '_' + filters.sortBy.order}
                    onChange={onSortChange}
                />
            </div>
        </div>
    );
};

export default SampleReceptionFilters;
