import { gql } from 'graphql-request';

export const habitatTrendsQuery = gql`
    query getHabitatTrends($projectId: String!, $hlcaCharts: [HlcaChartType!], $siteName: [String!]) {
        habitatTrends: chart(projectId: $projectId, chartId: "habitatinsightschart", hlcaCharts: $hlcaCharts, siteName: $siteName) {
            data
        }
    }
`;

type HabitatTrendsData = {
    data: {
        chartType: string;
        datetime: string;
        group: {
            type: string;
            value: number;
            bufferArea?: number;
        }[];
        site: string;
    }[];
};

export type HabitatTrendsQueryResult = {
    habitatTrends: HabitatTrendsData;
};
