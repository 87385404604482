import { downloadTextAsFile } from 'src/shared/helpers/downloadAsFile';
import { TableContextType } from '../DataTable';

export type DownloadHandlerProps = Pick<TableContextType, 'data' | 'columns' | 'downloadFileName'>;

export const dataTableDefaultDownloadHandler = async (props: DownloadHandlerProps) => {
    const { data, columns, downloadFileName } = props;

    const dataForExport: string[][] = [];
    dataForExport[0] = [...columns.filter(column => column.isExportable !== false).map(column => String(column.title ?? ''))];

    data.forEach((cells, rowIndex) => {
        dataForExport[rowIndex + 1] = [];
        cells.forEach((cell, cellIndex) => {
            const { downloadRenderer, isExportable } = columns[cellIndex];

            if (isExportable !== false) {
                const cellExportData = downloadRenderer
                    ? downloadRenderer({
                          rowData: cells,
                          rowId: String(rowIndex),
                          cellData: cell,
                      })
                    : (cell as string | number);

                dataForExport[rowIndex + 1].push(`"${cellExportData}"`);
            }
        });
    });

    const csv = formatCSV(dataForExport);

    downloadTextAsFile(csv, downloadFileName ?? 'Table export.csv');
};

const formatCSV = (table: (string | number)[][]) => {
    return table.map(row => row.join(',')).join('\n');
};
