import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { getSamplesRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, SamplesViews } from 'src/routes/routesConfig';
import cmsService from 'src/services/cms-service';
import { Resources } from 'src/shared/types';
import SampleManifest from './sample-manifest/SampleManifest';
import SampleReception from './sample-reception/SampleReception';
import samplesStorageService from './services/samples-storage-service';
import SampleBatchPrint from './sample-batch-print/SampleBatchPrint';

export const samplesRouteSettings: { [key in SamplesViews]: IndividualRouteSetting } = {
    samples: {
        name: 'Samples',
        path: '/',
        handler: <div>Samples</div>,
        resource: Resources.SAMPLES,
    },
    sampleManifest: {
        name: 'Sample Manifest',
        path: '/sample-manifest/*',
        handler: <SampleManifest />,
        resource: Resources.SAMPLE_MANIFEST,
    },
    sampleReception: {
        name: 'Sample Reception',
        path: '/sample-reception/*',
        handler: <SampleReception />,
        resource: Resources.SAMPLE_RECEPTION,
    },
    sampleBatchPrint: {
        name: 'Sample Batch Print',
        path: '/sample-batch-print/*',
        handler: <SampleBatchPrint />,
        resource: Resources.SAMPLE_BATCH_PRINT,
    },
};

const Samples = () => {
    const routesConfig = useAppSelector(getSamplesRoutesAvailability);

    useEffect(() => {
        const loadKitTestTypesMapping = async () => {
            // Fetch CMS kits data
            const kitTestTypesMapping = await cmsService.getKitTestsMapping();
            samplesStorageService.setKitTestTypesMapping(kitTestTypesMapping || []);
        };

        if (!samplesStorageService.getKitTestTypesMapping().length) {
            loadKitTestTypesMapping();
        }
    }, []);

    if (!routesConfig) {
        return null;
    }

    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as SamplesViews];
                const routeSetting = samplesRouteSettings[routeKey as SamplesViews];

                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'} routeSetting={routeSetting}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Samples;
