import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentProjectId } from 'src/app/projects/views/project-list/state/projectListSelector';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { showUpgradeModal } from 'src/global/state/site-menu/siteMenuSlice';
import { useEffect, useState } from 'react';
import useAppNavigation from './useAppNavigation';
import { Resources } from '../types';

export type HasFeatureParams = {
    resource: Resources;
    projectId?: string;
};

const useFeature = () => {
    const currentProjectId = useAppSelector(getCurrentProjectId);
    const { currentCustomerProjects, currentCustomerId } = useCustomer();
    const dispatch = useAppDispatch();
    const navigation = useAppNavigation();

    const [isFeatureAvailabilityCheckReady, setIsFeatureAvailabilityCheckReady] = useState(false);

    useEffect(() => {
        if (!currentCustomerId || currentCustomerProjects) {
            setIsFeatureAvailabilityCheckReady(true);
        }
    }, [currentCustomerId, currentCustomerProjects]);

    const hasFeature = (params: HasFeatureParams) => {
        const { projectId = currentProjectId, resource } = params;
        const currentProjectDetails = currentCustomerProjects?.find(project => project.projectId === projectId);
        const allowedResources = currentProjectDetails?.subscription?.allowedResources || [];
        return allowedResources.includes(resource);
    };

    const checkFeatureAvailabilityAndAlert = (params: HasFeatureParams) => {
        if (!hasFeature(params)) {
            dispatch(showUpgradeModal());
            return false;
        }
        return true;
    };

    const checkFeatureAvailabilityAndRedirect = (params: HasFeatureParams) => {
        const { projectId = currentProjectId } = params;
        if (!hasFeature(params) && projectId) {
            navigation.toUpgradeSubscription();
            return false;
        }

        return true;
    };

    return {
        isFeatureAvailabilityCheckReady,
        hasFeature,
        checkFeatureAvailabilityAndAlert,
        checkFeatureAvailabilityAndRedirect,
    };
};

export default useFeature;
