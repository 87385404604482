import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';

import { getMapRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, MapViews } from 'src/routes/routesConfig';
import { Resources } from 'src/shared/types';

// Lazy load the map components
const HabitatMap = lazy(() => import('./views/habitat-map/HabitatMap'));
const SpeciesMap = lazy(() => import('./views/species-map/SpeciesMap'));

export const mapRouteSettings: { [key in MapViews]: IndividualRouteSetting } = {
    speciesMap: {
        name: 'Species Map',
        path: '/species-insights-map',
        handler: (
            <Suspense fallback='Loading...'>
                <SpeciesMap />
            </Suspense>
        ),
        resource: Resources.SPECIES_INSIGHTS_MAP,
    },
    habitatMap: {
        name: 'Habitat map',
        path: '/habitat-insights-map',
        handler: (
            <Suspense fallback='Loading...'>
                <HabitatMap />
            </Suspense>
        ),
        resource: Resources.HABITAT_INSIGHTS_MAP,
    },
};

const Maps = () => {
    const routesConfig = useAppSelector(getMapRoutesAvailability);
    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as MapViews];
                const routeSetting = mapRouteSettings[routeKey as MapViews];
                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'} routeSetting={routeSetting}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Maps;
