import React, { ReactNode, useState } from 'react';

import { Tooltip as MUITooltip, TooltipProps as MUITooltipProps, Fade, tooltipClasses } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import { PopperProps } from '@mui/material/Popper';

export type TooltipProps = {
    content: NonNullable<ReactNode>;
    children: ReactNode;
    isOpen?: boolean;
    placement?: MUITooltipProps['placement'];
    maxWidth?: number | string;
    padding?: number | string;
    popper?: Partial<PopperProps>;
};
const Tooltip = (props: TooltipProps) => {
    const { placement = 'right', children, content } = props;
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <LightTooltip
                arrow
                title={content}
                placement={placement}
                open={open || props.isOpen}
                onClose={handleTooltipClose}
                enterDelay={500}
                leaveDelay={200}
                slotProps={{
                    tooltip: {
                        sx: {
                            maxWidth: props.maxWidth ? props.maxWidth : '300px',
                            padding: props.padding ? props.padding : '20px',
                        },
                    },
                    popper: props.popper,
                    transition: { timeout: 600 },
                }}
                slots={{
                    transition: Fade,
                }}
            >
                <span onMouseEnter={handleTooltipOpen} style={{ display: 'inline-flex' }}>
                    {children}
                </span>
            </LightTooltip>
        </ClickAwayListener>
    );
};
const LightTooltip = styled(
    React.forwardRef((props: MUITooltipProps, ref) => <MUITooltip {...props} classes={{ popper: props.className }} ref={ref} />)
)(props => {
    return {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: props.theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: props.theme.shadows[22],
            fontSize: 15,
            borderRadius: '10px',
            marginTop: '0 !important',
        },
        [`& .MuiTooltip-arrow::before`]: {
            backgroundColor: props.theme.palette.common.white,
        },
    };
});

export default Tooltip;
