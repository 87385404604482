import {
    TimeSeriesData,
    LegendData,
    LineStyleType,
    LegendStyleType,
    TickFormat,
    InteractivityPreference,
    TimeSeriesChartData,
} from 'src/shared/types';
import moment from 'moment';
import { availableAreaColors } from 'src/shared/constants/colors';

type habitatInsightsTrasformedChartData = {
    chartData: TimeSeriesChartData[];
    legendData: LegendData[];
    isDataWithBufferArea: boolean;
    isDataWithAnnualRange: boolean;
    tickslist: string[];
};

export const transformHabitatInsightsChartData = (
    timeSeriesData: TimeSeriesData[],
    bufferArea: string,
    annualRange: string,
    getColourForHabitatInsights: (type: string) => string
): habitatInsightsTrasformedChartData => {
    const output: habitatInsightsTrasformedChartData = {
        chartData: [],
        legendData: [],
        isDataWithBufferArea: false,
        isDataWithAnnualRange: false,
        tickslist: [],
    };

    const colorMap: { [key: string]: string } = {};
    let colorIndex = 0;
    const getColorForString = (str: string): string => {
        if (!colorMap[str]) {
            colorMap[str] = availableAreaColors[colorIndex % availableAreaColors.length];
            colorIndex++;
        }
        return colorMap[str];
    };

    const aggregatedData: { [type: string]: { [year: number]: number } } = {};
    const bufferAreaData: { [type: string]: { [year: number]: number } } = {};
    const annualRangeData: { [type: string]: { [year: number]: [number, number] } } = {};

    timeSeriesData.forEach(timeSeriesEntry => {
        timeSeriesEntry.group.forEach(metric => {
            const yearFromDateTime = parseInt(moment(parseInt(timeSeriesEntry.datetime)).format(TickFormat.YEAR_ONLY));

            // Aggregate main data
            if (!aggregatedData[metric.type]) {
                aggregatedData[metric.type] = {};
            }
            aggregatedData[metric.type][yearFromDateTime] =
                Math.round(
                    (100 *
                        ((aggregatedData[metric.type][yearFromDateTime] || 0) +
                            (Array.isArray(metric.value) ? metric.value.reduce((a, b) => a + b, 0) : metric.value))) /
                        (aggregatedData[metric.type][yearFromDateTime] ? 2 : 1)
                ) / 100;

            // Aggregate buffer area data
            if (metric.boundary) {
                output.isDataWithBufferArea = true;
                if (!bufferAreaData[metric.type]) bufferAreaData[metric.type] = {};
                bufferAreaData[metric.type][yearFromDateTime] =
                    Math.round(
                        (100 * ((bufferAreaData[metric.type][yearFromDateTime] || 0) + metric.boundary)) /
                            (bufferAreaData[metric.type][yearFromDateTime] ? 2 : 1)
                    ) / 100;
            }

            // Aggregate annual range data
            if (metric.annualRange) {
                output.isDataWithAnnualRange = true;
                if (!annualRangeData[metric.type]) annualRangeData[metric.type] = {};
                annualRangeData[metric.type][yearFromDateTime] = [metric.annualRange.min, metric.annualRange.max];
            }
        });
    });

    Object.keys(aggregatedData).forEach(type => {
        let groupObjColor = getColourForHabitatInsights(type);
        if (groupObjColor === '#000') {
            groupObjColor = getColorForString(type);
        }

        output.chartData.push({
            type,
            data: Object.entries(aggregatedData[type]).map(([year, value]) => ({
                date: parseInt(year),
                value,
            })),
            color: groupObjColor,
            label: type,
            groupType: 'group' + type,
        });

        output.legendData.push({
            label: type,
            type,
            color: groupObjColor,
            groupType: 'group' + type,
            interactivityPreference: InteractivityPreference.GROUP_TYPE,
        });

        if (bufferArea === 'show' && bufferAreaData[type]) {
            output.chartData.push({
                type: 'Habitat Insights Buffer Area',
                data: Object.entries(bufferAreaData[type]).map(([year, value]) => ({
                    date: parseInt(year),
                    value,
                })),
                color: getColourForHabitatInsights(type),
                label: type + ' Buffer Area',
                styleType: LineStyleType.DASHED,
                groupType: 'group' + type,
            });
        }

        if (annualRange === 'show' && annualRangeData[type]) {
            output.chartData.push({
                type: 'Habitat Insights Annual Range',
                data: Object.entries(annualRangeData[type]).map(([year, value]) => ({
                    date: parseInt(year),
                    value,
                })),
                color: getColourForHabitatInsights(type),
                label: type + ' Annual Range',
                styleType: LineStyleType.AREA,
            });
        }
    });

    if (output.isDataWithBufferArea && bufferArea === 'show') {
        output.legendData.push({
            label: 'Buffer area',
            type: 'Habitat Insights Buffer Area',
            color: '#D9D9D9',
            styleType: LegendStyleType.DASHED,
            marginTop: '18px',
        });
    }

    if (output.isDataWithAnnualRange && annualRange === 'show') {
        output.legendData.push({
            label: 'Annual range',
            type: 'Habitat Insights Annual Range',
            color: '#C1C1C1',
            styleType: LegendStyleType.AREA,
            marginTop: '18px',
        });
    }

    if (output.chartData.length > 0) {
        output.tickslist = output.chartData[0].data.map(value => value.date.toString());
    }

    return output;
};
