import React, { useRef } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import noop from 'lodash/noop';
import {
    GetPinDivIconParams,
    getPinDivIcon,
} from 'src/app/maps/views/species-map/components/species-map-main/components/map-marker/mapMarkerHelpers';
import MarkerTooltip from '../marker-tooltip/MarkerTooltip';
import { SamplingKitInfo } from '../../SampleMapModal';

type MapMarkerProps = {
    samplingKitInfo: SamplingKitInfo;
    color: string;
    groupOrder: number;
    hoveredSamplingKitId: string | null;
    showTestsInTootip?: boolean;
    handleMarkerMouseOver: (sampleKitId: string | null) => void;
};

const MapMarker = (props: MapMarkerProps) => {
    const { samplingKitInfo, color, groupOrder, hoveredSamplingKitId, showTestsInTootip, handleMarkerMouseOver } = props;
    const markerRef = useRef<L.Marker | null>(null);

    // If the current sample is hovered, remove the `mouseover` event to prevent repeated firing
    const isCurrentSampleHovered = hoveredSamplingKitId === samplingKitInfo.id;

    const markerEventHandlers = {
        mouseover: isCurrentSampleHovered ? noop : () => handleMarkerMouseOver(samplingKitInfo.id),
        mouseout: () => handleMarkerMouseOver(null),
        tooltipclose: () => handleMarkerMouseOver(null),
    };

    const getIconParams: GetPinDivIconParams = {
        color: color,
        groupOrder: groupOrder,
        isSurveyPin: false,
    };

    return (
        <Marker
            ref={markerRef}
            riseOnHover={true}
            position={[Number(samplingKitInfo.latitude), Number(samplingKitInfo.longitude)]}
            eventHandlers={{ ...markerEventHandlers }}
            icon={getPinDivIcon(getIconParams)}
        >
            <MarkerTooltip samplingKitInfo={samplingKitInfo} hoveredSamplingKitId={hoveredSamplingKitId} showTests={showTestsInTootip} />
        </Marker>
    );
};

export default MapMarker;
