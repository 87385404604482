import React from 'react';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import Tooltip from '../tooltip/Tooltip';

type BackNextNavigationProps = {
    onBack: () => void;
    onNext: () => void;
    disableNext?: boolean;
    disableBack?: boolean;
    hideNext?: boolean;
    hideBack?: boolean;
    nextTooltip?: React.ReactNode;
    backTooltip?: React.ReactNode;
};

const BackNextNavigation = (props: BackNextNavigationProps) => {
    const { onBack, onNext, disableNext, disableBack, hideBack, hideNext, nextTooltip, backTooltip } = props;

    const backButton = backTooltip ? (
        <Tooltip content={backTooltip}>
            <SecondaryButton onClick={onBack} disabled={disableBack}>
                <ChevronLeft /> Back
            </SecondaryButton>
        </Tooltip>
    ) : (
        <SecondaryButton onClick={onBack} disabled={disableBack}>
            <ChevronLeft /> Back
        </SecondaryButton>
    );

    const nextButton = nextTooltip ? (
        <Tooltip content={nextTooltip}>
            <PrimaryButton onClick={onNext} disabled={disableNext}>
                Next <ChevronRight />
            </PrimaryButton>
        </Tooltip>
    ) : (
        <PrimaryButton onClick={onNext} disabled={disableNext}>
            Next <ChevronRight />
        </PrimaryButton>
    );

    return (
        <div className='flex gap-2'>
            {!hideBack && backButton}

            {!hideNext && nextButton}
        </div>
    );
};

export default BackNextNavigation;
