import { LeafletEvent } from 'leaflet';
import { useMapEvents } from 'react-leaflet';

type MapEventsHandlerProps = {
    handleMapEvents: (event: LeafletEvent) => void;
};

const MapEventsHandler = ({ handleMapEvents }: MapEventsHandlerProps) => {
    useMapEvents({
        zoomend: e => handleMapEvents(e),
        dragend: e => handleMapEvents(e),
    });
    return null;
};

export default MapEventsHandler;
