export type BaseUrls = {
    graphApiUrl: string;
    cmsApiUrl: string;
    apiBaseUrl: string;
    gbifApiUrl: string;
    supportUrl: string;
    sentryDsn: string;
    shopifyRedirectUrl: string;
};

export type CookiesConfig = {
    cookiesDomain: string;
    cookiesExpires: number;
    cookiesPath: string;
    cookiesSecure: boolean;
};

export type Keys = {
    cognitoUserPoolId: string;
    cognitoClientId: string;
    googleMapsJavascriptApiKey: string;
    googleAnalyticsKey: string;
    msClarityAnalyticsKey: string;
};

export type PublicKeys = {
    googleAnalyticsKey: string;
    msClarityAnalyticsKey: string;
};

const defaultUrls: BaseUrls = {
    graphApiUrl: 'https://api.dev.nm-digital.net/query',
    cmsApiUrl: 'https://cms.dev.nm-digital.net/api',
    apiBaseUrl: 'https://api.dev.nm-digital.net',
    gbifApiUrl: 'https://api.gbif.org/v1',
    supportUrl: 'https://naturemetrics.atlassian.net/servicedesk/customer/portal/14/group/55',
    sentryDsn: 'https://87916080e3f845ab908b40fe182348e5@o4505425811406848.ingest.sentry.io/4505442338734080',
    shopifyRedirectUrl: '',
};

const defaultKeys: Keys = {
    cognitoUserPoolId: '',
    cognitoClientId: '',
    googleMapsJavascriptApiKey: '',
    googleAnalyticsKey: '',
    msClarityAnalyticsKey: '',
};

const defaultCookiesConfig: CookiesConfig = {
    cookiesDomain: 'localhost',
    cookiesExpires: 7,
    cookiesPath: '/',
    cookiesSecure: false,
};

const getCookiesConfig = (): CookiesConfig => {
    return {
        cookiesDomain: import.meta.env.REACT_APP_COOKIES_DOMAIN ?? defaultCookiesConfig.cookiesDomain,
        cookiesExpires: import.meta.env.REACT_APP_COOKIES_EXPIRES_IN_DAYS
            ? parseInt(import.meta.env.REACT_APP_COOKIES_EXPIRES_IN_DAYS)
            : defaultCookiesConfig.cookiesExpires,
        cookiesPath: import.meta.env.REACT_APP_COOKIES_PATH ?? defaultCookiesConfig.cookiesPath,
        cookiesSecure: import.meta.env.REACT_APP_COOKIES_SECURE_FLAG
            ? import.meta.env.REACT_APP_COOKIES_SECURE_FLAG === 'true'
            : defaultCookiesConfig.cookiesSecure,
    };
};

const getBaseApiUrls = (): BaseUrls => {
    return {
        graphApiUrl: import.meta.env.REACT_APP_GRAPH_API_URL ?? defaultUrls.graphApiUrl,
        cmsApiUrl: import.meta.env.REACT_APP_CMS_API_URL ?? defaultUrls.cmsApiUrl,
        apiBaseUrl: import.meta.env.REACT_APP_API_BASE_URL ?? defaultUrls.apiBaseUrl,
        gbifApiUrl: import.meta.env.REACT_APP_GBIF_API_URL ?? defaultUrls.gbifApiUrl,
        supportUrl: import.meta.env.REACT_APP_SUPPORT_URL ?? defaultUrls.supportUrl,
        sentryDsn: import.meta.env.REACT_APP_SENTRY_DSN ?? defaultUrls.sentryDsn,
        shopifyRedirectUrl: import.meta.env.REACT_APP_SHOPIFY_REDIRECT_URL ?? defaultUrls.shopifyRedirectUrl,
    };
};

const getKeys = (): Keys => {
    return {
        cognitoUserPoolId: import.meta.env.REACT_APP_COGNITO_USER_POOL_ID ?? defaultKeys.cognitoUserPoolId,
        cognitoClientId: import.meta.env.REACT_APP_COGNITO_CLIENT_ID ?? defaultKeys.cognitoClientId,
        googleMapsJavascriptApiKey: import.meta.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY ?? defaultKeys.googleMapsJavascriptApiKey,
        googleAnalyticsKey: import.meta.env.REACT_APP_GOOGLE_ANALYTICS_KEY ?? defaultKeys.googleMapsJavascriptApiKey,
        msClarityAnalyticsKey: import.meta.env.REACT_APP_MS_CLARITY_KEY ?? defaultKeys.msClarityAnalyticsKey,
    };
};

const deploymentEnv = import.meta.env.REACT_APP_DEPLOYMENT_ENV ?? 'localhost';

export default {
    isDevelopment: import.meta.env.NODE_ENV === 'development',
    isProduction: import.meta.env.NODE_ENV !== 'development',
    shouldReportErrors: ['dev', 'uat', 'staging', 'prod'].includes(deploymentEnv),
    deploymentEnv,
    ...getBaseApiUrls(),
    ...getKeys(),
    ...getCookiesConfig(),
};
