import React from 'react';
import SearchIcon from 'src/assets/svg/general/search.svg?react';

import classNames from 'classnames';
import styles from './SearchInput.module.scss';
import Input, { InputProps } from '../input/Input';

type SearchInputProps = InputProps & {
    width?: string;
};

const SearchInput = (props: SearchInputProps) => {
    const { width = `300px` } = props as SearchInputProps;

    const className = classNames(styles.searchInput, props.className);
    return (
        <div className={styles.searchInputContainer} style={{ width }}>
            <SearchIcon className={styles.searchIcon} />
            <Input {...props} className={className} />
        </div>
    );
};

export default SearchInput;
