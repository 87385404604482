import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useSampleManifestAddTestsAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: Omit<AnalyticsOptions, 'category'>) => {
        analyticsTracking.trackEvent({
            ...ga,
            category: 'sample_manifest',
            app: AppName.SAMPLE_MANIFEST,
            project: ga.project || currentProjectDetails?.projectName,
        });
    };

    const trackSampleManifestAddTestsViewMapClick = () => {
        sendTrackEvent({
            action: 'sample_manifest_add_tests_view_samples_on_map_click',
        });
    };

    const trackSampleManifestAddTestsAddDropdownAction = (props: { action: 'click' | 'test_added' | 'test_removed' }) => {
        sendTrackEvent({
            action: `sample_manifest_add_tests_add_dropdown_${props.action}`,
        });
    };

    const trackSampleManifestRemoveTestsIconClick = () => {
        sendTrackEvent({
            action: 'sample_manifest_add_tests_remove_tests_icon_click',
        });
    };

    return {
        trackSampleManifestAddTestsViewMapClick,
        trackSampleManifestAddTestsAddDropdownAction,
        trackSampleManifestRemoveTestsIconClick,
    };
};

export default useSampleManifestAddTestsAnalytics;
