import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useHabitatTrendsAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: Omit<AnalyticsOptions, 'category'>) => {
        analyticsTracking.trackEvent({
            ...ga,
            project: currentProjectDetails?.projectName,
            app: AppName.HABITAT_TRENDS,
            category: 'habitat-trends',
        });
    };

    const trackAnnualRangeFilterClick = () => {
        sendTrackEvent({
            action: `habital_trends_annual_range_filter_click`,
        });
    };

    const trackBufferAreaFilterClick = () => {
        sendTrackEvent({
            action: `habital_trends_buffer_area_filter_click`,
        });
    };

    const trackSiteFilterClick = () => {
        sendTrackEvent({
            action: `habital_trends_site_filter_click`,
        });
    };

    const trackChartLegendClick = () => {
        sendTrackEvent({
            action: `habital_trends_chart_legend_click`,
        });
    };

    const trackMetricFilterClick = (props: { metric: string }) => {
        sendTrackEvent({
            action: `habital_trends_${props.metric}_metric_filter_click`,
        });
    };

    return {
        trackAnnualRangeFilterClick,
        trackBufferAreaFilterClick,
        trackSiteFilterClick,
        trackChartLegendClick,
        trackMetricFilterClick,
    };
};

export default useHabitatTrendsAnalytics;
