import React, { ButtonHTMLAttributes } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

import { SecondaryButton } from '../button/Button';

export type DownloadButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    buttonClassName?: string;
};

const DownloadButton = (props: DownloadButtonProps) => {
    const { className, buttonClassName, onClick } = props;

    const buttonLabel = (
        <>
            <span>Download</span>
            <DownloadIcon />
        </>
    );

    return (
        <div className={className}>
            <SecondaryButton onClick={onClick} className={buttonClassName}>
                {props.children || buttonLabel}
            </SecondaryButton>
        </div>
    );
};

export default DownloadButton;
