import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useFreemiumInsightsAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: Omit<AnalyticsOptions, 'category'>) => {
        analyticsTracking.trackEvent({
            ...ga,
            project: currentProjectDetails?.projectName,
            category: 'upgrade-subscription-link',
        });
    };

    const trackUpgradeSubscriptionClick = () => {
        sendTrackEvent({
            action: 'upgrade_subscription_click_from_insights',
        });
    };

    return {
        trackUpgradeSubscriptionClick,
    };
};

export default useFreemiumInsightsAnalytics;
