import React from 'react';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import HabitatMapIcon from 'src/assets/svg/maps/habitat-map/habitat-map-icon.svg?react';
import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability, getMapRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation } from 'react-router-dom';
import useProject from 'src/shared/hooks/useProject';
import { mapRouteSettings } from 'src/app/maps/Maps';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import styles from '../SiteMenu.module.scss';

const MapMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);
    const mapRoutesAvailability = useAppSelector(getMapRoutesAvailability);

    const navigate = useAppNavigation();
    const { pathname } = useLocation();

    const { isHabitatInsightsProject } = useProject();

    if (!appRoutesAvailability.map) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname.endsWith(menuItemPath),
        });
    };

    return (
        <>
            {mapRoutesAvailability.speciesMap && (
                <div className={getClassNames(mapRouteSettings.speciesMap.path)} onClick={navigate.toSpeciesMap}>
                    <div className={styles.icon}>
                        <MapOutlinedIcon />
                    </div>
                    <div className={styles.label}>{mapRouteSettings.speciesMap.name}</div>
                </div>
            )}

            {mapRoutesAvailability.habitatMap && isHabitatInsightsProject && (
                <div className={getClassNames(mapRouteSettings.habitatMap.path)} onClick={navigate.toHabitatMap}>
                    <div className={styles.icon}>
                        <HabitatMapIcon />
                    </div>
                    <div className={styles.label}>{mapRouteSettings.habitatMap.name}</div>
                </div>
            )}
        </>
    );
};

export default MapMenu;
