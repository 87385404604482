import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import { StatisticalComparisonFiltersState, updateFilters } from 'src/app/insights/state/statistical-comparison/statisticalComparisonSlice';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentStatisticalComparisonUIState } from 'src/app/insights/state/statistical-comparison/statisticalComparisonSelector';
import { useStatisticalComparisonQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import styles from './StatisticalComparisonFilters.module.scss';

const StatisticalComparisonFilters = () => {
    const [metricOptions, setMetricOptions] = useState<SelectOption[]>([]);
    const [comparingOptions, setComparingOptions] = useState<SelectOption[]>([]);
    const [compareWithOptions, setCompareWithOptions] = useState<SelectOption[]>([]);
    const [samplingYearOptions, setSamplingYearOptions] = useState<SelectOption[]>([]);

    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType, getColourForArea } = useProjectFilters();

    const dispatch = useAppDispatch();

    const uiState = useAppSelector(getCurrentStatisticalComparisonUIState);

    const {
        filters: { metric, comparing, comparingWith, samplingYear },
    } = uiState;

    const onFiltersChange = (fragment: Partial<StatisticalComparisonFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    const { currentData, isFetching } = useStatisticalComparisonQuery(
        {
            projectId: currentProjectId || '',
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    useEffect(() => {
        if (!habitatAssayType) {
            return;
        }

        const newMetricsOptions = habitatAssayType.metrics.map(metric => ({
            label: startCase(metric.metricName),
            value: metric.metricKey,
        }));

        setMetricOptions(newMetricsOptions);

        if (newMetricsOptions.length) {
            dispatch(
                updateFilters({
                    metric: newMetricsOptions[0].value,
                })
            );
        } else {
            setMetricOptions([
                {
                    label: 'None available',
                    value: 'none',
                },
            ]);
        }
    }, [habitatAssayType]);

    useEffect(() => {
        if (!currentData) {
            return;
        }

        const { statisticalComparison } = currentData;
        const newSamplingYearOptions = statisticalComparison.data[0].years.map(entry => {
            return {
                label: entry.samplingYear,
                value: entry.samplingYear,
            };
        });
        setSamplingYearOptions(newSamplingYearOptions);

        const uniqueSites: string[] = [];
        const newComparingOptions: SelectOption[] = [];

        statisticalComparison.data.forEach(entry =>
            entry.years.forEach(year =>
                year.boxes.forEach(box => {
                    if (!uniqueSites.includes(box.variable.key)) {
                        newComparingOptions.push({
                            label: box.variable.key,
                            value: box.variable.value,
                        });

                        uniqueSites.push(box.variable.key);
                    }
                })
            )
        );

        setComparingOptions(newComparingOptions);

        setCompareWithOptions(newComparingOptions);

        dispatch(
            updateFilters({
                comparing: newComparingOptions[0].value,
                comparingWith: newComparingOptions[1].value,
                samplingYear: newSamplingYearOptions[0].value,
            })
        );
    }, [currentData]);

    if (isFetching || !currentData) {
        return null;
    }

    const comparingColourClass = styles[`color-${getColourForArea(comparing).replace('#', '')}`];
    const comparingWithColourClass = styles[`color-${getColourForArea(comparingWith).replace('#', '')}`];

    return (
        <div className={styles.filters}>
            Select the data to be compared
            <div className={styles.selectTitle}>
                Metric
                <QuestionButtonHelp type='api' placement='right' slug='metrics-explainer' />
            </div>
            <div className={styles.selectContainer}>
                <Select
                    className={{ select: styles.select }}
                    label=''
                    width='100%'
                    options={metricOptions}
                    selectedValue={metric}
                    onChange={value =>
                        onFiltersChange({
                            metric: value,
                        })
                    }
                ></Select>
            </div>
            <div className={styles.selectTitle}>Compare</div>
            <div className={styles.selectContainer}>
                <Select
                    className={{ select: classNames(styles.select, comparingColourClass) }}
                    label=''
                    width='100%'
                    options={comparingOptions}
                    selectedValue={comparing}
                    onChange={value =>
                        onFiltersChange({
                            comparing: value,
                        })
                    }
                ></Select>
            </div>
            <div className={styles.selectTitle} style={{ marginTop: -10 }}>
                With
            </div>
            <div className={styles.selectContainer}>
                <Select
                    className={{ select: classNames(styles.select, comparingWithColourClass) }}
                    label=''
                    width='100%'
                    options={compareWithOptions}
                    selectedValue={comparingWith}
                    onChange={value =>
                        onFiltersChange({
                            comparingWith: value,
                        })
                    }
                ></Select>
            </div>
            <div className={styles.selectTitle}>Sampling year</div>
            <div className={styles.selectContainer}>
                <Select
                    label=''
                    width='100%'
                    options={samplingYearOptions}
                    selectedValue={samplingYear}
                    onChange={value =>
                        onFiltersChange({
                            samplingYear: value,
                        })
                    }
                ></Select>
            </div>
        </div>
    );
};

export default StatisticalComparisonFilters;
