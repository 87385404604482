import React, { ReactNode } from 'react';

import OrgIcon from 'src/assets/svg/general/organisation.svg?react';
import ProjectIcon from 'src/assets/svg/general/project.svg?react';
import SamplingEventIcon from 'src/assets/svg/general/sampling-event.svg?react';
import PlacePinIcon from 'src/assets/svg/general/place-pin.svg?react';
import DnaIcon from 'src/assets/svg/general/dna.svg?react';
import KeyIcon from 'src/assets/svg/general/key.svg?react';
import TrafficLightsIcon from 'src/assets/svg/general/traffic-light.svg?react';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import { SampleCountForTestTypes } from 'src/shared/types';
import { useSearchParams } from 'react-router-dom';
import useSamplingCadence from 'src/app/samples/hooks/useSamplingCadence';
import useSamplingEvent, { RemainingTest } from 'src/app/samples/hooks/useSamplingEvent';

import { formatSamplingEventCode } from 'src/app/samples/samplesHelpers';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import ProjectSampleManifestStatusIndicator from 'src/app/samples/components/project-sample-manifest-status-indicator/ProjectSampleManifestStatusIndicator';

type OverviewProps = {
    showInfo?: boolean;
};
const Overview = ({ showInfo = true }: OverviewProps) => {
    const { samplingCadence, customerData } = useSamplingCadence();
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get('customerId') as string;
    const selectedSamplingEventId = searchParams.get('samplingEventId') as string;
    const [samplingEventOptions, setSamplingEventOptions] = React.useState<SelectOption[]>([]);
    const { samplingEvent, remainingTests, submittedKits, projectSummary } = useSamplingEvent();
    const sampleCountForTestTypes = samplingEvent?.sampleManifestInfo.countInfo.countByTestTypes;
    const estimatedSampleCounts = samplingEvent?.sampleManifestInfo.countInfo.kitsCount;

    const setSelectedSamplingEvent = (value: string) => {
        // Update the URL with the selected sampling event
        searchParams.set('samplingEventId', value);
    };

    React.useEffect(() => {
        if (samplingCadence) {
            const options = samplingCadence.events
                .filter(event => event.identifier)
                .map(event => ({
                    value: event.identifier,
                    label: event.name,
                }));

            setSamplingEventOptions(options);

            setSelectedSamplingEvent(options[0].value);
        }
    }, [samplingCadence]);

    const samplingEventDropdown = (
        <Select options={samplingEventOptions} selectedValue={selectedSamplingEventId} onChange={setSelectedSamplingEvent} width='200px' />
    );

    if (!projectSummary || !samplingEvent) {
        return null;
    }

    return (
        <div className='flex flex-col shadow-lg p-4 rounded-lg bg-white gap-2'>
            {showInfo && (
                <div className='flex border-b-grey-40 border-b pb-2'>
                    <OverviewItem
                        icon={<OrgIcon />}
                        title='Organisation:'
                        value={
                            <div>
                                <div className='text-primary'>{customerData?.customerName}</div>
                                <div className='text-grey-60'>Customer code: {customerId}</div>
                            </div>
                        }
                    />
                    <OverviewItem
                        icon={<ProjectIcon />}
                        title='Project:'
                        value={
                            <div>
                                <div className='text-primary'>{projectSummary?.projectName}</div>
                                <div className='text-grey-60'>Project code: {projectSummary?.projectCode}</div>
                            </div>
                        }
                    />
                    <OverviewItem
                        icon={<SamplingEventIcon />}
                        title='Sampling event'
                        value={samplingEventDropdown}
                        helpSlug='sample-manifest-sampling-event'
                    />
                    {samplingEvent?.status && (
                        <OverviewItem
                            icon={<TrafficLightsIcon />}
                            title='Event Status'
                            value={<ProjectSampleManifestStatusIndicator status={projectSummary.sampleManifestStatus} />}
                        />
                    )}

                    <OverviewItem
                        icon={<KeyIcon />}
                        title='Event pin code'
                        value={<span className='text-primary'>{formatSamplingEventCode(samplingEvent?.identifier)}</span>}
                    />
                </div>
            )}
            <div className='flex pt-2'>
                <OverviewItem
                    icon={<PlacePinIcon />}
                    title='Samples submitted'
                    value={
                        <span className='text-primary text-2xl'>
                            {submittedKits.length}/{estimatedSampleCounts}
                        </span>
                    }
                    helpSlug='sample-reception-samples-submitted'
                />

                <div className='flex gap-2 flex-2'>
                    <DnaIcon className='fill-grey-80' />
                    <div>
                        <div className='flex gap-1 items-center'>
                            <div className='text-grey-80'>Test(s) added</div>
                            <QuestionButtonHelp slug='sample-reception-tests-added' type='api' />
                        </div>
                        <div className='text-primary'>
                            <TestsOverview sampleCountForTestTypes={sampleCountForTestTypes || []} remainingTests={remainingTests} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

type OverviewItemProps = {
    icon: ReactNode;
    title: ReactNode;
    value: ReactNode;
    helpSlug?: string;
};

const OverviewItem = (props: OverviewItemProps) => {
    const { icon, title, value, helpSlug } = props;

    return (
        <div className='flex gap-2 flex-2 w-[290px]'>
            <div className='text-grey-80'>{icon}</div>
            <div>
                <div className=' flex gap-1 items-start'>
                    <div className='text-grey-80'>{title}</div>
                    {helpSlug && (
                        <div className='mt-[-6px]'>
                            <QuestionButtonHelp slug={helpSlug} type='api' />
                        </div>
                    )}
                </div>
                <div>{value}</div>
            </div>
        </div>
    );
};

type TestsOverviewProps = {
    sampleCountForTestTypes: SampleCountForTestTypes;
    remainingTests: RemainingTest[];
};

const TestsOverview = (props: TestsOverviewProps) => {
    const { sampleCountForTestTypes, remainingTests } = props;

    return (
        <div className='flex gap-4 flex-wrap'>
            {sampleCountForTestTypes.map((test, index) => {
                const remainingTest = remainingTests.find(
                    remainingTest => remainingTest.testType.habitatAssayKey === test.type.habitatAssayKey
                );
                return (
                    <>
                        <TestItem remainingTest={remainingTest as RemainingTest} key={index} />
                        {sampleCountForTestTypes[index + 1] && (
                            <div
                                className='inline-block h-[40px] min-h-[1em] w-0.5 self-stretch bg-primary'
                                key={`separator - ${index}`}
                            ></div>
                        )}
                    </>
                );
            })}
        </div>
    );
};

const TestItem = (props: { remainingTest: RemainingTest }) => {
    const { testType, total, used } = props.remainingTest;

    return (
        <div className='flex gap-2 flex-2 text-primary'>
            <div className='text-primary text-4xl'>
                {used}/{total}
            </div>
            <div>
                <div className='font-light'>{testType.habitatName}</div>
                <div className='font-bold'>{testType.assayName}</div>
            </div>
        </div>
    );
};

export default Overview;
