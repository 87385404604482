import React, { useEffect } from 'react';
import useAuthService from 'src/shared/hooks/useAuthService';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import PagenotfoundImage from 'src/assets/images/general/404.webp';

const PageNotFound = () => {
    const { redirectIfNotAuthenticated } = useAuthService();

    useEffect(() => {
        redirectIfNotAuthenticated();
    }, []);

    const mainContent = (
        <div className='container mx-auto p-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='flex flex-col justify-center text-primary'>
                    <h1 className='text-6xl font-bold mb-6'>Oops!</h1>
                    <h2 className='text-4xl font-bold mb-4'>Something went wrong and we cannot find that page.</h2>
                    <p className='text-xl'>Please try refreshing your browser or contact NatureMetrics support if the issue persists.</p>
                </div>
                <div className='flex justify-center items-center'>
                    <img src={PagenotfoundImage} alt='Page not found' />
                </div>
            </div>
        </div>
    );

    return <StandardAppLayout mainContent={mainContent} />;
};
export default PageNotFound;
