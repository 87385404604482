import storageService, { StorageServiceInterface, StorageType } from 'src/services/storage-service';

export enum StorageKeys {
    CURRENT_CUSTOMER_ID = 'customer.currentCustomerId',
}

const localStorageOptions = {
    storage: StorageType.SESSION_STORAGE,
};

export class CustomersStorageService {
    private storageService: StorageServiceInterface;

    public constructor(storageService: StorageServiceInterface) {
        this.storageService = storageService;
    }

    public getCurrentCustomerId(): string | null {
        return this.storageService.get(StorageKeys.CURRENT_CUSTOMER_ID, localStorageOptions);
    }

    public setCurrentCustomerId(customerId: string | null): void {
        this.storageService.remove(StorageKeys.CURRENT_CUSTOMER_ID, localStorageOptions);

        this.storageService.save(StorageKeys.CURRENT_CUSTOMER_ID, customerId, localStorageOptions);
    }

    public clearAll(): void {
        this.storageService.remove(StorageKeys.CURRENT_CUSTOMER_ID, localStorageOptions);
    }
}

export default new CustomersStorageService(storageService);
