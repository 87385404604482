import React, { useEffect, useState } from 'react';
import { InputLabel } from '@mui/material';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useProjectFilters from 'src/shared/hooks/useProjectFilters';
import { useAppDispatch, useAppSelector } from 'src/store';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';

import { getCurrentHabitatTrendsUIState } from 'src/app/insights/state/habitat-trends/habitatTrendsSelector';
import { HabitatTrendsFiltersState, updateFilters, HideShowType } from 'src/app/insights/state/habitat-trends/habitatTrendsSlice';
import useHabitatTrendsAnalytics from 'src/app/insights/views/habitat-trends/hooks/useHabitatTrendsAnalytics';
import styles from './HabitatTrendsFilters.module.scss';

const hideShowOptions = [
    {
        label: 'Show',
        value: 'show',
    },
    {
        label: 'Hide',
        value: 'hide',
    },
];

const FILTER_WIDTH = 190;

type HabitatTrendsFiltersProps = {
    showBufferAreaFilter?: boolean;
    showAnnualRangeFilter?: boolean;
};

const HabitatTrendsFilters = ({ showBufferAreaFilter, showAnnualRangeFilter }: HabitatTrendsFiltersProps) => {
    const [metricOptions, setMetricOptions] = useState<SelectOption[]>([
        {
            label: 'None available',
            value: 'none',
        },
    ]);
    const [siteOptions, setSiteOptions] = useState<SelectOption[]>([
        {
            label: 'None available',
            value: 'none',
        },
    ]);

    const { currentProjectFilters } = useProjectFilters();

    const { trackAnnualRangeFilterClick, trackBufferAreaFilterClick, trackMetricFilterClick, trackSiteFilterClick } =
        useHabitatTrendsAnalytics();

    const dispatch = useAppDispatch();

    const uiState = useAppSelector(getCurrentHabitatTrendsUIState);

    const {
        filters: { metric, site, bufferArea, annualRange },
    } = uiState;

    const onFiltersChange = (fragment: Partial<HabitatTrendsFiltersState>) => {
        fragment = { ...fragment };

        // Track GA events for filter clicks
        if (fragment.annualRange) {
            trackAnnualRangeFilterClick();
        }

        if (fragment.bufferArea) {
            trackBufferAreaFilterClick();
        }

        if (fragment.site) {
            trackSiteFilterClick();
        }

        if (fragment.metric) {
            trackMetricFilterClick({ metric: fragment.metric.replace(/\s+/g, '_').toLowerCase() });
        }

        dispatch(updateFilters(fragment));
    };

    useEffect(() => {
        if (
            !currentProjectFilters ||
            currentProjectFilters.habitatInsightsMetricOptions.length === 0 ||
            currentProjectFilters.habitatInsightsSiteOptions.length === 0
        ) {
            return;
        }

        setMetricOptions(currentProjectFilters.habitatInsightsMetricOptions);
        setSiteOptions(currentProjectFilters.habitatInsightsSiteOptions);

        if (!metric || !site) {
            const newFilterValues = {
                metric: metric || currentProjectFilters.habitatInsightsMetricOptions[0].value,
                site: site || currentProjectFilters.habitatInsightsSiteOptions[0].value,
            };

            dispatch(updateFilters(newFilterValues));
        }
    }, [currentProjectFilters]);

    return (
        <div className={styles.filterGroup}>
            <div className={styles.filters}>
                {showAnnualRangeFilter && (
                    <div className={styles.selectGroup}>
                        <div className={styles.selectInput}>
                            <div className={styles.labelGroup}>
                                <InputLabel>Annual range</InputLabel>
                            </div>
                            <Select
                                options={hideShowOptions}
                                onChange={value => onFiltersChange({ annualRange: value as HideShowType })}
                                selectedValue={annualRange || 'show'}
                                width={FILTER_WIDTH}
                            />
                        </div>
                    </div>
                )}
                {showBufferAreaFilter && (
                    <div className={styles.selectGroup}>
                        <div className={styles.selectInput}>
                            <div className={styles.labelGroup}>
                                <InputLabel>Buffer area</InputLabel>
                                <QuestionButtonHelp type='api' placement='right' slug='habitat-insights-buffer-area-description' />
                            </div>
                            <Select
                                options={hideShowOptions}
                                onChange={value => onFiltersChange({ bufferArea: value as HideShowType })}
                                selectedValue={bufferArea || 'show'}
                                width={FILTER_WIDTH}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.selectInput}>
                    <InputLabel>Site</InputLabel>
                    <Select
                        options={siteOptions}
                        onChange={value => onFiltersChange({ site: value })}
                        selectedValue={site || 'none'}
                        width={FILTER_WIDTH}
                    />
                </div>
                <div className={styles.selectInput}>
                    <InputLabel>Metric</InputLabel>
                    <Select
                        options={metricOptions}
                        onChange={value =>
                            onFiltersChange({
                                metric: value,
                            })
                        }
                        selectedValue={metric || 'none'}
                        width={FILTER_WIDTH}
                    />
                </div>
            </div>
        </div>
    );
};

export default HabitatTrendsFilters;
