import React, { useState } from 'react';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useAppDispatch, useAppSelector } from 'src/store';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import Checkbox from 'src/shared/components/checkbox/Checkbox';
import { formatPhoneNumberWithCountryCode } from 'src/app/admin/views/user-management/components/userManagementHelpers';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import { Access } from 'src/shared/types';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import useAuthService from 'src/shared/hooks/useAuthService';
import AssignAdminRoles from '../../assign-admin-roles/AssignAdminRoles';
import AssignProjects from '../../assign-projects/AssignProjects';
import { useReassignUserToOrganisationMutation, useUpdateProjectUserMutation } from '../../../state/api/userManagementGraphSlice';
import {
    EditProjectUserFormState,
    reset,
    setEditUserFormState,
    setEditUserView,
    updateGeneralUiState,
} from '../../../state/userManagementSlice';
import { getEditProjectUserFormState } from '../../../state/userManagementSelector';
import UserDetails from '../../user-details/UserDetails';
import styles from '../EditProjectUser.module.scss';

const ReassignUserToOrganisation = () => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getEditProjectUserFormState);
    const { firstName, lastName, email, status, phone, projects, phoneCountryCode, roles, cognitoId } = formState;
    const [notify, setNotify] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [updateProjectUser] = useUpdateProjectUserMutation();
    const { currentCustomerId } = useCustomer();
    const { isCurrentUserSuperAdmin, isCurrentUserOnboardingManager } = useAuthService();
    const { isFetching, currentData: projectsData } = useProjectsQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    const projectIdsForCurrentCustomer = projectsData?.map(project => project.projectId) || [];

    const onStepChange = (step: string) => {
        dispatch(setEditUserView(step));
    };

    const [reassignUserToOrganisation] = useReassignUserToOrganisationMutation();

    const resetForm = (message: string, shouldShowSuccessAlert = true) => {
        dispatch(reset());
        dispatch(
            updateGeneralUiState({
                shouldShowEditUserModal: false,
                shouldShowSuccessAlert: shouldShowSuccessAlert,
                successStatusMessage: message,
            })
        );
    };

    const reassignUser = async () => {
        setErrorMessage('');
        try {
            const updatedUserParams = { firstName, lastName, cognitoId, projects };
            await updateProjectUser({
                ...updatedUserParams,
                phone: formatPhoneNumberWithCountryCode(phoneCountryCode, phone),
                tnc_consent: formState.tnc_consent ? true : false,
                customerId: currentCustomerId,
                notify: false,
            }).unwrap();

            await reassignUserToOrganisation({
                id: formState.id,
                notify: notify,
                customerId: currentCustomerId,
            }).unwrap();

            resetForm('User reassigned successfully.');
        } catch (error) {
            setErrorMessage('An error occurred while reassigning user.');
        }
    };

    const onFormChange = (fragment: Partial<EditProjectUserFormState['formState']>) => {
        dispatch(
            setEditUserFormState({
                ...fragment,
            })
        );
    };

    const isActiveOrInvited = status === 'Active' || status === 'Invited';
    const shouldEnableReassignButton =
        !isFetching &&
        (projects.filter(project => projectIdsForCurrentCustomer.includes(project.projectId) && project.roles?.length).length > 0 ||
            roles.length);

    return (
        <div className={styles.unassignUser}>
            <div>Are you sure you want to reassign the following user to this organisation?</div>
            <span className={styles.sectionDivider}></span>
            <UserDetails firstName={firstName} lastName={lastName} email={email} status={status} phone={phone} />
            <span className={styles.sectionDivider}></span>
            {(isCurrentUserSuperAdmin || isCurrentUserOnboardingManager) && (
                <div className={styles.section}>
                    <div className={styles.sectionHeading}>Admin roles</div>
                    <AssignAdminRoles onChange={onFormChange} roles={formState.roles} defaultRoles={roles} />
                </div>
            )}
            <div className={styles.section}>
                <div className={styles.sectionHeading}>Project permissions</div>
                <AssignProjects formState={formState} onFormChange={onFormChange} formMode={Access.UPDATE} disabled={!isActiveOrInvited} />
            </div>
            <span className={styles.sectionDivider}></span>
            <InfoMessage
                message={
                    <div>
                        This user will regain access to their assigned projects within this organisation on the NatureMetrics platform.
                        <br />
                        <br />
                        You can unassign this user at a later time
                    </div>
                }
            />
            <Checkbox
                label={'Send user an email to notify them of this change'}
                checked={notify}
                onChange={value => setNotify(value)}
                className={styles.notifyUser}
            />
            <div className={styles.footerActions}>
                <SecondaryButton onClick={() => onStepChange('default')}>
                    <ChevronLeft /> <span className={styles.buttonText}>Back</span>
                </SecondaryButton>
                <PrimaryButton onClick={reassignUser} disabled={!shouldEnableReassignButton}>
                    <PersonOffIcon /> Reassign
                </PrimaryButton>
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
    );
};

export default ReassignUserToOrganisation;
