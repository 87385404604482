import moment from 'moment';
import { useEffect, useState } from 'react';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { useLockEditingSampleKitMutation } from 'src/app/samples/sample-manifest/state/api/sampleManifestGraphSlice';
import { getCurrentSampleManifestState } from 'src/app/samples/sample-manifest/state/sampleManifestSelector';
import { selectSamplingKit } from 'src/app/samples/sample-manifest/state/sampleManifestSlice';
import { isValidLatitude, isValidLongitude } from 'src/shared/helpers/coordinateHelpers';
import { getKitTypeFromBarcode, isAquaticMaxiKit, KitType, isValidKitBarcode, isFullLengthBarcode } from 'src/shared/helpers/kitHelpers';
import { SamplingKit } from 'src/shared/types';
import { useAppDispatch, useAppSelector } from 'src/store';

const useFieldDataForm = () => {
    const { samplingKits, saveSamplingKit, samplingEvent, currentProjectId } = useSamplingEvent();
    const sampleManifestState = useAppSelector(getCurrentSampleManifestState);
    const selectedSamplingKit = samplingKits.find(kit => kit.id === sampleManifestState.selectedSamplingKitId);
    const [lockEditingSampleKit] = useLockEditingSampleKitMutation();
    const [isLockObtained, setIsLockObtained] = useState<boolean>();
    const [samplingKitDraft, setSamplingKitDraft] = useState<SamplingKit>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const startEditing = async () => {
            if (!selectedSamplingKit || !samplingEvent) {
                return;
            }

            const response = await lockEditingSampleKit({
                projectId: currentProjectId || '',
                eventIdentifier: samplingEvent?.identifier,
                kitId: sampleManifestState.selectedSamplingKitId,
            }).unwrap();

            const latestSamplingKit = response.event.sampleManifestInfo.kits.find(
                kit => kit.id === sampleManifestState.selectedSamplingKitId
            );

            if (latestSamplingKit) {
                setSamplingKitDraft({
                    ...latestSamplingKit,
                    sampledAt: latestSamplingKit?.sampledAt || new Date().toISOString(),
                });
            }

            setIsLockObtained(response.lock);
        };

        startEditing();

        return () => {
            setSamplingKitDraft(undefined);
            setIsLockObtained(undefined);
        };
    }, [selectedSamplingKit?.id]);

    const { id, barcode = '' } = samplingKitDraft || {};

    const isCurrentSampleNameUnique = samplingKits.every(kit => kit.id === id || kit.name !== samplingKitDraft?.name);
    const isCurrentBarcodeUnique = samplingKits.every(kit => kit.id === id || kit.barcode !== samplingKitDraft?.barcode);
    const kitType = getKitTypeFromBarcode(barcode);
    const isAquaticKit = kitType === KitType.Aquatic || kitType === KitType.AquaticBacteria;
    const isMaxiAquaticKit = isAquaticMaxiKit(barcode);
    const isMarineSedimentKit = kitType === KitType.MarineSediment;
    const isSoilKit = kitType === KitType.Soil;
    const isBulkInvertebrateKit = kitType === KitType.InvertebrateTissue;
    const kitStatus = selectedSamplingKit?.status;

    const onSamplingKitChange = (newValues: Partial<typeof selectedSamplingKit>) => {
        // If the date is today's date, and if the time is greater than the current time, set the time to the current time
        if (
            newValues &&
            newValues.sampledTime &&
            newValues.sampledAt &&
            new Date(newValues.sampledAt).toDateString() === new Date().toDateString()
        ) {
            const newTime = new Date(newValues.sampledTime).getTime();
            const currentTime = new Date().getTime();
            if (newTime > currentTime) {
                const newDate = new Date(newValues.sampledTime);
                newDate.setHours(new Date().getHours());
                newDate.setMinutes(new Date().getMinutes());
                newValues.sampledTime = newDate.toISOString();
            }
        }
        if (!samplingKitDraft) {
            return;
        }

        setSamplingKitDraft({ ...samplingKitDraft, ...newValues });
    };

    const onKitIdChange = (newBarcode: string) => {
        const newKitType = getKitTypeFromBarcode(newBarcode);

        const shouldLeaveHabitatEmpty = [
            newKitType === KitType.InvertebrateTissue,
            newKitType === KitType.Aquatic,
            newKitType === KitType.AquaticBacteria,
            newKitType === KitType.Barcoding,
        ].some(Boolean);

        if (shouldLeaveHabitatEmpty) {
            onSamplingKitChange({
                barcode: newBarcode,
                testTypes: [],
                habitat: '',
            });
            return;
        }

        onSamplingKitChange({
            barcode: newBarcode,
            habitat: newKitType,
            collectionMethod: '',
            testTypes: [],
            volumeFiltered: null,
            combinedVolumeFiltered: null,
        });
    };

    const saveChanges = async () => {
        try {
            await saveSamplingKit(samplingKitDraft as SamplingKit);
            // If the kit is saved successfully, clear the draft
            setSamplingKitDraft(undefined);
            // Unselect the kit
            dispatch(selectSamplingKit(''));
        } catch (error) {
            console.error(error);
        }
    };

    const getValidationData = () => {
        if (!samplingKitDraft || !samplingEvent) {
            return {
                errors: {},
                warnings: {},
                totalErrors: 0,
                totalWarnings: 0,
            };
        }
        const { name, sampler, sampledAt, latitude, longitude, notes } = samplingKitDraft as SamplingKit;
        const errors: Partial<Record<keyof SamplingKit, string[]>> = {
            name: [],
            sampler: [],
            sampledAt: [],
            latitude: [],
            longitude: [],
            notes: [],
            barcode: [],
            collectionMethod: [],
        };

        const warnings: Partial<Record<keyof SamplingKit, string[]>> = {
            volumeFiltered: [],
            combinedVolumeFiltered: [],
        };

        let totalErrors = 0;
        let totalWarnings = 0;

        // Sample Name
        // check if name is empty
        if (!name?.trim()) {
            totalErrors++;
        }

        // check if name is alphanumerical
        if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
            errors.name?.push('Sample name cannot contain any special characters');
            totalErrors++;
        }

        if (!isCurrentSampleNameUnique) {
            errors.name?.push('This sample name has already been used in this event. You must enter a unique name for every sample');
            totalErrors++;
        }

        if (isFullLengthBarcode(barcode) && !isValidKitBarcode(barcode)) {
            errors.barcode?.push('Please enter a valid barcode');
            totalErrors++;
        }

        if (isFullLengthBarcode(barcode) && !isCurrentBarcodeUnique) {
            errors.barcode?.push(
                'This barcode has already been used in another batch or sampling event. Please enter another another barcode'
            );
            totalErrors++;
        }
        // Sampler name must be entered in Latin script with no special characters
        if (!/^[a-zA-Z0-9 ]*$/.test(sampler)) {
            errors.sampler?.push('Sampler name must be entered in Latin script with no special characters');
            totalErrors++;
        }

        // Sampled At
        // sampledAt can only be a date between sampling event start date and current date
        // converted to moment objects for comparison as UTC date objects comparison is not reliable
        const momentSampledAtDate = moment(sampledAt, 'YYYY-MM-DD');
        const momentSamplingEventStartDate = moment(samplingEvent.fromDate, 'YYYY-MM-DD');
        const momentCurrentDateMonment = moment();

        const isDateInRange = momentSampledAtDate.isBetween(momentSamplingEventStartDate, momentCurrentDateMonment, 'days', '[]');
        if (!isDateInRange) {
            errors.sampledAt?.push(
                `Date must be from the start of sampling event ${momentSamplingEventStartDate.format(
                    'DD MMM YYYY'
                )} and up to today’s date ${moment().format('DD MMM YYYY')}`
            );
            totalErrors++;
        }

        // Latitude
        if (latitude && !isValidLatitude(latitude)) {
            errors.latitude?.push('Latitude must be between -90 to 90 and have at least four decimal places');
            totalErrors++;
        }

        // Longitude
        if (longitude && !isValidLongitude(longitude)) {
            errors.longitude?.push('Longitude must be between -180 to 180 and have at least four decimal place');
            totalErrors++;
        }

        // Notes
        if (!/^[a-zA-Z0-9!@#;':"|,.?*_ ]*$/.test(notes)) {
            errors.notes?.push(
                'Field notes can only contain letters, numbers, and the following special characters: ! @ # ; \' : " | , . ? * _'
            );
            totalErrors++;
        }

        // Volume Filtered
        if (isAquaticKit && samplingKitDraft.volumeFiltered && samplingKitDraft.volumeFiltered < 500) {
            warnings.volumeFiltered?.push('This is lower than the minimum recommended volume (500mL)');
            totalWarnings++;
        }

        // Combined volume Filtered
        if (isMaxiAquaticKit && samplingKitDraft.combinedVolumeFiltered && samplingKitDraft.combinedVolumeFiltered < 500) {
            warnings.combinedVolumeFiltered?.push('This is lower than the minimum recommended volume (500mL)');
            totalWarnings++;
        }

        return {
            errors,
            warnings,
            totalErrors,
            totalWarnings,
        };
    };

    return {
        samplingEvent,
        samplingKitDraft,
        isLockObtained,
        isAquaticKit,
        isMaxiAquaticKit,
        isSoilKit,
        isBulkInvertebrateKit,
        isCurrentSampleNameUnique,
        isCurrentBarcodeUnique,
        isMarineSedimentKit,
        validationData: getValidationData(),
        onSamplingKitChange,
        onKitIdChange,
        saveChanges,
        kitType,
        kitStatus,
    };
};

export default useFieldDataForm;
