import React from 'react';
import { FishMetricsQueryResult } from 'src/app/dashboard/state/api/queries/fishMetricsQuery';
import FishIndexBackground from 'src/assets/images/dashboard/fishmetrics-cover.webp';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import fishMetricsImageUrl from 'src/assets/images/dashboard/fishmetrics.svg?url';
import { Divider } from '@mui/material';
import styles from './FishMetricsSingleChart.module.scss';

const FishMetricsSingleChart = ({ data }: { data: FishMetricsQueryResult }) => {
    const commercialValue: number = data.fishcommercialvalue?.data[0]?.data[0].group.find(item => item.type === 'all')?.value ?? 0;
    const foodChainData: number = data.fishfoodchainintegrity?.data[0]?.data[0].group.find(item => item.type === 'all')?.value ?? 0;
    const prevalanceData: number =
        data.fishprevalenceofsensitivespecies?.data[0]?.data[0].group.find(item => item.type === 'all')?.value ?? 0;

    const metricsData = [
        {
            title: 'Commercial value',
            score: commercialValue,
            explainer: 'number of high and very high commercial species per sample',
        },
        {
            title: 'Food chain integrity',
            score: foodChainData,
            explainer: 'average trophic level per sample',
        },
        {
            title: 'Prevalence of sensitive species',
            score: prevalanceData,
            explainer: 'average vulnerability score per sample',
        },
    ];

    const backgroundImageStyle = {
        backgroundImage: `url(${fishMetricsImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '150% center',
        backgroundSize: '40%',
    };

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={FishIndexBackground} alt='' />
            </div>
            <div className={styles.contentBucket}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>Fish Metrics</div>
                    <p className={styles.subTitle}>A description of the overall condition of fish communities within an ecosystem.</p>
                </div>
                <div className={styles.metricContainer} style={backgroundImageStyle}>
                    {metricsData.map((metric, index) => {
                        const dataTestId = metric.title.toLowerCase().replace(/\s+/g, '-');
                        return (
                            <React.Fragment key={index}>
                                <div className={styles.metric}>
                                    <div className={styles.header}>
                                        <span className={styles.title} data-testid={`${dataTestId}-title`}>
                                            {metric.title}
                                        </span>
                                        <span className={styles.helpButton}>
                                            <QuestionButtonHelp type='api' slug={legendSlug[metric.title]} />
                                        </span>
                                    </div>
                                    <div className={styles.score} data-testid={`${dataTestId}-score`}>
                                        {metric.score}
                                    </div>
                                    <div className={styles.explainer} data-testid={`${dataTestId}-explainer`}>
                                        {metric.explainer}
                                    </div>
                                </div>
                                {metricsData[index + 1] && <Divider orientation='vertical' flexItem variant='middle' />}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const legendSlug: { [key: string]: string } = {
    'Commercial value': 'fish-commercial-value',
    'Food chain integrity': 'food-chain-integrity',
    'Prevalence of sensitive species': 'fish-prevalance-of-sensitive-species',
};

export default FishMetricsSingleChart;
