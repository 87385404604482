import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import { ProjectDefinition } from 'src/shared/types';
import {
    ProjectsQueryResult,
    projectsQuery,
    ProjectQueryResult,
    projectQuery,
    ProjectFiltersQueryResult,
    projectFiltersQuery,
    customerProjectsInBCQuery,
    CustomerProjectsInBCQueryResult,
    enablePdfDownloadQuery,
} from './queries/projectsListQuery';

type ProjectsQueryParams = {
    customerId: string;
};

type ProjectQueryParams = {
    projectId: string;
    samples?: {
        fromDate: string;
        toDate: string;
        assay: string;
    };
};

type ProjectFiltersQueryParams = {
    projectId: string;
};

type CustomerProjectsQueryParams = {
    customerId: string;
};

interface EnablePdfDownloadResponse {
    admin?: {
        enablePdfDownload?: boolean;
    };
}

const projectListGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        projects: builder.query<ProjectDefinition[], ProjectsQueryParams>({
            query: params => ({
                body: projectsQuery,
                variables: params,
            }),
            transformResponse: (response: ProjectsQueryResult) => {
                return response.customer.projects.map(project => ({
                    ...project,
                    projectCode: !project.projectCode || project.projectCode === 'Not set' ? 'Missing' : project.projectCode,
                }));
            },
        }),
        customerProjectsInBC: builder.query<CustomerProjectsInBCQueryResult, CustomerProjectsQueryParams>({
            query: params => ({
                body: customerProjectsInBCQuery,
                variables: params,
            }),
        }),
        project: builder.query<ProjectQueryResult, ProjectQueryParams>({
            query: params => ({
                body: projectQuery,
                variables: params,
            }),
        }),
        projectFilters: builder.query<ProjectFiltersQueryResult, ProjectFiltersQueryParams>({
            query: params => ({
                body: projectFiltersQuery,
                variables: params,
            }),
        }),
        enablePdfDownload: builder.mutation<boolean, { projectId: string; eventName: string }>({
            query: params => ({
                body: enablePdfDownloadQuery,
                variables: params,
            }),
            transformResponse: (response: EnablePdfDownloadResponse) => {
                return response.admin?.enablePdfDownload ?? false;
            },
        }),
    }),
});

export const {
    useLazyProjectsQuery,
    useProjectsQuery,
    useProjectQuery,
    useProjectFiltersQuery,
    useCustomerProjectsInBCQuery,
    useEnablePdfDownloadMutation,
} = projectListGraphSlice;

export const { projects: fetchProjects, project: fetchProject } = projectListGraphSlice.endpoints;
