import React, { useRef } from 'react';
import Clarity from '@microsoft/clarity';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import useAuthService from 'src/shared/hooks/useAuthService';
import useAuthServiceTimers from 'src/shared/hooks/useAuthServiceTimers';
import analyticsTracking from 'src/services/analytics-service';
import useGlobalAnalytics from 'src/global/hooks/useGlobalAnalytics';
import AppRoutes from 'src/routes/AppRoutes';
import SiteMenu from 'src/global/components/site-menu/SiteMenu';
import VideoHelp from 'src/global/components/video-help/VideoHelp';
import config from 'src/config';
import styles from './App.module.scss';
import './MUItheme.css';

const { msClarityAnalyticsKey } = config;

const App = () => {
    const { isAuthenticated } = useAuthService();
    const ref = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const shouldHideSiteMenu = searchParams.get('printLayout') === 'true';

    useAuthServiceTimers();
    analyticsTracking.initialize();
    useGlobalAnalytics(ref);
    if (msClarityAnalyticsKey) {
        Clarity.init(msClarityAnalyticsKey);
    }
    return (
        <>
            <CssBaseline />
            <Container disableGutters sx={{ m: isAuthenticated ? 'auto' : 0 }} maxWidth={false} ref={ref} className={styles.app}>
                {!shouldHideSiteMenu && <SiteMenu />}
                <Container sx={isAuthenticated ? { minHeight: '100%' } : {}} disableGutters maxWidth={false}>
                    <AppRoutes />
                </Container>
            </Container>
            <VideoHelp />
        </>
    );
};
export default App;
