import samplesStorageService from 'src/app/samples/services/samples-storage-service';
import { FieldDataStatus, SamplingKit } from '../types';
import { SelectOption } from '../components/select/Select';

export enum KitType {
    Aquatic = 'Aquatic',
    AquaticBacteria = 'Aquatic bacteria',
    Soil = 'Soil',
    MarineSediment = 'Marine sediment',
    Barcoding = 'Barcoding',
    InvertebrateTissue = 'Invertebrate tissue',
}

export const getKitTypeFromBarcode = (barcode: string): KitType | undefined => {
    const barcodePrefix = barcode.slice(0, 3);
    const kitsHabitatTestTypesMapping = samplesStorageService.getKitTestTypesMapping();

    return kitsHabitatTestTypesMapping.find(k => k.kitPrefix === barcodePrefix)?.kitType;
};

export const isFullLengthBarcode = (barcode: string): boolean => {
    return barcode.replaceAll(' ', '').length === 10;
};

export const isValidKitBarcode = (kitBarcode: string): boolean => {
    return /^[A-Z]{3}\d{7}/.test(kitBarcode);
};

export const getSamplingKitStatusData = (kit: SamplingKit) => {
    const kitType = getKitTypeFromBarcode(kit.barcode);
    const isAquaticKit = kitType === 'Aquatic' || kitType === 'Aquatic bacteria';
    const isBulkInvertebrateKit = kitType === KitType.InvertebrateTissue;
    const valuesToCheck = [
        { field: 'barcode', value: kit.barcode.trim() },
        { field: 'sampler', value: kit.sampler },
        { field: 'latitude', value: kit.latitude },
        { field: 'longitude', value: kit.longitude },
        { field: 'habitat', value: kit.habitat },
    ];
    // const fieldsToCheck = [ç, 'sampler', 'latitude', 'longitude', 'habitat'];

    if (isAquaticKit && !isAquaticMaxiKit(kit.barcode)) {
        valuesToCheck.push({ field: 'volumeFiltered', value: String(kit.volumeFiltered || '') });
    }

    if (isBulkInvertebrateKit) {
        valuesToCheck.push({ field: 'collectionMethod', value: kit.collectionMethod });
    }

    if (isAquaticMaxiKit(kit.barcode)) {
        valuesToCheck.push({ field: 'combinedVolumeFiltered', value: String(kit.combinedVolumeFiltered || '') });
    }

    const filledValuesCount = valuesToCheck.filter(record => Boolean(record.value)).length;

    const status =
        filledValuesCount === 0
            ? FieldDataStatus.NONE
            : valuesToCheck.length === filledValuesCount
            ? FieldDataStatus.COMPLETE
            : FieldDataStatus.INCOMPLETE;

    return {
        status,
        incompleteFields:
            status === FieldDataStatus.INCOMPLETE ? valuesToCheck.filter(record => !record.value).map(record => record.field) : [],
    };
};

export const isKitFieldDataCompleted = (kit: SamplingKit) => {
    if (getSamplingKitStatusData(kit).status !== FieldDataStatus.COMPLETE) {
        return false;
    }

    const valuesToCheck = [kit.name];

    const filledValuesCount = valuesToCheck.filter(Boolean).length;

    return filledValuesCount === valuesToCheck.length;
};

// Format: ABC0100001 to ABC-01-00001
export const formattedKitBarcode = (barcode: string): string => {
    return `${barcode.slice(0, 3)}-${barcode.slice(3, 5)}-${barcode.slice(5)}`;
};

type TestType = {
    habitatName: string;
    assayName: string;
    habitatAssayKey: string;
};

export const getTestTypes = (barcode: string, habitatName: string): TestType[] | undefined => {
    const kitType = getKitTypeFromBarcode(barcode);
    // The kit types is loaded onto the samplesStorageService on the first load of the app
    const kitsHabitatTestTypesMapping = samplesStorageService.getKitTestTypesMapping();
    const kit = kitsHabitatTestTypesMapping.find(k => k.kitType === kitType);
    if (!kit) {
        return undefined;
    }
    const habitatTests = kit.testsTypes.filter(test => test.habitatName === habitatName);
    return habitatTests.length > 0 ? habitatTests : undefined;
};

export const getHabitatOptionsByKitPrefix = (barcode: string): SelectOption[] => {
    // The kit types is loaded onto the samplesStorageService on the first load of the app
    const kitsHabitatTestTypesMapping = samplesStorageService.getKitTestTypesMapping() || [];
    const kit = kitsHabitatTestTypesMapping.find(k => k.kitPrefix === barcode?.slice(0, 3));
    if (!kit) {
        return [];
    }
    const habitatNames = kit.testsTypes.map(test => test.habitatName);
    const uniqueHabitatNames = Array.from(new Set(habitatNames));
    return uniqueHabitatNames.map(habitatName => ({
        label: habitatName,
        value: habitatName,
    }));
};

// If the aquatic kit entered in the kit barcode field contains the prefixes ‘AMI, PMI, PMD or AMD’, then it is aquatic maxi kit
export const isAquaticMaxiKit = (barcode: string): boolean => {
    return ['AMI', 'PMI', 'PMD', 'AMD'].includes(barcode.slice(0, 3));
};
