import React, { useRef } from 'react';

import { AppName } from 'src/global/globalConfig';
import useProject from 'src/shared/hooks/useProject';
import Lozenges from './Lozenges';

import styles from './HabitatAssayLozenges.module.scss';

export type HabitatAssayLozengeOption = {
    habitatName: string;
    assayName: string;
    value: string;
};

type HabitatAssayLozengesProps = {
    selectedValue: string;
    options: HabitatAssayLozengeOption[];
    onChange: (value: string) => void;
    app: AppName;
};

const HabitatAssayLozenges = (props: HabitatAssayLozengesProps) => {
    const { selectedValue, options, onChange, app } = props;
    const elementRef = useRef<HTMLDivElement | null>(null);
    const { currentProjectDetails } = useProject();
    const lozengeOptions = options.map(entry => ({
        label: (
            <div className={styles.lozenge} data-testid={`${entry.value}-lozenge`}>
                <span className={styles.habitat}>{entry.habitatName}</span>
                <span className={styles.assay}>{entry.assayName}</span>
            </div>
        ),
        value: entry.value,
    }));

    const onLozengeChange = (value: string) => {
        const event = new CustomEvent('analytics', {
            detail: {
                category: 'habitat-assay-lozenge',
                action: 'habitat_assay_changed',
                app,
                habitatAssayType: value,
                project: currentProjectDetails?.projectName,
            },
            bubbles: true,
        });

        elementRef.current?.dispatchEvent(event);

        onChange(value);
    };

    return (
        <span ref={elementRef}>
            <Lozenges options={lozengeOptions} selectedValue={selectedValue} onChange={onLozengeChange} />
        </span>
    );
};

export default HabitatAssayLozenges;
