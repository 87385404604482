import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SiteMenuUIState = {
    isOpen: boolean;
    isUpgradeModalOpen: boolean;
};

export const defaultSiteMenuUIState: SiteMenuUIState = {
    isOpen: false,
    isUpgradeModalOpen: false,
};

export type SiteMenuState = {
    uiState: SiteMenuUIState;
};
export const initialSiteMenuState: SiteMenuState = {
    uiState: { ...defaultSiteMenuUIState },
};

const siteMenuSlice = createSlice({
    name: 'siteMenu',
    initialState: initialSiteMenuState,
    reducers: {
        updateSiteMenuUIState(state, action: PayloadAction<Partial<SiteMenuUIState>>) {
            state.uiState = { ...state.uiState, ...action.payload };
        },
        closeSiteMenu(state) {
            state.uiState.isOpen = false;
        },
        openSiteMenu(state) {
            state.uiState.isOpen = true;
        },
        showUpgradeModal(state) {
            state.uiState.isUpgradeModalOpen = true;
        },
        hideUpgradeModal(state) {
            state.uiState.isUpgradeModalOpen = false;
        },
    },
});

export default siteMenuSlice.reducer;

export const { closeSiteMenu, openSiteMenu, showUpgradeModal, hideUpgradeModal } = siteMenuSlice.actions;
