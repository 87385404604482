import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { SamplingEventFilterData } from 'src/shared/hooks/useProjectFilters';
import { RootState } from 'src/store';

const defaultIucnFilterState = {
    samplingEvent: null,
};

type IucnMetricUIState = {
    samplingEvent: SamplingEventFilterData | null;
};

export type DashboardUIState = {
    iucnMetric: IucnMetricUIState;
};

export const defaultUiState = {
    iucnMetric: { ...defaultIucnFilterState },
};

type DashboardState = {
    uiState: {
        [projectId: string]: DashboardUIState;
    };
};
const initialState: DashboardState = {
    uiState: {},
};

export const updateUiState =
    (fragment: Partial<DashboardUIState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;
        if (projectId) {
            dispatch(
                dashboardGeneralSlice.actions.updateUIState({
                    projectId,
                    fragment,
                })
            );
        }
    };

export const updateIucnMetricUiState =
    (fragment: Partial<IucnMetricUIState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();
        const projectId = state.projects.projectList.currentProjectId;
        if (projectId) {
            dispatch(
                dashboardGeneralSlice.actions.updateIucnMetricUIState({
                    projectId,
                    fragment,
                })
            );
        }
    };

const ensureProjectUIStatePresence = (state: DashboardState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            iucnMetric: { ...defaultIucnFilterState },
        };
    }
};

const dashboardGeneralSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        updateIucnMetricUIState(state, action: PayloadAction<{ projectId: string; fragment: Partial<IucnMetricUIState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId].iucnMetric = {
                ...(state.uiState[projectId].iucnMetric as IucnMetricUIState),
                ...fragment,
            };
        },

        updateUIState(state, action: PayloadAction<{ projectId: string; fragment: Partial<DashboardUIState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);
            state.uiState[projectId] = {
                ...(state.uiState[projectId] as DashboardUIState),
                ...fragment,
            };
        },
    },
});

export default dashboardGeneralSlice.reducer;
