import React, { useEffect } from 'react';
import { useAppDispatch } from 'src/store';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import Loader from 'src/shared/components/loader/Loader';
import { SamplingEventBatchStatus, SamplingEventStatus, Status } from 'src/shared/types';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import moment from 'moment';
import ProjectSampleManifestStatusIndicator from 'src/app/samples/components/project-sample-manifest-status-indicator/ProjectSampleManifestStatusIndicator';
import { updateFilters } from 'src/app/samples/sample-reception/state/sampleReceptionSlice';
import ChipStatusIndicator from './components/chip-status-indicator/ChipStatusIndicator';
import useSampleReceptionList from './hooks/useSampleReceptionList';

const columns: DataTableColumns = [
    {
        columnId: 'customerId',
        title: '',
    },
    {
        columnId: 'projectId',
        title: '',
        isUnique: true,
    },
    {
        columnId: 'eventId',
        title: '',
    },
    {
        columnId: 'projectName',
        title: 'Project',
        width: '20%',
    },
    {
        columnId: 'organisation',
        title: 'Organisation',
        width: '20%',
    },
    {
        columnId: 'subscriptionType',
        title: 'Subscription type',
    },
    {
        columnId: 'completedEvents',
        title: 'Completed events',
    },
    {
        columnId: 'currentEventStatus',
        title: 'Current event status',
    },
    {
        title: 'Pending NM approval',
        columnId: 'pendingNMApproval',
        width: '100px',
    },
    {
        title: 'Pending cust. amendment',
        columnId: 'pendingCustAmendment',
        width: '140px',
    },
];

const SampleReceptionList = () => {
    const dispatch = useAppDispatch();
    const navigation = useAppNavigation();
    const { isFetching, tableState, currentData, setTableState } = useSampleReceptionList();

    useEffect(() => {
        if (!currentData) {
            return;
        }
        const selectedRowIds = new Set<string>();
        const data = currentData?.projects.map(project => {
            const projectName = (
                <div className='flex flex-col'>
                    <span>{project.projectName}</span>
                    <span className='text-grey-80 text-sm'>{project.projectCode || 'Missing'}</span>
                </div>
            );

            const organisation = (
                <div className='flex flex-col'>
                    <span>{project.customerName || 'customerName'}</span>
                    <span className='text-grey-80 text-sm'>{project.customerId}</span>
                </div>
            );

            // is any batch submitted created more than 5 days ago using moment
            const isBatchSubmittedMoreThan5Days = project.ongoingEvent?.sampleManifestInfo.batches?.some(
                batch => [SamplingEventBatchStatus.CREATED].includes(batch.status) && moment().diff(batch.createdAt, 'days') > 5
            );

            const completedEvents = countByStatus(
                project.otherEvents.map((event: any) => ({ status: event.status })),
                SamplingEventStatus.COMPLETED
            );
            const samplingEventStatus = <ProjectSampleManifestStatusIndicator status={project.sampleManifestStatus} />;
            const batchesPendingNMApproval = countByStatus(project.ongoingEvent?.sampleManifestInfo.batches, [
                SamplingEventBatchStatus.CREATED,
                SamplingEventBatchStatus.APPROVED_BY_LOGISTICS,
            ]);

            const batchesPendingCustAmendment = countByStatus(
                project.ongoingEvent?.sampleManifestInfo.batches,
                SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT
            );
            const nmApprovalValue =
                batchesPendingNMApproval > 0 ? (
                    <ChipStatusIndicator
                        status={isBatchSubmittedMoreThan5Days ? Status.ERROR : Status.WARNING}
                        label={`x ${batchesPendingNMApproval}`}
                    />
                ) : null;
            const custAmendmentValue =
                batchesPendingCustAmendment > 0 ? (
                    <ChipStatusIndicator status={Status.WARNING} label={`x ${batchesPendingCustAmendment}`} />
                ) : null;

            if (batchesPendingNMApproval || batchesPendingCustAmendment) {
                selectedRowIds.add(project.projectId);
            }

            //this needs to revisited when we have multiple events
            const eventIdentifier = project.ongoingEvent?.identifier
                ? project.ongoingEvent?.identifier
                : project.otherEvents[0]?.identifier;

            return [
                project.customerId,
                project.projectId,
                eventIdentifier,
                projectName,
                organisation,
                project.subscriptionType,
                completedEvents,
                samplingEventStatus,
                nmApprovalValue,
                custAmendmentValue,
            ];
        });

        setTableState(prev => ({ ...prev, data, selectedRowIds, currentPageNumber: currentData.pagination.page }));
    }, [currentData]);

    const handleRowClick = (row: TableRowData) => {
        navigation.toViewSampleManifest({
            customerId: row[0] as string,
            projectId: row[1] as string,
            samplingEventId: row[2] as string,
        });
    };

    if (!tableState.data || isFetching) {
        return <Loader />;
    }

    const handleOnPageChange = (page: number) => {
        dispatch(updateFilters({ currentPageNumber: page }));
    };

    const table = (
        <DataTable
            state={tableState.data ? tableState : undefined}
            columns={columns}
            emptyStateMessage='There were no sample manifests found matching this selection. Adjust the filters to find customer sample manifests'
            className='flex-1 bg-white rounded-md mt-2'
            onRowSelect={handleRowClick}
            onPageChange={handleOnPageChange}
            totalRowsForPagination={currentData?.pagination.total}
        />
    );

    return table;
};

const countByStatus = (items: { status: SamplingEventStatus | SamplingEventBatchStatus }[] = [], status: string | string[]): number => {
    if (!Array.isArray(status)) {
        status = [status];
    }

    return items.filter(item => status.includes(item.status)).length;
};

export default SampleReceptionList;
