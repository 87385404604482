import React, { ReactNode, useEffect } from 'react';
import RadioGroup, { OptionValue } from 'src/shared/components/radio-group/RadioGroup';
import BoxIcon from 'src/assets/svg/general/box.svg?react';
import PrintIcon from 'src/assets/svg/general/print.svg?react';
import LockIcon from 'src/assets/svg/general/lock.svg?react';
import ReturnIcon from 'src/assets/svg/general/return.svg?react';
import CircleCheckIcon from 'src/assets/svg/general/check-circle.svg?react';
import Checkbox from 'src/shared/components/checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from 'src/store';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import useSampleManifestSubmitAnalytics from 'src/app/samples/sample-manifest/hooks/useSampleManifestSubmitAnalytics';
import { getCurrentSampleManifestState } from '../../../state/sampleManifestSelector';
import { setSamplingEventCompleted, setTermsAndConditionsAccepted } from '../../../state/sampleManifestSlice';
import SubmitData from '../../components/submit-data/SubmitData';

const options = [
    {
        label: (
            <div className='flex flex-col gap-2'>
                <div className='text-lg text-primary'>
                    No, I <span className='underline'>will</span> submit more samples for this sampling event
                </div>
                <div className='flex flex-col text-grey-80'>
                    <div>By selecting this, you acknowledge that:</div>
                    <ul className='list-disc pl-8'>
                        <li>NatureMetrics will not create your report until all samples for this event are submitted</li>
                        <li>This will create a batch of samples. More batches can be submitted for this event</li>
                    </ul>
                </div>
            </div>
        ),
        value: 'false',
    },
    {
        label: (
            <div className='flex flex-col gap-2'>
                <div className='text-lg text-primary'>
                    Yes, I <span className='underline'>will not</span> submit more samples for this sampling event
                </div>
                <div className='flex flex-col text-grey-80'>
                    <div>By selecting this, you acknowledge that:</div>
                    <ul className='list-disc pl-8'>
                        <li>You cannot submit any more samples for this sampling event</li>
                        <li>
                            Any unused tests from this sampling event may not automatically be transferrable to a future event and
                            NatureMetrics will begin creating your report
                        </li>
                    </ul>
                </div>
            </div>
        ),
        value: 'true',
    },
];

const infoMessage = (
    <InfoMessage
        message={
            <>
                You have completed every sample for this sampling event.
                <br /> It is not possible to submit more samples.
            </>
        }
    />
);

const Submit = () => {
    const dispatch = useAppDispatch();
    const { isSamplingEventCompleted, isTermsAndConditionsAccepted } = useAppSelector(getCurrentSampleManifestState);
    const { isBatchOnlySubmit, samplingKits, submittedKits, readyToSubmitKits } = useSamplingEvent();
    const { trackSampleManifestSubmitOption } = useSampleManifestSubmitAnalytics();
    const hasCompletedAllSamples = readyToSubmitKits.length + submittedKits.length === samplingKits.length;
    const isResubmission = isBatchOnlySubmit;

    const submitOptions = options.map((option, index) => ({
        ...option,
        isDisabled: hasCompletedAllSamples && index === 0,
        label: (
            <>
                {option.label}
                {index === 0 && hasCompletedAllSamples && infoMessage}
            </>
        ),
    }));

    useEffect(() => {
        // Clear all selections when returning to 'submit' step
        dispatch(setSamplingEventCompleted(null));
        dispatch(setTermsAndConditionsAccepted(false));
    }, []);

    const onRadioChange = (value: OptionValue) => {
        dispatch(setSamplingEventCompleted(value === 'true'));

        // Capture GA for sample manifest submit user will/will not submit more samples
        trackSampleManifestSubmitOption({ action: value === 'true' ? 'will_not' : 'will' });
    };

    return (
        <div className='flex flex-col gap-4 overflow-auto'>
            <div className='flex flex-col gap-4 bg-white p-4 shadow-lg rounded-lg'>
                {isResubmission ? (
                    <div className='flex flex-col gap-6'>
                        <div className='text-xl text-primary'>You are resubmitting a sample batch</div>

                        <div className='text-grey-60'>
                            Please review and agree to the the resubmission guidance and terms below before resubmitting
                        </div>
                        <span className='border-b border-b-grey-20' />
                    </div>
                ) : (
                    <>
                        <div className='text-xl text-primary'>Is your sampling event complete?</div>

                        <div className='flex flex-col gap-2 pb-2 border-b border-b-grey-20'>
                            <RadioGroup
                                options={submitOptions}
                                selectedOption={String(isSamplingEventCompleted)}
                                onChange={onRadioChange}
                            />
                        </div>
                    </>
                )}

                <div className='flex flex-col gap-5'>
                    <div className='text-xl text-primary'>
                        {isResubmission ? 'Resubmission guidance and terms' : 'Submission guidance and terms'}
                    </div>
                    <div className='flex flex-col gap-4'>
                        {isResubmission && (
                            <>
                                <GuidanceRow
                                    icon={<ReturnIcon />}
                                    descritpion='NatureMetrics will review all this submitted data and may require you to amend any of the data that does not meet our requirements to be able to generate the insights for your report'
                                />

                                <GuidanceRow
                                    icon={<LockIcon />}
                                    descritpion='Once all samples have been submitted, you will not be able to edit any of the data fields'
                                />

                                <GuidanceRow
                                    icon={<CircleCheckIcon />}
                                    descritpion='In the event that you need to change your sampling data after NatureMetrics accepts your data submission, this may incur additional costs, because NatureMetrics would have to begin creating a new report'
                                />
                            </>
                        )}
                        {!isResubmission && (
                            <>
                                <GuidanceRow
                                    icon={<BoxIcon />}
                                    descritpion='You will receive an email confirmation with shipping instructions to our lab after we review your samples'
                                />

                                <GuidanceRow
                                    icon={<PrintIcon className='fill-primary' />}
                                    descritpion='You will need to print a copy of your data submission and place it in the sample return box. (print option will appear once you have clicked submit)'
                                />

                                <GuidanceRow
                                    icon={<ReturnIcon />}
                                    descritpion='Our team will review each batch submission and inform you via email if any data needs to be amended by you. Before lab processing can begin, all amendments must be completed and resubmitted'
                                />

                                <GuidanceRow
                                    icon={<CircleCheckIcon />}
                                    descritpion='Additional charges may incur if you need to change your sampling data after NatureMetrics accepts your submission and the sampling event is completed'
                                />
                            </>
                        )}
                    </div>

                    <div className='flex gap-2'>
                        <Checkbox
                            checked={isTermsAndConditionsAccepted}
                            onChange={value => {
                                dispatch(setTermsAndConditionsAccepted(value));
                                if (isResubmission) {
                                    dispatch(setSamplingEventCompleted(false));
                                }
                            }}
                            label='I agree to the terms of submission'
                            className='bg-grey-20 p-2 rounded-lg text-grey-80'
                        />
                    </div>
                </div>
            </div>

            <div className='flex flex-col items-end gap-2'>
                <div className='text-primary'>
                    {isResubmission ? 'Agree to the terms of submission before resubmitting' : 'Complete each section before submitting'}
                </div>
                <div>
                    <SubmitData />
                </div>
            </div>
        </div>
    );
};

type GuidanceRowProps = {
    icon: ReactNode;
    descritpion: string;
};

const GuidanceRow = (props: GuidanceRowProps) => {
    return (
        <div className='flex gap-4'>
            <div className='flex items-center'>{props.icon}</div>
            <div className='text-md text-grey-80'>{props.descritpion}</div>
        </div>
    );
};

export default Submit;
