import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { getProjectRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, ProjectViews } from 'src/routes/routesConfig';
import { Resources } from 'src/shared/types';
import ProjectAdmin from './views/project-admin/ProjectAdmin';
import ProjectDefinition from './views/project-definition/ProjectDefinition';
import ProjectList from './views/project-list/ProjectsList';
import NewProjectDefinition from './views/new-project-definition/NewProjectDefinition';

export const projectRouteSettings: { [key in ProjectViews]: IndividualRouteSetting } = {
    projects: {
        name: 'Projects',
        path: '/',
        handler: <ProjectList />,
        resource: Resources.PROJECTS_LIST,
    },
    projectAdmin: {
        name: 'Project admin',
        path: '/project-admin',
        handler: <ProjectAdmin />,
        resource: Resources.PROJECT_ADMIN,
    },
    newProjectDefinition: {
        name: 'New project definition',
        path: '/new-project-definition',
        handler: <NewProjectDefinition />,
        resource: Resources.NEW_PROJECT_DEFINITION,
    },
    projectDefinition: {
        name: 'Project definition',
        path: '/project-definition',
        handler: <ProjectDefinition />,
        resource: Resources.PROJECT_DEFINITION,
    },
};

const Projects = () => {
    const routesConfig = useAppSelector(getProjectRoutesAvailability);

    if (!routesConfig) {
        return null;
    }

    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as ProjectViews];
                const routeSetting = projectRouteSettings[routeKey as ProjectViews];

                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'} routeSetting={routeSetting}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Projects;
